import React, { useState } from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

const WeHasilPdf = () => {
    const [file, setFile] = useState('https://webew.id/img/dokumen/Skripsi_Mela_Kurniawan.pdf');
    const [isLoaded, setIsLoaded] = useState(false);  // State to track if the PDF is loaded

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = file;
        link.download = 'THE RELATIONSHIP BETWEEN LEARNING INTEREST AND LEARNING OUTCOMES OF CLASS VIII STUDENTS IN THE ENGLISH SUBJECT AT MANDALAHAYU MIDDLE SCHOOL MelA KURNIAWAN.pdf';
        link.click();
    };

    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    // Handler for when the PDF document is fully loaded
    const handleDocumentLoad = () => {
        setIsLoaded(true);
    };

    return (
        <div style={{ textAlign: 'center', height: '100vh', width: '100vw', overflow: 'hidden', margin: 0 }}>
            <div style={{ margin: '10px 0' }}>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-5">
                        {/* Show download button only when the PDF is loaded */}
                        {isLoaded && (
                            <button className='btn btn-webew-login' onClick={handleDownload} style={{ marginRight: '10px' }}>
                                <i className="bi bi-download" style={{color:"white"}}></i> Download PDF
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {/* PDF Viewer */}
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                <div style={{ height: 'calc(100vh - 60px)', width: '100%' }}>
                    <Viewer
                        fileUrl={file}
                        plugins={[defaultLayoutPluginInstance]}
                        defaultScale={SpecialZoomLevel.PageWidth}
                        onDocumentLoad={handleDocumentLoad} // Trigger when document is loaded
                    />
                </div>
            </Worker>
        </div>
    );
}

export default WeHasilPdf;
