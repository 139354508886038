// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate, Link } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBarAdmin.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbarAdmin.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBotAdmin.jsx'
// MENU WE AQIQAH
const MenuWeMotivasiAdmin = () => { // BUKA KURUNG MENU WE AQIQAH
    // MEMBUAT VARIABEL WE AQIQAH
    const [displayLoader, setDisplayLoader] = useState("")
    const [panggilan, setPanggilan] = useState('')
    const [data, setData] = useState([])
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        selectAllWeMotivasi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokMinBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/lowemin", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokMinBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
     // PROSES MENAMPILKAN WE ADMIN BERDASARKAN PANGGILAN BY PARAMS
     const selectAllWeMotivasi = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectAllWeMotivasiBE`)
            setData(response.data)
        }catch(error){
            console.log(error)
        }
    }
    // PROSES MENGHAPUS DATA MOTIVASI
    const deleteData = async (id) => {
        try {
            await axios.delete(`https://server13.webew.id/deleteWeMotivasiBE/${id}`)
            selectAllWeMotivasi()
        } catch (error) {
            console.log(error)
        }
    }
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <h4 className="text-center fw-bold">We Motivasi Admin</h4>
                                    <div className='my-5 pb-3'>
                                        <div className='box' style={{width: '100%',minHeight:'70vh',maxHeight:'70vh',overflow:"auto"}}>
                                            <table style={{width:"1500px",overflow:"auto"}}>
                                                <thead>
                                                    <tr style={{borderBottom:'2px solid #f5f5f5'}}>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Aksi</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>No.</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Nama</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Kata Motivasi</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Jenis Motivasi</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Created At</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Updated At</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((user,index)=>(
                                                        <tr key={data.id} style={{borderBottom:'2px solid #f5f5f5'}}>
                                                        <td className='p-2 fw-semibold text-center' style={{fontSize:'10pt',color:"white"}}><button className="btn btn-logout mx-auto d-block p-2" onClick={()=> deleteData(user.id)} style={{border:"none",backgroundColor:"red"}}><i className="bi bi-trash3" style={{color:"white"}}></i></button></td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{index + 1}.</td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.name}</td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.kata}</td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.jenisMotivasi}</td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.createdAt}</td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.updatedAt}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KUTUNG MENU WE AQIQAH
// EKSPOR
export default MenuWeMotivasiAdmin