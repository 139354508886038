// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE PARAMS, USE NAVIGATE, LINK
import { useParams, useNavigate } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../../Layout/MenuDonasi.jsx'
// 
const MenuWePernikahanForm = () => { // KURUNG BUKA MENU WE PERNIKAHAN FORM
    // MEMBUAT VARIABEL MENU WE PERNIKAHAN FORM
    const { panggilanParams } = useParams();
    const { jenisWePernikahanParams } = useParams();
    const [panggilan, setPanggilan] = useState('')
    const [displayLoader, setDisplayLoader] = useState("")
    const [msg, setMsg] = useState("")
    const [fotoLatar, setFotoLatar] = useState('')
    const [fotoPria, setFotoPria] = useState('')
    const [fotoWanita, setFotoWanita] = useState('')
    const [fotoGaleri1, setFotoGaleri1] = useState('')
    const [fotoGaleri2, setFotoGaleri2] = useState('')
    const [fotoGaleri3, setFotoGaleri3] = useState('')
    const [fotoGaleri4, setFotoGaleri4] = useState('')
    const [fotoGaleri5, setFotoGaleri5] = useState('')
    const [fotoGaleri6, setFotoGaleri6] = useState('')
    const [namaPanggilPria, setNamaPanggilPria] = useState('')
    const [namaPanggilWanita, setNamaPanggilWanita] = useState('')
    const [namaLengkapPria, setNamaLengkapPria] = useState('')
    const [namaLengkapWanita, setNamaLengkapWanita] = useState('')
    const [namaBapakPria, setNamaBapakPria] = useState('')
    const [namaBapakWanita, setNamaBapakWanita] = useState('')
    const [namaIbuPria, setNamaIbuPria] = useState('')
    const [namaIbuWanita, setNamaIbuWanita] = useState('')
    const [hariAkad, setHariAkad] = useState('')
    const [tanggalAkad, setTanggalAkad] = useState('')
    const [jamAkadMulai, setJamAkadMulai] = useState('')
    const [jamAkadAkhir, setJamAkadAkhir] = useState('')
    const [hariResepsi, setHariResepsi] = useState('')
    const [tanggalResepsi, setTanggalResepsi] = useState('')
    const [jamResepsiMulai, setJamResepsiMulai] = useState('')
    const [jamResepsiAkhir, setJamResepsiAkhir] = useState('')
    const [alamatAkad, setAlamatAkad] = useState('')
    const [linkMapsAlamatAkad, setLinkMapsAlamatAkad] = useState('')
    const [alamatResepsi, setAlamatResepsi] = useState('')
    const [linkMapsAlamatResepsi, setLinkMapsAlamatResepsi] = useState('')
    const [isCheckedAnnisa, setIsCheckedAnnisa] = useState('')
    const [isCheckedArrum, setIsCheckedArrum] = useState('')
    const [isCheckedAnnur, setIsCheckedAnnur] = useState('')
    const [isCheckedAnnahl, setIsCheckedAnnahl] = useState('')
    const [isCheckedAlfathir, setIsCheckedAlfathir] = useState('')
    const [isCheckedAzzariyat, setIsCheckedAzzariyat] = useState('')
    const [namaSurat, setNamaSurat] = useState('')
    const [ayat, setAyat] = useState('')
    const [namaBank1, setNamaBank1] = useState('')
    const [noRek1, setNoRek1] = useState('')
    const [atasNama1, setAtasNama1] = useState('')
    const [namaBank2, setNamaBank2] = useState('')
    const [noRek2, setNoRek2] = useState('')
    const [atasNama2, setAtasNama2] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-pernikahan", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES MENGAMBIL FILE FOTO DARI FORM
    function handleUploadChangeLatar(e){
        let uploaded = e.target.files[0]
        setFotoLatar(uploaded)
    }
    function handleUploadChangePria(e){
        let uploaded = e.target.files[0]
        setFotoPria(uploaded)
    }
    function handleUploadChangeWanita(e){
        let uploaded = e.target.files[0]
        setFotoWanita(uploaded)
    }
    function handleUploadChangeGaleri1(e){
        let uploaded = e.target.files[0]
        setFotoGaleri1(uploaded)
    }
    function handleUploadChangeGaleri2(e){
        let uploaded = e.target.files[0]
        setFotoGaleri2(uploaded)
    }
    function handleUploadChangeGaleri3(e){
        let uploaded = e.target.files[0]
        setFotoGaleri3(uploaded)
    }
    function handleUploadChangeGaleri4(e){
        let uploaded = e.target.files[0]
        setFotoGaleri4(uploaded)
    }
    function handleUploadChangeGaleri5(e){
        let uploaded = e.target.files[0]
        setFotoGaleri5(uploaded)
    }
    function handleUploadChangeGaleri6(e){
        let uploaded = e.target.files[0]
        setFotoGaleri6(uploaded)
    }
    // PROSES MENYIMPAN WE PRENIKAHAN KE DB
    const createWePernikahan = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("panggilan", panggilanParams)
        formData.append("fotoLatar", fotoLatar)
        formData.append("fotoPria", fotoPria)
        formData.append("fotoWanita", fotoWanita)
        formData.append("fotoGaleri1", fotoGaleri1)
        formData.append("fotoGaleri2", fotoGaleri2)
        formData.append("fotoGaleri3", fotoGaleri3)
        formData.append("fotoGaleri4", fotoGaleri4)
        formData.append("fotoGaleri5", fotoGaleri5)
        formData.append("fotoGaleri6", fotoGaleri6)
        formData.append("namaPanggilPria", namaPanggilPria)
        formData.append("namaPanggilWanita", namaPanggilWanita)
        formData.append("namaLengkapPria", namaLengkapPria)
        formData.append("namaLengkapWanita", namaLengkapWanita)
        formData.append("namaBapakPria", namaBapakPria)
        formData.append("namaBapakWanita", namaBapakWanita)
        formData.append("namaIbuPria", namaIbuPria)
        formData.append("namaIbuWanita", namaIbuWanita)
        formData.append("hariAkad", hariAkad)
        formData.append("tanggalAkad", tanggalAkad)
        formData.append("jamAkadMulai", jamAkadMulai)
        formData.append("jamAkadAkhir", jamAkadAkhir)
        formData.append("hariResepsi", hariResepsi)
        formData.append("tanggalResepsi", tanggalResepsi)
        formData.append("jamResepsiMulai", jamResepsiMulai)
        formData.append("jamResepsiAkhir", jamResepsiAkhir)
        formData.append("alamatAkad", alamatAkad)
        formData.append("linkMapsAlamatAkad", linkMapsAlamatAkad)
        formData.append("alamatResepsi", alamatResepsi)
        formData.append("linkMapsAlamatResepsi", linkMapsAlamatResepsi)
        formData.append("namaSurat", namaSurat)
        formData.append("ayat", ayat)
        formData.append("namaBank1", namaBank1)
        formData.append("noRek1", noRek1)
        formData.append("atasNama1", atasNama1)
        formData.append("namaBank2", namaBank2)
        formData.append("noRek2", noRek2)
        formData.append("atasNama2", atasNama2)
        formData.append("jenisUndanganDigitalPernikahan", jenisWePernikahanParams)
        try{
            setDisplayLoader("")
            await axios.patch("https://server13.webew.id/createWePernikahanBE", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            navigate(`/menu-we-pernikahan-hasil/${panggilan}/${jenisWePernikahanParams}`, { replace: true })
        } catch(error){
            if(error.response){
                setDisplayLoader("none")
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES INPUT AYAT SUCI ANNISA
    const inputAyatSuciAnnisa = async() => {
        setIsCheckedAnnisa(!isCheckedAnnisa)
        isCheckedAnnisa ? setAyat("") : setAyat("“Hai manusia, bertakwalah kepada Tuhan-mu Yang menciptakan kamu dari satu jiwa, dan darinya Dia menciptakan jodohnya, dan mengembang-biakan dari keduanya banyak laki-laki dan perempuan; dan bertakwalah kepada Allah swt. yang dengan nama-Nya kamu saling bertanya, terutama mengenai hubungan tali kekerabatan. Sesungguhnya Allah swt. adalah pengawas atas kamu.”")
        isCheckedAnnisa ? setNamaSurat("") : setNamaSurat("QS An Nisa Ayat 1")
        isCheckedAnnisa ? setIsCheckedArrum("") : setIsCheckedArrum("")
        isCheckedAnnisa ? setIsCheckedAnnur("") : setIsCheckedAnnur("")
        isCheckedAnnisa ? setIsCheckedAnnahl("") : setIsCheckedAnnahl("")
        isCheckedAnnisa ? setIsCheckedAlfathir("") : setIsCheckedAlfathir("")
        isCheckedAnnisa ? setIsCheckedAzzariyat("") : setIsCheckedAzzariyat("")
    }
    // PROSES INPUT AYAT SUCI ARRUM
    const inputAyatSuciArrum = async() => {
        setIsCheckedArrum(!isCheckedArrum)
        isCheckedArrum ? setAyat("") : setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
        isCheckedArrum ? setNamaSurat("") : setNamaSurat("QS Ar Rum Ayat 21")
        isCheckedArrum ? setIsCheckedAnnisa("") : setIsCheckedAnnisa("")
        isCheckedArrum ? setIsCheckedAnnur("") : setIsCheckedAnnur("")
        isCheckedArrum ? setIsCheckedAnnahl("") : setIsCheckedAnnahl("")
        isCheckedArrum ? setIsCheckedAlfathir("") : setIsCheckedAlfathir("")
        isCheckedArrum ? setIsCheckedAzzariyat("") : setIsCheckedAzzariyat("")
    }
    // PROSES INPUT AYAT SUCI ANNUR
    const inputAyatSuciAnnur = async() => {
        setIsCheckedAnnur(!isCheckedAnnur)
        isCheckedAnnur ? setAyat("") : setAyat("“Dan nikahkanlah orang-orang yang masih membujang di antara kamu, dan juga orang-orang yang layak (menikah) dari hamba-hamba sahayamu yang laki-laki dan perempuan. Jika mereka miskin, Allah akan memberi kemampuan kepada mereka dengan karunia-Nya. Dan Allah Mahaluas (pemberian-Nya) lagi Maha Mengetahui.”")
        isCheckedAnnur ? setNamaSurat("") : setNamaSurat("QS An Nur Ayat 32")
        isCheckedAnnur ? setIsCheckedAnnisa("") : setIsCheckedAnnisa("")
        isCheckedAnnur ? setIsCheckedArrum("") : setIsCheckedArrum("")
        isCheckedAnnur ? setIsCheckedAnnahl("") : setIsCheckedAnnahl("")
        isCheckedAnnur ? setIsCheckedAlfathir("") : setIsCheckedAlfathir("")
        isCheckedAnnur ? setIsCheckedAzzariyat("") : setIsCheckedAzzariyat("")
    }
    // PROSES INPUT AYAT SUCI ANNAHL
    const inputAyatSuciAnnahl = async() => {
        setIsCheckedAnnahl(!isCheckedAnnahl)
        isCheckedAnnahl ? setAyat("") : setAyat("“Dan Allah menjadikan bagimu pasangan (suami atau istri) dari jenis kamu sendiri, menjadikan anak dan cucu bagimu dari pasanganmu, serta memberimu rezeki dari yang baik-baik. Mengapa mereka beriman kepada yang batil dan mengingkari nikmat Allah?”")
        isCheckedAnnahl ? setNamaSurat("") : setNamaSurat("QS An Nahl Ayat 72")
        isCheckedAnnahl ? setIsCheckedAnnisa("") : setIsCheckedAnnisa("")
        isCheckedAnnahl ? setIsCheckedArrum("") : setIsCheckedArrum("")
        isCheckedAnnahl ? setIsCheckedAnnur("") : setIsCheckedAnnur("")
        isCheckedAnnahl ? setIsCheckedAlfathir("") : setIsCheckedAlfathir("")
        isCheckedAnnahl ? setIsCheckedAzzariyat("") : setIsCheckedAzzariyat("")
    }
    // PROSES INPUT AYAT SUCI ALFATHIR
    const inputAyatSuciAlfathir = async() => {
        setIsCheckedAlfathir(!isCheckedAlfathir)
        isCheckedAlfathir ? setAyat("") : setAyat("“Dan Allah menciptakan kamu dari tanah kemudian dari air mani, kemudian Dia menjadikan kamu berpasang-pasangan (laki-laki dan perempuan). Tidak ada seorang perempuan pun yang mengandung dan melahirkan melainkan dengan sepengetahuan-Nya. Dan tidak dipanjangkan umur seseorang dan tidak pula dikurangi umurnya, melainkan (sudah ditetapkan) dalam Kitab (Lauh Mahfuzh). Sesungguhnya yang demikian itu mudah bagi Allah.”")
        isCheckedAlfathir ? setNamaSurat("") : setNamaSurat("QS Al Fathir Ayat 11")
        isCheckedAlfathir ? setIsCheckedAnnisa("") : setIsCheckedAnnisa("")
        isCheckedAlfathir ? setIsCheckedArrum("") : setIsCheckedArrum("")
        isCheckedAlfathir ? setIsCheckedAnnur("") : setIsCheckedAnnur("")
        isCheckedAlfathir ? setIsCheckedAnnahl("") : setIsCheckedAnnahl("")
        isCheckedAlfathir ? setIsCheckedAzzariyat("") : setIsCheckedAzzariyat("")
    }
    // PROSES INPUT AYAT SUCI AZZARIYAT
    const inputAyatSuciAzzariyat = async() => {
        setIsCheckedAzzariyat(!isCheckedAzzariyat)
        isCheckedAzzariyat ? setAyat("") : setAyat("“Dan segala sesuatu Kami ciptakan berpasang-pasangan supaya kamu mengingat kebesaran Allah.”")
        isCheckedAzzariyat ? setNamaSurat("") : setNamaSurat("QS Az-Zariyat Ayat 49")
        isCheckedAzzariyat ? setIsCheckedAnnisa("") : setIsCheckedAnnisa("")
        isCheckedAzzariyat ? setIsCheckedArrum("") : setIsCheckedArrum("")
        isCheckedAzzariyat ? setIsCheckedAnnur("") : setIsCheckedAnnur("")
        isCheckedAzzariyat ? setIsCheckedAnnahl("") : setIsCheckedAnnahl("")
        isCheckedAzzariyat ? setIsCheckedAlfathir("") : setIsCheckedAlfathir("")
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div  className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid mt-3">
                                    <div className="row justify-content-center mb-5 py-4">
                                        <div className="col-12 col-lg-4 mb-4">
                                            <div className="col-12">
                                                <div className="box pt-3 pb-4 px-3">
                                                    <a href={`/menu-we-pernikahan/${panggilan}`} className="btn btn-webew-product" style={{width:"auto"}}><i class="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                                                    <h3 className="title-webew-home" style={{marginTop:"10px"}}>Form We Pernikahan</h3>
                                                    <p className="text-webew-home">Silahkan isi form We pernikahan ini ya</p>
                                                    <a href={`/menu-we-pernikahan-hasil/${panggilan}/${jenisWePernikahanParams}`} className="btn btn-webew-product mx-auto d-block">Lihat Punya Saya</a>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="box mt-4">
                                                    <img src="https://webew.id/img/banner.png" alt="banner webew.id" className='mx-auto d-block' style={{width:"100%",maxHeight:"35.4vh",borderRadius:"20px"}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-8">
                                            <div className="box p-4">
                                                <form onSubmit={createWePernikahan}>
                                                    <div className="accordion" id="accordionPanelsStayOpenExample" style={{marginTop:"-20px"}}>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">Foto/Gambar</button>
                                                            </h2>
                                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                                                <div className="accordion-body">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="fotoLatar">Latar (JPEG/PNG)</label>
                                                                            <input type="file" id="fotoLatar" className="form-control form" accept="image/*" onChange={handleUploadChangeLatar} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="fotoPria">Pria (JPEG/PNG)</label>
                                                                            <input type="file" id="fotoPria" className="form-control form" accept="image/*" onChange={handleUploadChangePria} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="fotoWanita">Wanita (JPEG/PNG)</label>
                                                                            <input type="file" id="fotoWanita" className="form-control form" accept="image/*" onChange={handleUploadChangeWanita} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="fotoGaleri1">Galeri1 (JPEG/PNG)</label>
                                                                            <input type="file" id="fotoGaleri1" className="form-control form" accept="image/*" onChange={handleUploadChangeGaleri1} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="fotoGaleri2">Galeri2 (JPEG/PNG)</label>
                                                                            <input type="file" id="fotoGaleri2" className="form-control form" accept="image/*" onChange={handleUploadChangeGaleri2}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="fotoGaleri3">Galeri3 (JPEG/PNG)</label>
                                                                            <input type="file" id="fotoGaleri3" className="form-control form" accept="image/*" onChange={handleUploadChangeGaleri3}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="fotoGaleri4">Galeri4 (JPEG/PNG)</label>
                                                                            <input type="file" id="fotoGaleri4" className="form-control form" accept="image/*" onChange={handleUploadChangeGaleri4}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="fotoGaleri5">Galeri5 (JPEG/PNG)</label>
                                                                            <input type="file" id="fotoGaleri5" className="form-control form" accept="image/*" onChange={handleUploadChangeGaleri5}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="fotoGaleri6">Galeri6 (JPEG/PNG)</label>
                                                                            <input type="file" id="fotoGaleri6" className="form-control form" accept="image/*" onChange={handleUploadChangeGaleri6}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">Data Pengantin</button>
                                                            </h2>
                                                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                                                <div className="accordion-body">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaPanggilPria">Nama Panggilan Pria</label>
                                                                            <input type="text" id="namaPanggilPria" className="form-control form" maxLength="40" value={namaPanggilPria} onChange={(e)=>setNamaPanggilPria(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaPanggilWanita">Nama Panggilan Wanita</label>
                                                                            <input type="text" id="namaPanggilWanita" className="form-control form" maxLength="40" value={namaPanggilWanita} onChange={(e)=>setNamaPanggilWanita(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaLengkapPria">Nama Lengkap Pria</label>
                                                                            <input type="text" id="namaLengkapPria" className="form-control form" maxLength="40" value={namaLengkapPria} onChange={(e)=>setNamaLengkapPria(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaLengkapWanita">Nama Lengkap Wanita</label>
                                                                            <input type="text" id="namaLengkapWanita" className="form-control form" maxLength="40" value={namaLengkapWanita} onChange={(e)=>setNamaLengkapWanita(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaBapakPria">Nama Bapak Pria</label>
                                                                            <input type="text" id="namaBapakPria" className="form-control form" maxLength="40" value={namaBapakPria} onChange={(e)=>setNamaBapakPria(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaBapakWanita">Nama Bapak Wanita</label>
                                                                            <input type="text" id="namaBapakWanita" className="form-control form" maxLength="40" value={namaBapakWanita} onChange={(e)=>setNamaBapakWanita(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaIbuPria">Nama Ibu Pria</label>
                                                                            <input type="text" id="namaIbuPria" className="form-control form" maxLength="40" value={namaIbuPria} onChange={(e)=>setNamaIbuPria(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaIbuWanita">Nama Ibu Wanita</label>
                                                                            <input type="text" id="namaIbuWanita" className="form-control form" maxLength="40" value={namaIbuWanita} onChange={(e)=>setNamaIbuWanita(e.target.value)}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">Jadwal Acara</button>
                                                            </h2>
                                                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                                                <div className="accordion-body">
                                                                    <div className="row">
                                                                        <input type="text" id="panggilan" className="form-disable" value={panggilan} readOnly/>
                                                                        <input type="text" id="jenisUndanganDigitalPernikahan" className="form-disable" value={jenisWePernikahanParams} readOnly/>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="hariAkad">Hari Akad</label>
                                                                            <select className="form-select form" id="hariAkad" aria-label="hariAkad" value={hariAkad} onChange={(e)=>setHariAkad(e.target.value)} required>
                                                                                <option defaultValue="Senin">Pilih</option>
                                                                                <option defaultValue="Senin">Senin</option>
                                                                                <option defaultValue="Selasa">Selasa</option>
                                                                                <option defaultValue="Rabu">Rabu</option>
                                                                                <option defaultValue="Kamis">Kamis</option>
                                                                                <option defaultValue="Jumat">Jumat</option>
                                                                                <option defaultValue="Sabtu">Sabtu</option>
                                                                                <option defaultValue="Minggu">Minggu</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalAkad">Tanggal Akad</label>
                                                                            <input type="date"  id="tanggalAkad" className="form-control form" maxLength="40" value={tanggalAkad} onChange={(e)=>setTanggalAkad(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="jamAkadMulai">Jam Mulai Akad</label>
                                                                            <input type="time" id="jamAkadMulai" className="form-control form" maxLength="40" value={jamAkadMulai} onChange={(e)=>setJamAkadMulai(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="jamAkadAkhir">Jam Akhir Resepsi</label>
                                                                            <input type="time" id="jamAkadAkhir" className="form-control form" maxLength="40" value={jamAkadAkhir} onChange={(e)=>setJamAkadAkhir(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="hariResepsi">Hari Resepsi</label>
                                                                            <select className="form-select form" id="hariResepsi" aria-label="hariResepsi" value={hariResepsi} onChange={(e)=>setHariResepsi(e.target.value)} required>
                                                                                <option defaultValue="Senin">Pilih</option>
                                                                                <option defaultValue="Senin">Senin</option>
                                                                                <option defaultValue="Selasa">Selasa</option>
                                                                                <option defaultValue="Rabu">Rabu</option>
                                                                                <option defaultValue="Kamis">Kamis</option>
                                                                                <option defaultValue="Jumat">Jumat</option>
                                                                                <option defaultValue="Sabtu">Sabtu</option>
                                                                                <option defaultValue="Minggu">Minggu</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalResepsi">Tanggal Resepsi</label>
                                                                            <input type="date" id="tanggalResepsi" className="form-control form" maxLength="40" value={tanggalResepsi} onChange={(e)=>setTanggalResepsi(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="jamResepsiMulai">Jam Mulai Resepsi</label>
                                                                            <input type="time" id="jamResepsiMulai" className="form-control form" maxLength="40" value={jamResepsiMulai} onChange={(e)=>setJamResepsiMulai(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="jamResepsiAkhir">Jam Akhir Resepsi</label>
                                                                            <input type="time" id="jamResepsiAkhir" className="form-control form" maxLength="40" value={jamResepsiAkhir} onChange={(e)=>setJamResepsiAkhir(e.target.value)} required/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"aria-controls="panelsStayOpen-collapseFour">Alamat Acara</button>
                                                            </h2>
                                                            <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                                                <div className="accordion-body">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="alamatAkad">Alamat Akad</label>
                                                                            <input type="text" id="alamatAkad" className="form-control form" maxLength="45" value={alamatAkad} onChange={(e)=>setAlamatAkad(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="linkMapsAlamatAkad">Link Maps Alamat Akad</label>
                                                                            <input type="text" id="linkMapsAlamatAkad" className="form-control form" maxLength="45" value={linkMapsAlamatAkad} onChange={(e)=>setLinkMapsAlamatAkad(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="alamatResepsi">Alamat Resepsi</label>
                                                                            <input type="text" id="alamatResepsi" className="form-control form" maxLength="45" value={alamatResepsi} onChange={(e)=>setAlamatResepsi(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="linkMapsAlamatResepsi">Link Maps Alamat Resepsi</label>
                                                                            <input type="text" id="linkMapsAlamatResepsi" className="form-control form" maxLength="45" value={linkMapsAlamatResepsi} onChange={(e)=>setLinkMapsAlamatResepsi(e.target.value)} required/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="panelsStayOpen-headingFours">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFours" aria-expanded="false"aria-controls="panelsStayOpen-collapseFours">Rekening untuk kado</button>
                                                            </h2>
                                                            <div id="panelsStayOpen-collapseFours" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFours">
                                                                <div className="accordion-body">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaBank1">Nama Bank 1</label>
                                                                            <input type="text" id="namaBank1" className="form-control form" maxLength="45" value={namaBank1} onChange={(e)=>setNamaBank1(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="noRek1">No Rekening 1</label>
                                                                            <input type="text" id="noRek1" className="form-control form" maxLength="45" value={noRek1} onChange={(e)=>setNoRek1(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="atasNama1">Atas Nama 1</label>
                                                                            <input type="text" id="atasNama1" className="form-control form" maxLength="70" value={atasNama1} onChange={(e)=>setAtasNama1(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaBank2">Nama Bank 2</label>
                                                                            <input type="text" id="namaBank2" className="form-control form" maxLength="45" value={namaBank2} onChange={(e)=>setNamaBank2(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="noRek2">No Rekening 2</label>
                                                                            <input type="text" id="noRek2" className="form-control form" maxLength="45" value={noRek2} onChange={(e)=>setNoRek2(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="atasNama2">Atas Nama 2</label>
                                                                            <input type="text" id="atasNama2" className="form-control form" maxLength="70" value={atasNama2} onChange={(e)=>setAtasNama2(e.target.value)} required/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false"aria-controls="panelsStayOpen-collapseFive">Ayat Suci</button>
                                                            </h2>
                                                            <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                                                                <div className="accordion-body">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6 col-md-3 mt-3">
                                                                            <input className="form-check-input form" type="checkbox" id="isCheckedAnnisa" checked={isCheckedAnnisa} onChange={inputAyatSuciAnnisa}/>
                                                                            <label style={{fontSize:"10pt"}} className="form-check-label pt-1" htmlFor="isCheckedAnnisa">
                                                                                <h6>QS An Nisa Ayat 1</h6>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-3 mt-3">
                                                                            <input className="form-check-input form" type="checkbox" id="isCheckedArrum" checked={isCheckedArrum} onChange={inputAyatSuciArrum}/>
                                                                            <label style={{fontSize:"10pt"}} className="form-check-label pt-1" htmlFor="isCheckedArrum">
                                                                                <h6>QS Ar Rum Ayat 21</h6>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-3 mt-3">
                                                                            <input className="form-check-input form" type="checkbox" id="isCheckedAnnur" checked={isCheckedAnnur} onChange={inputAyatSuciAnnur}/>
                                                                            <label style={{fontSize:"10pt"}} className="form-check-label pt-1" htmlFor="isCheckedAnnur">
                                                                                <h6>QS An Nur Ayat 32</h6>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-3 mt-3">
                                                                            <input className="form-check-input form" type="checkbox" id="isCheckedAnnahl" checked={isCheckedAnnahl} onChange={inputAyatSuciAnnahl}/>
                                                                            <label style={{fontSize:"10pt"}} className="form-check-label pt-1" htmlFor="isCheckedAnnahl">
                                                                                <h6>QS An Nahl Ayat 72</h6>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-3 mt-3">
                                                                            <input className="form-check-input form" type="checkbox" id="isCheckedAlfathir" checked={isCheckedAlfathir} onChange={inputAyatSuciAlfathir}/>
                                                                            <label style={{fontSize:"10pt"}} className="form-check-label pt-1" htmlFor="isCheckedAlfathir">
                                                                                <h6>QS Al Fathir Ayat 11</h6>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-3 mt-3">
                                                                            <input className="form-check-input form" type="checkbox" id="isCheckedAzzariyat" checked={isCheckedAzzariyat} onChange={inputAyatSuciAzzariyat}/>
                                                                            <label style={{fontSize:"10pt"}} className="form-check-label pt-1" htmlFor="isCheckedAzzariyat">
                                                                                <h6>QS Az-Zariyat Ayat 49</h6>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <input type="text" className='form-disable' value={namaSurat} onChange={(e)=>setNamaSurat(e.target.value)}/>
                                                                            <textarea className='form-control form mt-3' value={ayat} readOnly></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 style={{textAlign:"center",marginTop:"20px",marginBottom:"-20px",color:"red"}}>{msg}</h6>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <input type="submit" className="btn btn-webew-product mt-4" value="Proses Bikin UD"/>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG MENU WE PERNIKAHAN FORM
// EKSPOR
export default MenuWePernikahanForm