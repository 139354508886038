// IMPORT REACT
import React from 'react'
// ERROR
const Error = () => { // KURUNG BUKA ERROR
    return ( // KURUNG BUKA RETURN
        <div>
            <div style={{marginTop:'30vh'}}>
                <p className='text-center fw-bold' style={{color:'rgb(11, 97, 255)',fontSize:'90pt'}}>404</p>
                <h6 className='text-center fw-bold' style={{color:'rgb(11, 97, 255)', marginTop:'-40px'}}>Halaman Tidak Tersedia</h6>
            </div>
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP ERROR
// EKSPOR
export default Error