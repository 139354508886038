// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE AQIQAH
const MenuWeAqiqah = () => { // BUKA KURUNG MENU WE AQIQAH
    // MEMBUAT VARIABEL WE AQIQAH
    const [displayLoader, setDisplayLoader] = useState("")
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-aqiqah", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })    
    // PROSES LOOP JENIS AQIQAH CARD BODY
    const jenisWeAqiqah = [
        { title: 'White 1', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/white1.png', viewLink: '/we-aqiqah/white1', createLink: `/menu-we-aqiqah-form/${panggilan}/white1` },
        { title: 'White 2', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/white2.png', viewLink: '/we-aqiqah/white2', createLink: `/menu-we-aqiqah-form/${panggilan}/white2` },
        { title: 'White 3', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/white3.png', viewLink: '/we-aqiqah/white3', createLink: `/menu-we-aqiqah-form/${panggilan}/white3` },
        { title: 'White 4', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/white4.png', viewLink: '/we-aqiqah/white4', createLink: `/menu-we-aqiqah-form/${panggilan}/white4` },
        { title: 'Flower 1', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flower1.png', viewLink: '/we-aqiqah/flower1', createLink: `/menu-we-aqiqah-form/${panggilan}/flower1` },
        { title: 'Flower 2', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flower2.png', viewLink: '/we-aqiqah/flower2', createLink: `/menu-we-aqiqah-form/${panggilan}/flower2` },
        { title: 'Flower 3', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flower3.png', viewLink: '/we-aqiqah/flower3', createLink: `/menu-we-aqiqah-form/${panggilan}/flower3` },
        { title: 'Flower 4', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flower4.png', viewLink: '/we-aqiqah/flower4', createLink: `/menu-we-aqiqah-form/${panggilan}/flower4` },
        { title: 'Color 1', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/color1.png', viewLink: '/we-aqiqah/color1', createLink: `/menu-we-aqiqah-form/${panggilan}/color1` },
        { title: 'Color 2', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/color2.png', viewLink: '/we-aqiqah/color2', createLink: `/menu-we-aqiqah-form/${panggilan}/color2` },
        { title: 'Color 3', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/color3.png', viewLink: '/we-aqiqah/color3', createLink: `/menu-we-aqiqah-form/${panggilan}/color3` },
        { title: 'Color 4', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/color4.png', viewLink: '/we-aqiqah/color4', createLink: `/menu-we-aqiqah-form/${panggilan}/color4` },
    ];
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* DONASI */}
                <MenuDonasi/>
            {/* TUTUP DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row my-3">
                                        <div className="col-12 p-2">
                                            <h4 className='text-center' style={{fontWeight:'700'}}>We Aqiqah</h4>
                                            <p className='text-center fw-semibold'>Buat Undangan Aqiqah Digital Dengan We Aqiqah</p>
                                        </div>
                                    </div>
                                    <div className="row mb-5 pb-1">
                                        {jenisWeAqiqah.map((data, index) => (
                                            <div key={index} className='col-6 col-lg-4 col-xl-3 mb-4'id="aqiqah">
                                                <div className='card' style={{ width: '100%', minHeight: '390px' }}>
                                                <h5 className='card-title text-center mt-2 fw-bold'>{data.title}</h5>
                                                <img src={data.imgSrc} className='mx-auto d-block mt-1' style={{ width: '125px', minHeight: '175px' }} alt={data.title} />
                                                <div className='card-body'>
                                                    <p className='card-text-harga'>Harga Saat Ini</p>
                                                    <p className='card-text-rupiah'>Rp 45.000</p>
                                                    <div className='row'>
                                                    <div className='col-12 col-sm-6'>
                                                        <a href={data.viewLink} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</a>
                                                    </div>
                                                    <div className='col-12 col-sm-6'>
                                                        <a href={data.createLink} className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Bikin</a>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KUTUNG MENU WE AQIQAH
// EKSPOR
export default MenuWeAqiqah