// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK 
import { useNavigate } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE CV
const MenuWeCv = () => { // BUKA KURUNG MENU WE CV
    // MEMBUAT VARIABEL MENU WE CV
    const [displayLoader, setDisplayLoader] = useState("")
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-cv", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES LOOP JENIS CV
    const jenisWeCv = [
        { title: 'CV 1', imgSrc: 'https://webew.id/img/bikinCV/cvg1.webp', alt: 'Bikin CV1', createLink: `/menu-we-cv-form/${panggilan}/CV1` },
        { title: 'CV 2', imgSrc: 'https://webew.id/img/bikinCV/cvg2.webp', alt: 'Bikin CV2', createLink: `/menu-we-cv-form/${panggilan}/CV2` },
        { title: 'CV 3', imgSrc: 'https://webew.id/img/bikinCV/cvg3.webp', alt: 'Bikin CV3', createLink: `/menu-we-cv-form/${panggilan}/CV3` },
        { title: 'CV 4', imgSrc: 'https://webew.id/img/bikinCV/cvg4.webp', alt: 'Bikin CV4', createLink: `/menu-we-cv-form/${panggilan}/CV4` },
    ]
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAP */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div  className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row my-3">
                                        <div className="col-12 p-2">
                                            <h4 className='text-center' style={{fontWeight:'700'}}>We CV</h4>
                                            <p className='text-center fw-semibold'>Buat CV Secara Online Dengan We CV</p>
                                        </div>
                                    </div>
                                    <div className="row mb-5 pb-1">
                                        {jenisWeCv.map((data, index) => (
                                            <div key={index} className="col-6 col-lg-4 col-xl-3 mb-4" id="cv">
                                                <div className='card' style={{ width: '100%' }}>
                                                    <h5 className='card-title text-center mt-2 fw-bold'>{data.title}</h5>
                                                    <a href={data.imgSrc} aria-label='cv' data-toggle='lightbox' data-gallery='cv'>
                                                        <img src={data.imgSrc}className='mx-auto d-block mt-2'style={{ width: '130px', minHeight: '195px' }}alt={data.alt}/>
                                                    </a>
                                                    <div className='card-body'>
                                                        <p className='card-text-harga mt-3'>Harga Saat Ini</p>
                                                        <p className='card-text-rupiah'>Rp 5.000</p>
                                                        <a href={data.createLink} className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Bikin</a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG MENU WE CV
// EKSPOR
export default MenuWeCv