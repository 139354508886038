// IMPORT REACT
import React,{ useRef, useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE PARAMS, USE NAVIGATE, LINK
import { useParams, useNavigate, Link } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../../Layout/MenuDonasi.jsx'
// MENU WE AQIQAH HASIL
const MenuWeAqiqahHasil = () => { // KURUNG BUKA MENU WE AQIQAH HASIL
    // MEMBUAT VARIABEL WE AQIQAH HASIL
    const { panggilanParams } = useParams();
    const { jenisWeAqiqahParams } = useParams();
    const [panggilan, setPanggilan] = useState('')
    const [jenisUda, setJenisUda] = useState('')
    const [displayPembayaran, setDisplayPembayaran] = useState('')
    const [displayLoader, setDisplayLoader] = useState('')
    const [display, setDisplay] = useState('')
    const [name, setName] = useState('')
    const [total,] = useState('45000')
    const [order_id, setOrder_id] = useState('')
    const [tokenTransaksi, setTokenTransaksi] = useState('')
    const [kodeOrder, setKodeOrder] = useState('')
    const [apiWhatsapp, setApiWhatsapp] = useState('')
    const [namaBapak, setNamaBapak] = useState('')
    const [namaIbu, setNamaIbu] = useState('')
    const [salinLink, setSalinLink] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL INPUTREF BY USEREF
    const inputRef = useRef(null);
    // MEMBUAT VARIABEL NAVIGATE BY USENAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        selectWeAqiqah()
        selectTransaksi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setName(decoded.name)
            setExpire(decoded.exp)
            setDisplayPembayaran("none")
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-aqiqah", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setName(decoded.name)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES MENAMPILKAN WE AQIQAH DARI DB BERDASARKAN PANGGILAN BY PARAMS
    const selectWeAqiqah = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeAqiqahBE/${panggilanParams}`)
            setNamaBapak(response.data.namaBapak)
            setNamaIbu(response.data.namaIbu)
            setApiWhatsapp("https://api.whatsapp.com/send?text=https://webew.id/undangan-digital-aqiqah/" + response.data.salinLink + "/Bapak%20Ibu")
            setSalinLink('https://webew.id/undangan-digital-aqiqah/'+response.data.salinLink + "/Bapak%20Ibu")
            setJenisUda(response.data.jenisUndanganDigitalAqiqah)
            const Seconds = new Date().getSeconds()
            const result = Math.random().toString(36).substring(2,12);
            setOrder_id(response.data.jenisUndanganDigitalAqiqah + result + Seconds)
        }catch(error){
            console.log(error)
        }
    }
    // PROSES TRANSAKSI
    const transaksi = async (e) => {
        e.preventDefault()
        try{
            const data = {
                nama: name,
                order_id: order_id,
                total: total,
                panggilan: panggilan
            }
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post("https://server13.webew.id/prosesTransaksiBE",data,config)
            setTokenTransaksi(response.data.tokenTransaksi)
        }catch(error){
            console.log(error)
        }
    }
    // PROSES MENYIMPAN TRANSAKSI KE DB
    const transaksiBerhasil = async (e) => {
        e.preventDefault()
        try{
            setDisplayLoader("")
            await axios.post("https://server13.webew.id/transaksiBerhasilBE",{
                nama: name,
                order_id: order_id,
                total: total,
                panggilan: panggilan
            })
            window.location.reload()
        }catch(error){
            console.log(error)
        }
    }
    // USEEFFECT TRANSAKSI
    useEffect(()=>{
        if(tokenTransaksi){
            window.snap.pay(tokenTransaksi, {
                onSuccess: (result) => {
                    setDisplayPembayaran("")
                    localStorage.setItem("Pembayaran", JSON.stringify(result))
                    setTokenTransaksi("")
                },
                onPending: (result) => {
                    window.location.reload()
                    localStorage.setItem("Pembayaran", JSON.stringify(result))
                    setTokenTransaksi("")
                },
                onError: (error) => {
                    window.location.reload()
                    console.log(error)
                    setTokenTransaksi("")
                },
                onClose: () => {
                    window.location.reload()
                    console.log("Anda belum menyelesaikan pembayaran")
                    setTokenTransaksi("")
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenTransaksi])
    useEffect(()=>{
        const midtransUrl = "https://app.midtrans.com/snap/snap.js"
        let scriptTag = document.createElement("script")
        scriptTag.src = midtransUrl
        const midtransClientKey = "Mid-client-D6LOR1Eecoo9s-wv"
        scriptTag.setAttribute("data-client-key", midtransClientKey)
        document.body.appendChild(scriptTag)
        return() => {
            document.body.removeChild(scriptTag)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // PROSES MENAMPILKAN DATA TRANSAKSI BERDASARKAN PANGGILAN BY PARAMS
    const selectTransaksi = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectTransaksiBE/${panggilanParams}`)
            let kodeOrderId = response.data.order_id
            setKodeOrder(kodeOrderId.substring(0, 3))
        }catch(error){
            console.log(error)
        }
    }
    // PROSES MENYALIN TEXT / LINK
    function copyToClipboard(e) {
        inputRef.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // PROSES MENUTUP MODAL
    const tutup = () => {
        setDisplay("none")
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL */}
                {/* MODAL DONASI */}
                    <MenuDonasi/>
                {/* TUTUP MODAL DONASI */}
                {/* Modal MESSAGE*/}
                    <div style={{position:"fixed",zIndex:"9999",backgroundColor:"#282a3a8b",height:"100vh",width:"100%",display:display}}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-6">
                                    <div style={{backgroundColor:"white", width:"100%", padding:"20px",borderRadius:"20px",marginTop:"28vh",boxShadow:"-10px 10px 25px -15px #7f7f7f"}}>
                                        {jenisUda !== jenisWeAqiqahParams
                                            ?<h6 style={{textAlign:"center",color:"red",fontWeight:"700"}}>Maaf ya kamu belum isi form data, silahkan isi form data dulu ya</h6> :<h6 className='card-text-rupiah mb-2' style={{textAlign:"center",fontWeight:"700"}}>Hore undangan digital Aqiqah tema {jenisUda} sudah jadi<br /><p style={{margin:"20px 0 0 0",fontSize:"12pt",fontWeight:"500"}}>Silahkan lakukan proses pembayaran, jika sudah nanti tekan salin link lalu bagikan ke orang-orang ya, jangan lupa Ganti tulisan "/Bapak%20Ibu" dengan nama tamu di link</p></h6>
                                        }
                                        {jenisUda !== jenisWeAqiqahParams
                                            ?<Link to={`/menu-we-aqiqah-form/${panggilan}/${jenisWeAqiqahParams}`} className="btn btn-webew-product" aria-label="Kembali webew.id" style={{marginTop:"18px"}}>Oke Siap</Link> :<button type="button" className="btn btn-webew-product" style={{marginTop:"18px"}} onClick={tutup}>Oke Siap</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{position:"fixed",zIndex:"9999",backgroundColor:"#282a3a8b",height:"100vh",width:"100%",display:displayPembayaran}}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-6">
                                    <div style={{backgroundColor:"white", width:"100%", padding:"20px",borderRadius:"20px",marginTop:"28vh",boxShadow:"-10px 10px 25px -15px #7f7f7f"}}>
                                        <h6 className='card-text-rupiah mb-2' style={{textAlign:"center",fontWeight:"700"}}>Hore Pembayaran Berhasil<br /><p style={{margin:"20px 0 0 0",fontSize:"12pt",fontWeight:"500"}}>Silahkan tekan tombol oke, jika sudah nanti tekan salin link lalu bagikan ke orang-orang ya, jangan lupa Ganti tulisan "/Bapak%20Ibu" dengan nama tamu di link, terimakasih<br /></p></h6>
                                        <form onSubmit={transaksiBerhasil}>
                                            <input type="text" className="form-disable" value={name} readOnly/>
                                            <input type="text" className="form-disable" value={order_id} readOnly/>
                                            <input type="text" className="form-disable" value={total} readOnly/>
                                            <input type="text" className="form-disable" value={panggilan} readOnly/>
                                            <input type="submit" className="btn btn-webew-product" value="Okeee" style={{marginTop:"18px"}}/>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* TUTUP MODAL MESSAGE */}
            {/* TUTUP MODAL*/}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div  className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row mb-5 py-4">
                                        <div className="col-12 col-lg-6 mb-4">
                                            <div className="box p-4">
                                                <Link to={`/menu-we-aqiqah-form/${panggilan}/${jenisWeAqiqahParams}`} className="btn btn-webew-product" aria-label="Kembali webew.id" style={{width:"auto"}}><i class="bi bi-arrow-left-circle-fill"></i> Kembali</Link>
                                                <h3 className="text-center fw-bold" style={{marginTop:"10px"}}>Salin Link We Aqiqah</h3>
                                                {kodeOrder === jenisUda
                                                    ?<p className="text-center fw-norman">Silahkan klik tombol salin link, lalu bagikan ke saudara atau lainnya yaa, bisa juga bagikan lewat WhatsApp</p>
                                                    :<p className="text-center fw-norman">Silahkan klik tombol beli, jika sudah maka kamu bisa mendapatkan link yang berisikan undangan digital aqiqah</p>
                                                }
                                                {kodeOrder === jenisUda
                                                    ?<Link to={`/menu-we-aqiqah-hasil/${panggilan}/${jenisWeAqiqahParams}`} className="btn btn-webew-product"><i class="bi bi-check-lg" style={{color:"white"}}></i> Sudah Dibeli</Link> 
                                                    :<form onSubmit={transaksi}>
                                                        <input type="text" className="form-disable" value={name} readOnly/>
                                                        <input type="text" className="form-disable" value={order_id} readOnly/>
                                                        <input type="text" className="form-disable" value={total} readOnly/>
                                                        <input type="text" className="form-disable" value={panggilan} readOnly/>
                                                        <input type="submit" className="btn btn-webew-product" value="Beli Rp 45.000"/>
                                                    </form>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="box p-4" style={{width: "100%"}}>
                                                <h4 className='text-center fw-bold'>Hai {namaBapak} dan {namaIbu}</h4>
                                                {kodeOrder === jenisUda
                                                    ?<div className="row">
                                                        <div className="col-12 col-sm-7 mt-2">
                                                            <input type="text" id="link" className="form-control form mt-3" ref={inputRef} value={salinLink} readOnly/>
                                                        </div>
                                                        <div className="col-12 col-sm-5 mt-2">
                                                            <button type="button" className="btn btn-webew-product" style={{marginTop:"16.5px"}} onClick={copyToClipboard}><i class="bi bi-clipboard-check" style={{color:"white"}}></i> Salin Link</button>
                                                        </div>
                                                        <div className="col-12 mt-2">
                                                            <a href={apiWhatsapp} className="btn btn-webew-product mt-4" style={{height:"auto"}}><i class="bi bi-share" style={{color:"white"}}></i> Atau bagikan lewat WhatsApp</a>
                                                        </div>
                                                    </div>
                                                    :<div className="row">
                                                        <div className="col-12 col-sm-7 mt-2">
                                                            <input type="text" id="link" className="form-control form mt-3" readOnly/>
                                                        </div>
                                                        <div className="col-12 col-sm-5 mt-2">
                                                            <button type="button" className="btn btn-webew-product" style={{marginTop:"16.5px"}}><i class="bi bi-lock-fill" style={{color:"white"}}></i> Salin Link</button>
                                                        </div>
                                                        <div className="col-12 mt-2">
                                                            <Link to={`/menu-we-aqiqah-form/${panggilan}/${jenisWeAqiqahParams}`} className="btn btn-webew-product mt-4" style={{height:"auto"}}><i class="bi bi-lock-fill" style={{color:"white"}}></i> Atau bagikan lewat WhatsApp</Link>
                                                        </div>
                                                    </div>
                                                }
                                                <h6 className='text-center fw-normal mt-4'>Kami dari webew.id mengucapkan selamat ya dan semoga acaranya lancar dan dimudahkan</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU WE AQIQAH HASIL
// EKSPOR
export default MenuWeAqiqahHasil