// IMPORT REACT
import React,{ useRef, useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT USE PARAMS
import { useParams } from "react-router-dom"
// IMPORT AOS
import AOS from "aos";
// WE PERNIKAHAN HASIL
const WePernikahanHasil = () => { // BUKA KURUNG WE PERNIKAHAN HASIL
    // MEMBUAT VARIABEL WE PERNIKAHAN HASIL
    const [msg, setMsg] = useState('')
    const [msgUcapan, setMsgUcapan] = useState('')
    const [nama, setNama] = useState('')
    const [status, setStatus] = useState('')
    const [ucapan, setUcapan] = useState('')
    const [urlFotoLatar, setUrlFotoLatar] = useState('')
    const [urlFotoPria, setUrlFotoPria] = useState('')
    const [urlFotoWanita, setUrlFotoWanita] = useState('')
    const [urlFotoGaleri1, setUrlFotoGaleri1] = useState('')
    const [urlFotoGaleri2, setUrlFotoGaleri2] = useState('')
    const [urlFotoGaleri3, setUrlFotoGaleri3] = useState('')
    const [urlFotoGaleri4, setUrlFotoGaleri4] = useState('')
    const [urlFotoGaleri5, setUrlFotoGaleri5] = useState('')
    const [urlFotoGaleri6, setUrlFotoGaleri6] = useState('')
    const [namaPanggilPria, setNamaPanggilPria] = useState('')
    const [namaPanggilWanita, setNamaPanggilWanita] = useState('')
    const [namaLengkapPria, setNamaLengkapPria] = useState('')
    const [namaLengkapWanita, setNamaLengkapWanita] = useState('')
    const [namaBapakPria, setNamaBapakPria] = useState('')
    const [namaBapakWanita, setNamaBapakWanita] = useState('')
    const [namaIbuPria, setNamaIbuPria] = useState('')
    const [namaIbuWanita, setNamaIbuWanita] = useState('')
    const [hariAkad, setHariAkad] = useState('')
    const [tanggalAkad, setTanggalAkad] = useState('')
    const [jamAkadMulai, setJamAkadMulai] = useState('')
    const [jamAkadAkhir, setJamAkadAkhir] = useState('')
    const [hariResepsi, setHariResepsi] = useState('')
    const [tanggalResepsi, setTanggalResepsi] = useState('')
    const [jamResepsiMulai, setJamResepsiMulai] = useState('')
    const [jamResepsiAkhir, setJamResepsiAkhir] = useState('')
    const [alamatAkad, setAlamatAkad] = useState('')
    const [linkMapsAlamatAkad, setLinkMapsAlamatAkad] = useState('')
    const [alamatResepsi, setAlamatResepsi] = useState('')
    const [linkMapsAlamatResepsi, setLinkMapsAlamatResepsi] = useState('')
    const [kataKata, setKataKata] = useState('')
    const [namaSurah, setNamaSurah] = useState('')
    const [namaBank1, setNamaBank1] = useState('')
    const [noRek1, setNoRek1] = useState('')
    const [atasNama1, setAtasNama1] = useState('')
    const [namaBank2, setNamaBank2] = useState('')
    const [noRek2, setNoRek2] = useState('')
    const [atasNama2, setAtasNama2] = useState('')
    const [jenisUndanganDigitalPernikahan, setJenisUndanganDigitalPernikahan] = useState('')
    const [panggilan, setPanggilan] = useState('')
    const [salinLinkk, setSalinLinkk] = useState('')
    const [bukaUndangan, setBukaUndangan] = useState('')
    const [tutupButton, setTutupButton] = useState('')
    const [kalenderAkad, setKalenderAkad] = useState('')
    const [kalenderResepsi, setKalenderResepsi] = useState('')
    const [dataUcapan, setDataUcapan] = useState([])
    const { salinLink } = useParams();
    const { namaTamu } = useParams();
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const inputNoRek1 = useRef(null);
    const inputNoRek2 = useRef(null);
    // USEEFFECT
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        selectWePernikahanHasil()
        selectUcapanWePernikahanLive()
        refresh()
    }, [])
    // PROSES MENAMPILKAN DATA WE PERNIKAHAN BERDASARKAN SALIN LINK BY PARAMS
    const selectWePernikahanHasil = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWePernikahanHasilBE/${salinLink}`)
            setUrlFotoLatar(response.data.urlFotoLatar)
            setUrlFotoPria(response.data.urlFotoPria)
            setUrlFotoWanita(response.data.urlFotoWanita)
            setUrlFotoGaleri1(response.data.urlFotoGaleri1)
            setUrlFotoGaleri2(response.data.urlFotoGaleri2)
            setUrlFotoGaleri3(response.data.urlFotoGaleri3)
            setUrlFotoGaleri4(response.data.urlFotoGaleri4)
            setUrlFotoGaleri5(response.data.urlFotoGaleri5)
            setUrlFotoGaleri6(response.data.urlFotoGaleri6)
            setNamaPanggilPria(response.data.namaPanggilPria)
            setNamaPanggilWanita(response.data.namaPanggilWanita)
            setNamaLengkapPria(response.data.namaLengkapPria)
            setNamaLengkapWanita(response.data.namaLengkapWanita)
            setNamaBapakPria(response.data.namaBapakPria)
            setNamaBapakWanita(response.data.namaBapakWanita)
            setNamaIbuPria(response.data.namaIbuPria)
            setNamaIbuWanita(response.data.namaIbuWanita)
            setHariAkad(response.data.hariAkad)
            setTanggalAkad(response.data.tanggalAkad)
            setJamAkadMulai(response.data.jamAkadMulai)
            setJamAkadAkhir(response.data.jamAkadAkhir)
            setHariResepsi(response.data.hariResepsi)
            setTanggalResepsi(response.data.tanggalResepsi)
            setJamResepsiMulai(response.data.jamResepsiMulai)
            setJamResepsiAkhir(response.data.jamResepsiAkhir)
            setAlamatAkad(response.data.alamatAkad)
            setLinkMapsAlamatAkad(response.data.linkMapsAlamatAkad)
            setAlamatResepsi(response.data.alamatResepsi)
            setLinkMapsAlamatResepsi(response.data.linkMapsAlamatResepsi)
            setKataKata(response.data.kataKata)
            setNamaSurah(response.data.namaSurah)
            setNamaBank1(response.data.namaBank1)
            setNoRek1(response.data.noRek1)
            setAtasNama1(response.data.atasNama1)
            setNamaBank2(response.data.namaBank2)
            setNoRek2(response.data.noRek2)
            setAtasNama2(response.data.atasNama2)
            setJenisUndanganDigitalPernikahan(response.data.jenisUndanganDigitalPernikahan)
            setPanggilan(response.data.panggilan)
            setSalinLinkk(response.data.salinLink)
            const tanggalAwalAkad = response.data.tanggalAkad.replace(/[^\w\s]/gi, '') + "T" + response.data.jamAkadMulai.replace(/[^\w\s]/gi, '') + "00"
            const tanggalAkhirAkad = response.data.tanggalAkad.replace(/[^\w\s]/gi, '') + "T" + response.data.jamAkadAkhir.replace(/[^\w\s]/gi, '') + "00"
            setKalenderAkad(`https://calendar.google.com/calendar/render?action=TEMPLATE&text=Pernikahan ${response.data.namaPanggilPria} dan ${response.data.namaPanggilWanita}&details=Kami mengharapkan kehadiran para tamu undangan di acara kami yang berbahagia ini yaitu pernikahan (Akad) kami ${response.data.namaPanggilPria} dan ${response.data.namaPanggilWanita} pada hari ${response.data.hariAkad}, ${response.data.tanggalAkad}, dari jam ${response.data.jamAkadMulai} s/d ${response.data.jamAkadAkhir}. Ditunggu kehadirannya yaaa&dates=${tanggalAwalAkad}/${tanggalAkhirAkad}&location=Indonesia`)
            const tanggalAwalResepsi = response.data.tanggalResepsi.replace(/[^\w\s]/gi, '') + "T" + response.data.jamResepsiMulai.replace(/[^\w\s]/gi, '') + "00"
            const tanggalAkhirResepsi = response.data.tanggalResepsi.replace(/[^\w\s]/gi, '') + "T" + response.data.jamResepsiAkhir.replace(/[^\w\s]/gi, '') + "00"
            setKalenderResepsi(`https://calendar.google.com/calendar/render?action=TEMPLATE&text=Pernikahan ${response.data.namaPanggilPria} dan ${response.data.namaPanggilWanita}&details=Kami mengharapkan kehadiran para tamu undangan di acara kami yang berbahagia ini yaitu pernikahan (Resepsi) kami ${response.data.namaPanggilPria} dan ${response.data.namaPanggilWanita} pada hari ${response.data.hariResepsi}, ${response.data.tanggalResepsi}, dari jam ${response.data.jamResepsiMulai} s/d ${response.data.jamResepsiAkhir}. Ditunggu kehadirannya yaaa&dates=${tanggalAwalResepsi}/${tanggalAkhirResepsi}&location=Indonesia`)
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES MENAMPILKAN UCAPAN WE PERNIKAHAN LIVE BERDASARKAN SALIN LINK BY PARAMS
    const selectUcapanWePernikahanLive = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectUcapanWePernikahanLiveBE/${salinLink}`)
            setDataUcapan(response.data)
        }catch(error){
            if(error.response){
                setMsgUcapan(error.response.data.msg)
            }
        }
    }
    // PROSES MENYIMPAN UCAPAN WE PERNIKAHAN
    const createUcapanWePernikahan = async(e)=>{
        e.preventDefault()
        try {
            await axios.post('https://server13.webew.id/createUcapanWePernikahanBE',{
                nama: nama,
                status: status,
                ucapan: ucapan,
                salinLink: salinLinkk,
                panggilan: panggilan
            })
            setMsgUcapan("Ucapan Berhasil Dikirim")
            window.location.reload()
        } catch (error) {
            if(error.response){
                console.log(error.response.data.msg)
            }
        }
    }
    // PROSES REFRESH HALAMAN
    const refresh = async() => {
        setBukaUndangan("none")
    }
    // PROSES BUTTON BUKA UNDANGAN
    const btnBukaUndangan = () => {
        setBukaUndangan("")
        setTutupButton("none")
        audioRef.current.play()
        setIsPlaying(true)
    }
    // BUTTON PLAY MUSSIC
    const handlePlay = () => {
        audioRef.current.play()
        setIsPlaying(true)
    }
    // BUTTON PAUSE MUSIC
    const handlePause = () => {
        audioRef.current.pause()
        setIsPlaying(false)
    }
    // BUTTON PLAY N PAUSE MUSIC
    const handlePlayPause = () => {
        if (isPlaying) {
            handlePause()
        } else {
            handlePlay()
        }
    }
    // PROSES SALIN REKENING 1
    function salinRek1(e) {
        inputNoRek1.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // PROSES SALIN REKENING 2
    function salinRek2(e) {
        inputNoRek2.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div className="background-home">
            {/* JENIS WE PERNIKAHAN FLOWER */}
            {jenisUndanganDigitalPernikahan === "AGL"
                ?<section id='AGL'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0129004d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button onClick={btnBukaUndangan} className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#a7ff4f15",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-45px"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"30px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"30px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png" className='w-100' style={{marginTop:"-10px"}} alt="bunga" />
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#a7ff4f15",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariAkad}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalAkad}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamAkadMulai} sd {jamAkadAkhir} WIB</h5>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13991173_5386317.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariResepsi}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalResepsi}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamResepsiMulai} sd {jamResepsiAkhir} WIB</h5>
                                                <a href={kalenderResepsi} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13991173_5386317.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-60px"}}></div>
                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Nikah</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatAkad}</h6>
                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatResepsi}</h6>
                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#a7ff4f15",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{kataKata}</h6>
                                                <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaSurah}</h5>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13991173_5386317.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-60px"}}></div>
                            <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                            <div className="container-fluid">
                                <div className='rowes justify-content-center'>
                                    <div className="column">
                                        <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                    <div className="column">
                                        <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png" className='w-100' alt="bunga" />
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#a7ff4f15",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">

                                        <div className="col-12 col-md-10 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div>
                                                            <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</h5>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek1' className="form form-control fw-bold mb-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} ref={inputNoRek1} value={noRek1}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="form form-control fw-bold mb-4" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} onClick={salinRek1}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div>
                                                            <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</h5>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek2' className="form form-control fw-bold mb-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} ref={inputNoRek2} value={noRek2}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="form form-control fw-bold mb-4" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} onClick={salinRek2}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13991173_5386317.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                                    <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                        <label className='mx-2 fw-semibold' htmlFor="nama">Nama</label>
                                                        <input type="text" id='nama' name='nama' className='form form-control mb-2' required style={{color:"rgb(108, 108, 108)"}} maxLength="100" value={nama} onChange={(e)=>setNama(e.target.value)}/>
                                                        <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                        <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                        <label className='mx-2 fw-semibold' htmlFor="status">Status</label>
                                                        <select className="form-select form mb-2" id="status" name='status' aria-label="status" required style={{color:"rgb(108, 108, 108)"}} value={status} onChange={(e)=>setStatus(e.target.value)}>
                                                            <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                            <option value="Saudara">Saudara</option>
                                                            <option value="Atasan Kerja">Atasan Kerja</option>
                                                            <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                            <option value="Dosen/Guru">Dosen/Guru</option>
                                                            <option value="Teman Kerja">Teman Kerja</option>
                                                            <option value="Teman Sekolah">Teman Sekolah</option>
                                                            <option value="Teman">Teman</option>
                                                        </select>
                                                        <label className='mx-2 fw-semibold' htmlFor="ucapan">Ucapan</label>
                                                        <input type="text" id='ucapan' name='ucapan' className='form form-control mb-4' required style={{color:"rgb(108, 108, 108)"}} maxLength="300" value={ucapan} onChange={(e)=>setUcapan(e.target.value)}/>
                                                        <input type="submit" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                        <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                                    </form>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13991173_5386317.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                                <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                    <table>
                                                        <tbody>
                                                            {dataUcapan.map((user,index)=>(
                                                                <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13991173_5386317.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-60px"}}></div>
                            <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/hand-painted-watercolor-tropical-leaves-background_13900114.htm#query=tropical%20background&position=3&from_view=search&track=ais" aria-label="By pikisuperstar">
                                            <input type="button" value="By pikisuperstar" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/realistic-tropical-leaves-background_13991173.htm#page=2&query=tropical&position=3&from_view=search&track=sph" aria-label="By pikisuperstar">
                                            <input type="button" value="By pikisuperstar" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                                <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "AGA"
                ?<section id='AGA'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button onClick={btnBukaUndangan} className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#4f8dff15",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-15px"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"30px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"30px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png" className='w-100' style={{marginTop:"-10px"}} alt="bunga" />
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#4f8dff15",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariAkad}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalAkad}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamAkadMulai} sd {jamAkadAkhir} WIB</h5>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/14309031_5470466.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariResepsi}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalResepsi}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamResepsiMulai} sd {jamResepsiAkhir} WIB</h5>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/14309031_5470466.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-25px"}}></div>
                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Nikah</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatAkad}</h6>
                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatResepsi}</h6>
                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#4f8dff15",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{kataKata}</h6>
                                                <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaSurah}</h5>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/14309031_5470466.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-25px"}}></div>
                            <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                            <div className="container-fluid">
                                <div className='rowes justify-content-center'>
                                    <div className="column">
                                        <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                    <div className="column">
                                        <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png" className='w-100' alt="bunga" />
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#4f8dff15",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">

                                        <div className="col-12 col-md-10 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div>
                                                            <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</h5>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek1' className="form form-control fw-bold mb-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} ref={inputNoRek1} value={noRek1}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="form form-control fw-bold mb-4" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} onClick={salinRek1}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div>
                                                            <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</h5>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek2' className="form form-control fw-bold mb-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} ref={inputNoRek2} value={noRek2}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="form form-control fw-bold mb-4" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} onClick={salinRek2}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/14309031_5470466.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                                <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                        <label className='mx-2 fw-semibold' htmlFor="nama">Nama</label>
                                                        <input type="text" id='nama' name='nama' className='form form-control mb-2' required style={{color:"rgb(108, 108, 108)"}} maxLength="100" value={nama} onChange={(e)=>setNama(e.target.value)}/>
                                                        <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                        <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                        <label className='mx-2 fw-semibold' htmlFor="status">Status</label>
                                                        <select className="form-select form mb-2" id="status" name='status' aria-label="status" required style={{color:"rgb(108, 108, 108)"}} value={status} onChange={(e)=>setStatus(e.target.value)}>
                                                            <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                            <option value="Saudara">Saudara</option>
                                                            <option value="Atasan Kerja">Atasan Kerja</option>
                                                            <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                            <option value="Dosen/Guru">Dosen/Guru</option>
                                                            <option value="Teman Kerja">Teman Kerja</option>
                                                            <option value="Teman Sekolah">Teman Sekolah</option>
                                                            <option value="Teman">Teman</option>
                                                        </select>
                                                        <label className='mx-2 fw-semibold' htmlFor="ucapan">Ucapan</label>
                                                        <input type="text" id='ucapan' name='ucapan' className='form form-control mb-4' required style={{color:"rgb(108, 108, 108)"}} maxLength="300" value={ucapan} onChange={(e)=>setUcapan(e.target.value)}/>
                                                        <input type="submit" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                        <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                                    </form>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/14309031_5470466.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                                <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                    <table>
                                                        <tbody>
                                                            {dataUcapan.map((user,index)=>(
                                                                <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/14309031_5470466.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-25px"}}></div>
                            <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/summer-pattern-background-zoom_8845952.htm#page=5&query=purple%20leaf%20background&position=34&from_view=search&track=ais" aria-label="By pikisuperstar">
                                            <input type="button" value="By pikisuperstar" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/gradient-tropical-leaves-background_14309031.htm#page=5&query=purple%20leaf%20background&position=46&from_view=search&track=ais" aria-label="By Freepik">
                                            <input type="button" value="By freepik" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                                <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "ALA"
                ?<section id='ALA'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#2920004d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button onClick={btnBukaUndangan} className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#ffe54f15",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-25px"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"30px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"30px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png" className='w-100' style={{marginTop:"-10px"}} alt="bunga" />
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#ffe54f15",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariAkad}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalAkad}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamAkadMulai} sd {jamAkadAkhir} WIB</h5>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5113181_2638116.png" className='w-100' alt="bunga" style={{marginTop:"-11px"}}/>
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariResepsi}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalResepsi}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamResepsiMulai} sd {jamResepsiAkhir} WIB</h5>
                                                <a href={kalenderResepsi} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5113181_2638116.png" className='w-100' alt="bunga" style={{marginTop:"-11px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-30px"}}></div>
                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Nikah</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatAkad}</h6>
                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatResepsi}</h6>
                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#ffe54f15",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{kataKata}</h6>
                                                <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaSurah}</h5>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5113181_2638116.png" className='w-100' alt="bunga" style={{marginTop:"-20px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-30px"}}></div>
                            <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                            <div className="container-fluid">
                                <div className='rowes justify-content-center'>
                                    <div className="column">
                                        <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                    <div className="column">
                                        <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png" className='w-100' alt="bunga" />
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#ffe54f15",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">

                                    <div className="col-12 col-md-10 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div>
                                                            <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</h5>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek1' className="form form-control fw-bold mb-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} ref={inputNoRek1} value={noRek1}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="form form-control fw-bold mb-4" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} onClick={salinRek1}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div>
                                                            <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</h5>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek2' className="form form-control fw-bold mb-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} ref={inputNoRek2} value={noRek2}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="form form-control fw-bold mb-4" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} onClick={salinRek2}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5113181_2638116.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                                    <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                        <label className='mx-2 fw-semibold' htmlFor="nama">Nama</label>
                                                        <input type="text" id='nama' name='nama' className='form form-control mb-2' required style={{color:"rgb(108, 108, 108)"}} maxLength="100" value={nama} onChange={(e)=>setNama(e.target.value)}/>
                                                        <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                        <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                        <label className='mx-2 fw-semibold' htmlFor="status">Status</label>
                                                        <select className="form-select form mb-2" id="status" name='status' aria-label="status" required style={{color:"rgb(108, 108, 108)"}} value={status} onChange={(e)=>setStatus(e.target.value)}>
                                                            <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                            <option value="Saudara">Saudara</option>
                                                            <option value="Atasan Kerja">Atasan Kerja</option>
                                                            <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                            <option value="Dosen/Guru">Dosen/Guru</option>
                                                            <option value="Teman Kerja">Teman Kerja</option>
                                                            <option value="Teman Sekolah">Teman Sekolah</option>
                                                            <option value="Teman">Teman</option>
                                                        </select>
                                                        <label className='mx-2 fw-semibold' htmlFor="ucapan">Ucapan</label>
                                                        <input type="text" id='ucapan' name='ucapan' className='form form-control mb-4' required style={{color:"rgb(108, 108, 108)"}} maxLength="300" value={ucapan} onChange={(e)=>setUcapan(e.target.value)}/>
                                                        <input type="submit" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                        <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                                    </form>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5113181_2638116.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                                <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                    <table>
                                                        <tbody>
                                                            {dataUcapan.map((user,index)=>(
                                                                <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5113181_2638116.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-30px"}}></div>
                            <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/realistic-autumn-background_9260611.htm#page=6&query=yellow%20leaf&position=16&from_view=search&track=ais" aria-label="By pikisuperstar">
                                            <input type="button" value="By pikisuperstar" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/flat-autumn-forest-leaves-background_5113181.htm#page=3&query=yellow%20leaf%20background&position=20&from_view=search&track=ais" aria-label="By Freepik">
                                            <input type="button" value="By freepik" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                                <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                        </section>
                    </div>
                </section>
                : <section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "AMA"
                ?<section id='AMA'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#2900004d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button onClick={btnBukaUndangan} className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#ff4f4f15",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"30px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"30px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png" className='w-100' style={{marginTop:"-10px"}} alt="bunga" />
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#ff4f4f15",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariAkad}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalAkad}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamAkadMulai} sd {jamAkadAkhir} WIB</h5>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5020958_2649322.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariResepsi}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalResepsi}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamResepsiMulai} sd {jamResepsiAkhir} WIB</h5>
                                                <a href={kalenderResepsi} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5020958_2649322.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-20px"}}></div>
                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Nikah</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatAkad}</h6>
                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatResepsi}</h6>
                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#ff4f4f15",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{kataKata}</h6>
                                                <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaSurah}</h5>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5020958_2649322.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-20px"}}></div>
                            <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                            <div className="container-fluid">
                                <div className='rowes justify-content-center'>
                                    <div className="column">
                                        <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                    <div className="column">
                                        <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png" className='w-100 mt-4' alt="bunga" />
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#ff4f4f15",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">

                                    <div className="col-12 col-md-10 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div>
                                                            <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</h5>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek1' className="form form-control fw-bold mb-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} ref={inputNoRek1} value={noRek1}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="form form-control fw-bold mb-4" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} onClick={salinRek1}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div>
                                                            <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</h5>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek2' className="form form-control fw-bold mb-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} ref={inputNoRek2} value={noRek2}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="form form-control fw-bold mb-4" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} onClick={salinRek2}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5020958_2649322.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                                    <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                        <label className='mx-2 fw-semibold' htmlFor="nama">Nama</label>
                                                        <input type="text" id='nama' name='nama' className='form form-control mb-2' required style={{color:"rgb(108, 108, 108)"}} maxLength="100" value={nama} onChange={(e)=>setNama(e.target.value)}/>
                                                        <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                        <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                        <label className='mx-2 fw-semibold' htmlFor="status">Status</label>
                                                        <select className="form-select form mb-2" id="status" name='status' aria-label="status" required style={{color:"rgb(108, 108, 108)"}} value={status} onChange={(e)=>setStatus(e.target.value)}>
                                                            <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                            <option value="Saudara">Saudara</option>
                                                            <option value="Atasan Kerja">Atasan Kerja</option>
                                                            <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                            <option value="Dosen/Guru">Dosen/Guru</option>
                                                            <option value="Teman Kerja">Teman Kerja</option>
                                                            <option value="Teman Sekolah">Teman Sekolah</option>
                                                            <option value="Teman">Teman</option>
                                                        </select>
                                                        <label className='mx-2 fw-semibold' htmlFor="ucapan">Ucapan</label>
                                                        <input type="text" id='ucapan' name='ucapan' className='form form-control mb-4' required style={{color:"rgb(108, 108, 108)"}} maxLength="300" value={ucapan} onChange={(e)=>setUcapan(e.target.value)}/>
                                                        <input type="submit" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                        <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                                    </form>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5020958_2649322.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                                <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                    <table>
                                                        <tbody>
                                                            {dataUcapan.map((user,index)=>(
                                                                <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5020958_2649322.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png" className='w-100' style={{transform:"rotate(180deg)"}} alt="bunga" />
                            <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-20px"}}></div>
                            <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5045257.htm#page=3&query=read%20leaf%20background&position=14&from_view=search&track=ais" aria-label="By Freepik">
                                            <input type="button" value="By freepik" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5020958.htm#page=7&query=red%20leaf%20background&position=34&from_view=search&track=ais" aria-label="By Freepik">
                                            <input type="button" value="By xfreepik" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                                <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE PERNIKAHAN BLACK N WHITE */}
            {jenisUndanganDigitalPernikahan === "BW1"
                ?<section id='BW1'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#63636350",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-1 px-4' onClick={btnBukaUndangan} style={{border:"4px solid rgb(108, 108, 108)",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#63636350",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb1.png" className='w-100'alt="bunga" />
                            <h1 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb2.png" className='w-100' style={{marginTop:"10px"}} alt="bunga" />
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto",padding:"5vh 0 5vh 0"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-5 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariAkad}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalAkad}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-12 col-md-5 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariResepsi}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalResepsi}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                <a href={kalenderResepsi} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb1.png" className='w-100' alt="bunga" />
                            <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Nikah</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatAkad}</h6>
                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatResepsi}</h6>
                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb2.png" className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-6 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{kataKata}</h6>
                                                <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">({namaSurah})</h5>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb1.png" className='w-100'  alt="bunga" />
                            <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                            <div className="container-fluid">
                                <div className='rowes justify-content-center'>
                                    <div className="column">
                                        <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                    <div className="column">
                                        <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb2.png" className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto",padding:"5vh 0 5vh 0"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">

                                        <div className="col-12 col-md-10 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</p>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek1} value={noRek1}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="fw-bold mt-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}} onClick={salinRek1}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</p>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek2} value={noRek2}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="fw-bold mt-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}} onClick={salinRek2}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                                <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                    <label className='mx-2 fw-semibold' htmlFor="nama" style={{color:"rgb(108, 108, 108)"}}>Nama</label>
                                                    <input type="text" id='nama' className='form form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required style={{color:"rgb(108, 108, 108)"}}/>
                                                    <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                    <label className='mx-2 fw-semibold' htmlFor="status" style={{color:"rgb(108, 108, 108)"}}>Status</label>
                                                    <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                    <select className="form-select form mb-2" id="status" aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required style={{color:"rgb(108, 108, 108)"}}>
                                                        <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                        <option value="Saudara">Saudara</option>
                                                        <option value="Atasan Kerja">Atasan Kerja</option>
                                                        <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                        <option value="Dosen/Guru">Dosen/Guru</option>
                                                        <option value="Teman Kerja">Teman Kerja</option>
                                                        <option value="Teman Sekolah">Teman Sekolah</option>
                                                        <option value="Teman">Teman</option>
                                                    </select>
                                                    <label className='mx-2 fw-semibold' htmlFor="ucapan" style={{color:"rgb(108, 108, 108)"}}>Ucapan</label>
                                                    <input type="text" id='ucapan' className='form form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required style={{color:"rgb(108, 108, 108)"}}/>
                                                    <input type="submit" className='mx-auto d-block fw-bold py-1' style={{width:"80%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                    <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                                </form>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                                <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                    <table>
                                                        <tbody>
                                                            {dataUcapan.map((user,index)=>(
                                                                <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb1.png" className='w-100'  alt="bunga" />
                            <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/elegant-wedding-decorative-floral-background_9853423.htm#query=black%20and%20white%20flower%20background&position=10&from_view=search&track=ais" aria-label="By Harryarts">
                                            <input type="button" value="By Harryarts" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/beauty-flower-leaves-line-art-collection_27229344.htm#&position=9&from_view=undefined" aria-label="By orchidart">
                                            <input type="button" value="By orchidart" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                                <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb2.png" className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "BW2"
                ?<section id='BW2'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#63636350",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-1 px-4' onClick={btnBukaUndangan} style={{border:"4px solid rgb(108, 108, 108)",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#63636350",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb4.png" className='w-100'alt="bunga" />
                            <h1 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb5.png" className='w-100' style={{marginTop:"10px"}} alt="bunga" />
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto",padding:"5vh 0 5vh 0"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-5 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariAkad}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalAkad}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-12 col-md-5 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariResepsi}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalResepsi}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                <a href={kalenderResepsi} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb4.png" className='w-100' alt="bunga" />
                            <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Nikah</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatAkad}</h6>
                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatResepsi}</h6>
                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb5.png" className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-6 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{kataKata}</h6>
                                                <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">({namaSurah})</h5>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb4.png" className='w-100'  alt="bunga" />
                            <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                            <div className="container-fluid">
                                <div className='rowes justify-content-center'>
                                    <div className="column">
                                        <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                    <div className="column">
                                        <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb5.png" className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto",padding:"5vh 0 5vh 0"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">

                                        <div className="col-12 col-md-10 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</p>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek1} value={noRek1}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="fw-bold mt-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}} onClick={salinRek1}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</p>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek2} value={noRek2}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="fw-bold mt-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}} onClick={salinRek2}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                                <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                    <label className='mx-2 fw-semibold' htmlFor="nama" style={{color:"rgb(108, 108, 108)"}}>Nama</label>
                                                    <input type="text" id='nama' className='form form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required style={{color:"rgb(108, 108, 108)"}}/>
                                                    <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                    <label className='mx-2 fw-semibold' htmlFor="status" style={{color:"rgb(108, 108, 108)"}}>Status</label>
                                                    <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                    <select className="form-select form mb-2" id="status" aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required style={{color:"rgb(108, 108, 108)"}}>
                                                        <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                        <option value="Saudara">Saudara</option>
                                                        <option value="Atasan Kerja">Atasan Kerja</option>
                                                        <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                        <option value="Dosen/Guru">Dosen/Guru</option>
                                                        <option value="Teman Kerja">Teman Kerja</option>
                                                        <option value="Teman Sekolah">Teman Sekolah</option>
                                                        <option value="Teman">Teman</option>
                                                    </select>
                                                    <label className='mx-2 fw-semibold' htmlFor="ucapan" style={{color:"rgb(108, 108, 108)"}}>Ucapan</label>
                                                    <input type="text" id='ucapan' className='form form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required style={{color:"rgb(108, 108, 108)"}}/>
                                                    <input type="submit" className='mx-auto d-block fw-bold py-1' style={{width:"80%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                    <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                                </form>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                                <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                    <table>
                                                        <tbody>
                                                            {dataUcapan.map((user,index)=>(
                                                                <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb4.png" className='w-100'  alt="bunga" />
                            <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/beautiful-hand-drawn-wedding-ornament-floral-background_9853397.htm#page=3&query=black%20and%20white%20flower%20background&position=3&from_view=search&track=ais" aria-label="By Harryarts">
                                            <input type="button" value="By Harryarts" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/beauty-flower-leaves-line-art-collection_27229344.htm#&position=9&from_view=undefined" aria-label="By orchidart">
                                            <input type="button" value="By orchidart" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                                <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb5.png" className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "BW3"
                ?<section id='BW3'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#63636350",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-1 px-4' onClick={btnBukaUndangan} style={{border:"4px solid rgb(108, 108, 108)",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#63636350",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb7.png" className='w-100'alt="bunga" />
                            <h1 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb8.png" className='w-100' style={{marginTop:"10px"}} alt="bunga" />
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto",padding:"5vh 0 5vh 0"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-5 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariAkad}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalAkad}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-12 col-md-5 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariResepsi}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalResepsi}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                <a href={kalenderResepsi} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb7.png" className='w-100' alt="bunga" />
                            <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Nikah</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatAkad}</h6>
                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatResepsi}</h6>
                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb8.png" className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-6 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{kataKata}</h6>
                                                <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">({namaSurah})</h5>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb7.png" className='w-100'  alt="bunga" />
                            <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                            <div className="container-fluid">
                                <div className='rowes justify-content-center'>
                                    <div className="column">
                                        <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                    <div className="column">
                                        <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb8.png" className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto",padding:"5vh 0 5vh 0"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-10 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</p>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek1} value={noRek1}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="fw-bold mt-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}} onClick={salinRek1}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</p>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek2} value={noRek2}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="fw-bold mt-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}} onClick={salinRek2}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                                <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                    <label className='mx-2 fw-semibold' htmlFor="nama" style={{color:"rgb(108, 108, 108)"}}>Nama</label>
                                                    <input type="text" id='nama' className='form form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required style={{color:"rgb(108, 108, 108)"}}/>
                                                    <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                    <label className='mx-2 fw-semibold' htmlFor="status" style={{color:"rgb(108, 108, 108)"}}>Status</label>
                                                    <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                    <select className="form-select form mb-2" id="status" aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required style={{color:"rgb(108, 108, 108)"}}>
                                                        <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                        <option value="Saudara">Saudara</option>
                                                        <option value="Atasan Kerja">Atasan Kerja</option>
                                                        <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                        <option value="Dosen/Guru">Dosen/Guru</option>
                                                        <option value="Teman Kerja">Teman Kerja</option>
                                                        <option value="Teman Sekolah">Teman Sekolah</option>
                                                        <option value="Teman">Teman</option>
                                                    </select>
                                                    <label className='mx-2 fw-semibold' htmlFor="ucapan" style={{color:"rgb(108, 108, 108)"}}>Ucapan</label>
                                                    <input type="text" id='ucapan' className='form form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required style={{color:"rgb(108, 108, 108)"}}/>
                                                    <input type="submit" className='mx-auto d-block fw-bold py-1' style={{width:"80%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                    <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                                </form>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                                <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                    <table>
                                                        <tbody>
                                                            {dataUcapan.map((user,index)=>(
                                                                <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb7.png" className='w-100'  alt="bunga" />
                            <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/hand-drawn-spring-background_12806202.htm#query=black%20and%20white%20flower%20background&position=38&from_view=search&track=ais" aria-label="By Freepik">
                                            <input type="button" value="By Freepik" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/beauty-flower-leaves-line-art-collection_27229344.htm#&position=9&from_view=undefined" aria-label="By orchidart">
                                            <input type="button" value="By orchidart" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                                <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb8.png" className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "BW4"
                ?<section id='BW4'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#63636350",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-1 px-4' onClick={btnBukaUndangan} style={{border:"4px solid rgb(108, 108, 108)",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#63636350",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb10.png" className='w-100'alt="bunga" />
                            <h1 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb11.png" className='w-100' style={{marginTop:"10px"}} alt="bunga" />
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto",padding:"5vh 0 5vh 0"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-5 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariAkad}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalAkad}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-12 col-md-5 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariResepsi}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalResepsi}</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                <a href={kalenderResepsi} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb10.png" className='w-100' alt="bunga" />
                            <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Nikah</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatAkad}</h6>
                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatResepsi}</h6>
                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb11.png" className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-6 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{kataKata}</h6>
                                                <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">({namaSurah})</h5>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb10.png" className='w-100'  alt="bunga" />
                            <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                            <div className="container-fluid">
                                <div className='rowes justify-content-center'>
                                    <div className="column">
                                        <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                    <div className="column">
                                        <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                    </div>
                                </div>
                            </div>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb11.png" className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto",padding:"5vh 0 5vh 0"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-10 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</p>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek1} value={noRek1}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="fw-bold mt-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}} onClick={salinRek1}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</p>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek2} value={noRek2}/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="fw-bold mt-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}} onClick={salinRek2}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                                <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                    <label className='mx-2 fw-semibold' htmlFor="nama" style={{color:"rgb(108, 108, 108)"}}>Nama</label>
                                                    <input type="text" id='nama' className='form form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required style={{color:"rgb(108, 108, 108)"}}/>
                                                    <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                    <label className='mx-2 fw-semibold' htmlFor="status" style={{color:"rgb(108, 108, 108)"}}>Status</label>
                                                    <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                    <select className="form-select form mb-2" id="status" aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required style={{color:"rgb(108, 108, 108)"}}>
                                                        <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                        <option value="Saudara">Saudara</option>
                                                        <option value="Atasan Kerja">Atasan Kerja</option>
                                                        <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                        <option value="Dosen/Guru">Dosen/Guru</option>
                                                        <option value="Teman Kerja">Teman Kerja</option>
                                                        <option value="Teman Sekolah">Teman Sekolah</option>
                                                        <option value="Teman">Teman</option>
                                                    </select>
                                                    <label className='mx-2 fw-semibold' htmlFor="ucapan" style={{color:"rgb(108, 108, 108)"}}>Ucapan</label>
                                                    <input type="text" id='ucapan' className='form form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required style={{color:"rgb(108, 108, 108)"}}/>
                                                    <input type="submit" className='mx-auto d-block fw-bold py-1' style={{width:"80%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                    <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                                </form>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                                <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                    <table>
                                                        <tbody>
                                                            {dataUcapan.map((user,index)=>(
                                                                <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png" className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb10.png" className='w-100'  alt="bunga" />
                            <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/floral-background-with-sketchy-style_2416402.htm#page=5&query=black%20and%20white%20flower%20background&position=13&from_view=search&track=ais" aria-label="By Freepik">
                                            <input type="button" value="By Freepik" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/beauty-flower-leaves-line-art-collection_27229344.htm#&position=9&from_view=undefined" aria-label="By orchidart">
                                            <input type="button" value="By orchidart" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                                <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb11.png" className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE PERNIKAHAN SPORT */}
            {jenisUndanganDigitalPernikahan === "FOT"
                ?<section id='FOT'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#ffffff31",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan//bg-min.webp')",border:"6px solid white",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{backgroundColor:"white",boxShadow:"0 10px 20px -10px #8e8e8e",border:"none",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#ffffff31",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section className='p-4' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3.png" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" className='mx-auto d-block' style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                            <h1 className='text-center fw-bold fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/fotografi/f2.png" className='mx-auto d-block pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"50px"}} alt="fotografi" /></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-1">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariAkad}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalAkad}</h5>
                                                        <h5 className='text-center pb-2' style={{marginTop:"-10px"}} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                        <a href={kalenderAkad} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/fotografi/f4.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"25px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/fotografi/f3.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariResepsi}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalResepsi}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                        <a href={kalenderResepsi} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Akad</h4>
                                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatAkad}</h6>
                                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/fotografi/f5.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/fotografi/f5.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Resepsi</h4>
                                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatResepsi}</h6>
                                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-6 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                                            <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{kataKata}</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">({namaSurah})</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b1.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginTop:"25px"}} alt="bingkai" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                                <div className="container-fluid">
                                    <div className='rowes justify-content-center'>
                                        <div className="column">
                                            <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                        <div className="column">
                                            <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">

                                    <div className="col-12 col-md-10 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek1} value={noRek1}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}} onClick={salinRek1}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek2} value={noRek2}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}} onClick={salinRek2}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                            <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama" style={{color:"rgb(108, 108, 108)"}}>Nama</label>
                                                <input type="text" id='nama' className='form form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required/>
                                                <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                <label className='mx-2 fw-semibold' htmlFor="status" style={{color:"rgb(108, 108, 108)"}}>Status</label>
                                                <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                <select className="form-select form mb-2" id="status" aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan" style={{color:"rgb(108, 108, 108)"}}>Ucapan</label>
                                                <input type="text" id='ucapan' className='form form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        {dataUcapan.map((user,index)=>(
                                                            <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='p-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-4">
                                            <a href="https://www.freepik.com/free-vector/professional-photographer-equipment-isometric-icons-collection-with-studio-portrait-photo-shoots-camera-light-drone-isolated-vector-illustration_6932664.htm#query=kamera&position=11&from_view=search&track=sph" aria-label="By macrovector">
                                                <input type="button" value="By macrovector" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <a href="https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph" aria-label="By macrovector">
                                                <input type="button" value="By macrovector" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                    <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                                <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            </div>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "BIK"
                ?<section id='BIK'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#ffffff31",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan//bg-min.webp')",border:"6px solid white",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{backgroundColor:"white",boxShadow:"0 10px 20px -10px #8e8e8e",border:"none",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#ffffff31",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section className='p-4' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3.png" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" className='mx-auto d-block' style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bike/b5.png" className='mx-auto d-block pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"50px"}} alt="fotografi" /></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-1">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariAkad}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalAkad}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                        <a href={kalenderAkad} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4" style={{marginLeft:"-15px"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bike/b1.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"25px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bike/b2.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariResepsi}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalResepsi}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                        <a href={kalenderResepsi} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Akad</h4>
                                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatAkad}</h6>
                                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bike/b3.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bike/b4.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Resepsi</h4>
                                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatResepsi}</h6>
                                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-6 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                                            <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{kataKata}</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">({namaSurah})</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b1.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginTop:"25px"}} alt="bingkai" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                                <div className="container-fluid">
                                    <div className='rowes justify-content-center'>
                                        <div className="column">
                                            <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                        <div className="column">
                                            <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek1} value={noRek1}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}} onClick={salinRek1}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek2} value={noRek2}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}} onClick={salinRek2}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                            <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama" style={{color:"rgb(108, 108, 108)"}}>Nama</label>
                                                <input type="text" id='nama' className='form form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required/>
                                                <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                <label className='mx-2 fw-semibold' htmlFor="status" style={{color:"rgb(108, 108, 108)"}}>Status</label>
                                                <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                <select className="form-select form mb-2" id="status" aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan" style={{color:"rgb(108, 108, 108)"}}>Ucapan</label>
                                                <input type="text" id='ucapan' className='form form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        {dataUcapan.map((user,index)=>(
                                                            <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='p-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-4">
                                            <a href="https://www.freepik.com/free-vector/bicycle-vintage-elements-set_5971242.htm#query=bike&position=23&from_view=search&track=sph" aria-label="By macrovector">
                                                <input type="button" value="By macrovector" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <a href="https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph" aria-label="By macrovector">
                                                <input type="button" value="By macrovector" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                    <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                                <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            </div>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "FOO"
                ?<section id='FOO'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#ffffff31",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan//bg-min.webp')",border:"6px solid white",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{backgroundColor:"white",boxShadow:"0 10px 20px -10px #8e8e8e",border:"none",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#ffffff31",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section className='p-4' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3.png" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" className='mx-auto d-block' style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/football/f5.png" className='mx-auto d-block pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"50px"}} alt="fotografi" /></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-1">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariAkad}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalAkad}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                        <a href={kalenderAkad} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4" style={{marginLeft:"-15px"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/football/f1.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"25px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/football/f2.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariResepsi}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalResepsi}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                        <a href={kalenderResepsi} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Akad</h4>
                                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatAkad}</h6>
                                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/football/f3.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/football/f4.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Resepsi</h4>
                                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatResepsi}</h6>
                                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-6 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                                            <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{kataKata}</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">({namaSurah})</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b1.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginTop:"25px"}} alt="bingkai" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                                <div className="container-fluid">
                                    <div className='rowes justify-content-center'>
                                        <div className="column">
                                            <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                        <div className="column">
                                            <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek1} value={noRek1}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}} onClick={salinRek1}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek2} value={noRek2}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}} onClick={salinRek2}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                            <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama" style={{color:"rgb(108, 108, 108)"}}>Nama</label>
                                                <input type="text" id='nama' className='form form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required/>
                                                <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                <label className='mx-2 fw-semibold' htmlFor="status" style={{color:"rgb(108, 108, 108)"}}>Status</label>
                                                <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                <select className="form-select form mb-2" id="status" aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan" style={{color:"rgb(108, 108, 108)"}}>Ucapan</label>
                                                <input type="text" id='ucapan' className='form form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        {dataUcapan.map((user,index)=>(
                                                            <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='p-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-4">
                                            <a href="https://www.freepik.com/free-vector/flat-football-players-collection_15275628.htm#query=football&position=7&from_view=search&track=sph" aria-label="By pikisuperstar">
                                                <input type="button" value="By pikisuperstar" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <a href="https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph" aria-label="By macrovector">
                                                <input type="button" value="By macrovector" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                    <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                                <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            </div>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "BAD"
                ?<section id='BAD'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#ffffff31",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan//bg-min.webp')",border:"6px solid white",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{backgroundColor:"white",boxShadow:"0 10px 20px -10px #8e8e8e",border:"none",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#ffffff31",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section className='p-4' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3.png" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" className='mx-auto d-block' style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/badminton/b5.png" className='mx-auto d-block pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"50px"}} alt="fotografi" /></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-1">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariAkad}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalAkad}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                        <a href={kalenderAkad} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4" style={{marginLeft:"-15px"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/badminton/b1.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"25px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/badminton/b2.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{hariResepsi}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{tanggalResepsi}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                        <a href={kalenderResepsi} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold"  data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine"style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Akad</h4>
                                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatAkad}</h6>
                                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4 "style={{marginLeft:"-15px"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/badminton/b3.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 "style={{marginLeft:"-15px",marginRight:"15px"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/badminton/b4.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Resepsi</h4>
                                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{alamatResepsi}</h6>
                                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-6 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                                            <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{kataKata}</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">({namaSurah})</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b1.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginTop:"25px"}} alt="bingkai" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                                <div className="container-fluid">
                                    <div className='rowes justify-content-center'>
                                        <div className="column">
                                            <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                        <div className="column">
                                            <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-10 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek1} value={noRek1}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}} onClick={salinRek1}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek2} value={noRek2}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}} onClick={salinRek2}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                            <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama" style={{color:"rgb(108, 108, 108)"}}>Nama</label>
                                                <input type="text" id='nama' className='form form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required/>
                                                <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                <label className='mx-2 fw-semibold' htmlFor="status" style={{color:"rgb(108, 108, 108)"}}>Status</label>
                                                <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                <select className="form-select form mb-2" id="status" aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan" style={{color:"rgb(108, 108, 108)"}}>Ucapan</label>
                                                <input type="text" id='ucapan' className='form form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        {dataUcapan.map((user,index)=>(
                                                            <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='p-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-4">
                                            <a href="https://www.freepik.com/free-vector/flat-badminton-player-silhouette-collection_4787467.htm#query=badminton%20vector&position=30&from_view=search&track=ais" aria-label="By pikisuperstar">
                                                <input type="button" value="By pikisuperstar" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <a href="https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph" aria-label="By macrovector">
                                                <input type="button" value="By macrovector" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                    <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                                <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            </div>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE PERNIKAHAN BLACK */}
            {jenisUndanganDigitalPernikahan === "BLG"
                ?<section id='BLG'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundImage:"linear-gradient(to bottom, #0000005a, #e6c40035)",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundColor:"black",borderLeft:"3px solid #e6c300",borderRight:"3px solid #e6c300",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"15px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"15px",borderLeft:"4px solid #e6c300",borderRight:"4px solid #e6c300"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px",color:"#e6c300"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold' style={{color:"#e6c300"}}>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{color:"#e6c300"}}>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{backgroundColor:"black",border:"3px solid #e6c300",borderRadius:"15px",color:"#e6c300",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundImage:"linear-gradient(to bottom, #0000005a, #e6c40035)",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section className='p-4' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2G.png" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" className='mx-auto d-block' style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"15px",borderLeft:"4px solid #e6c300",borderRight:"4px solid #e6c300"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"15px",borderLeft:"4px solid #e6c300",borderRight:"4px solid #e6c300"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"#e6c300"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#e6c40035",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-1">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid #e6c300",borderRight:"3px solid #e6c300"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5G.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#e6c300"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>Akad Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>{hariAkad}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"#e6c300"}}>{tanggalAkad}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"#e6c300"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                        <a href={kalenderAkad} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"#e6c300",border:"3px solid #e6c300"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4" style={{marginLeft:"-15px"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliG.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"25px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliG.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid #e6c300",borderRight:"3px solid #e6c300"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5G.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#e6c300"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>Resepsi Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>{hariResepsi}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"#e6c300"}}>{tanggalResepsi}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"#e6c300"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                        <a href={kalenderResepsi} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"#e6c300",border:"3px solid #e6c300"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid #e6c300",borderRight:"3px solid #e6c300"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5G.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#e6c300"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>Alamat Akad</h4>
                                                        <h6 className='text-center fw-normal pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>{alamatAkad}</h6>
                                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"#e6c300",border:"3px solid #e6c300"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliG.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliG.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid #e6c300",borderRight:"3px solid #e6c300"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5G.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#e6c300"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>Alamat Resepsi</h4>
                                                        <h6 className='text-center fw-normal pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>{alamatResepsi}</h6>
                                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"#e6c300",border:"3px solid #e6c300"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-6 my-4">
                                        <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3G.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                                            <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>{kataKata}</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>({namaSurah})</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b1G.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginTop:"25px"}} alt="bingkai" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#e6c40035",width:"100%",height:"auto"}}>
                                <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#e6c300"}}></div>
                                <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>Gallery Foto</h4>
                                <div className="container-fluid">
                                    <div className='rowes justify-content-center'>
                                        <div className="column">
                                            <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                        <div className="column">
                                            <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-10 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#e6c300"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div style={{backgroundColor:"transparant",borderRadius:"15px",width:"100%"}}>
                                                        <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>{namaBank1}</h5>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>{atasNama1}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form-control mb-3' ref={inputNoRek1} value={noRek1} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"15px",color:"#e6c300",backgroundColor:"black",border:"3px solid #e6c300"}}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="mx-auto d-block fw-bold py-2" onClick={salinRek1} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"80%",backgroundColor:"black",borderRadius:"15px",color:"#e6c300",border:"3px solid #e6c300"}}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div style={{backgroundColor:"transparant",borderRadius:"15px",width:"100%"}}>
                                                        <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>{namaBank2}</h5>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>{atasNama2}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form-control mb-3' ref={inputNoRek2} value={noRek2} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"15px",color:"#e6c300",backgroundColor:"black",border:"3px solid #e6c300"}}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="mx-auto d-block fw-bold py-2" onClick={salinRek2} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"80%",backgroundColor:"black",borderRadius:"15px",color:"#e6c300",border:"3px solid #e6c300"}}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                        
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#e6c300"}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>Beri Ucapan</h4>
                                            <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama" style={{color:"#e6c300"}}>Nama</label>
                                                <input type="text" id='nama' name='nama' className='form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required style={{borderRadius:"15px",color:"#e6c300",backgroundColor:"black",border:"3px solid #e6c300"}} maxLength="100" />
                                                <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                <label className='mx-2 fw-semibold' htmlFor="status" style={{color:"#e6c300"}}>Status</label>
                                                <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                <select className="form-select mb-2" id="status" name='status' aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required style={{borderRadius:"15px",color:"#e6c300",backgroundColor:"black",border:"3px solid #e6c300"}}>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan" style={{color:"#e6c300"}}>Ucapan</label>
                                                <input type="text" id='ucapan' name='ucapan' className='form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required style={{borderRadius:"15px",color:"#e6c300",backgroundColor:"black",border:"3px solid #e6c300"}} maxLength="300"/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"black",border:"3px solid #e6c300",borderRadius:"15px",color:"#e6c300"}}/>
                                                <h6 className='text-center mt-3' style={{color:"#e6c300"}}>{msgUcapan}</h6>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#e6c300"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#e6c300"}}>Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        {dataUcapan.map((user,index)=>(
                                                            <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"#e6c300",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"black",border:"3px solid #e6c300",color:"#e6c300",textAlign:"center",borderRadius:"15px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"black",border:"3px solid #e6c300",color:"#e6c300",textAlign:"left",borderRadius:"15px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='p-4' style={{backgroundColor:"#e6c40035",width:"100%",height:"auto"}}>
                                <div className='bi bi-image text-center' style={{fontSize:"30px",color:"#e6c300"}}></div>
                                <h4 className='text-center fw-bolder' style={{color:"#e6c300"}}>Image On Freepik</h4>
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-4">
                                            <a href="https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph" aria-label="By macrovector">
                                                <input type="button" value="By macrovector" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"black",borderRadius:"15px",color:"#e6c300",border:"3px solid #e6c300"}}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"#e6c300",marginTop:"25px"}}></div>
                                    <h4 className='text-center fw-bolder' style={{color:"#e6c300"}}>Song On Youtube</h4>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                                <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"black",borderRadius:"15px",color:"#e6c300",border:"3px solid #e6c300"}}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <h6 className='text-center fw-bolder mt-2' style={{color:"#e6c300"}}>Website By Webew.id</h6>
                            </div>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "BLP"
                ?<section id='BLP'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundImage:"linear-gradient(to bottom, #0000005a, #ff009935)",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundColor:"black",borderLeft:"3px solid #ffabde",borderRight:"3px solid #ffabde",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"15px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"15px",borderLeft:"4px solid #ffabde",borderRight:"4px solid #ffabde"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px",color:"#ffabde"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold' style={{color:"#ffabde"}}>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{color:"#ffabde"}}>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{backgroundColor:"black",border:"3px solid #ffabde",borderRadius:"15px",color:"#ffabde",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundImage:"linear-gradient(to bottom, #0000005a, #ff009935)",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section className='p-4' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2P.png" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" className='mx-auto d-block' style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"15px",borderLeft:"4px solid #ffabde",borderRight:"4px solid #ffabde"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"15px",borderLeft:"4px solid #ffabde",borderRight:"4px solid #ffabde"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"#ffabde"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#ff009935",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-1">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid #ffabde",borderRight:"3px solid #ffabde"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5P.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#ffabde"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>Akad Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>{hariAkad}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"#ffabde"}}>{tanggalAkad}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"#ffabde"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                        <a href={kalenderAkad} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"#ffabde",border:"3px solid #ffabde"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4" style={{marginLeft:"-15px"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliP.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"25px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliP.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid #ffabde",borderRight:"3px solid #ffabde"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5P.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#ffabde"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>Resepsi Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>{hariResepsi}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"#ffabde"}}>{tanggalResepsi}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"#ffabde"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                        <a href={kalenderResepsi} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"#ffabde",border:"3px solid #ffabde"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid #ffabde",borderRight:"3px solid #ffabde"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5P.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#ffabde"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>Alamat Akad</h4>
                                                        <h6 className='text-center fw-normal pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>{alamatAkad}</h6>
                                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"#ffabde",border:"3px solid #ffabde"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliP.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliP.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid #ffabde",borderRight:"3px solid #ffabde"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5P.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#ffabde"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>Alamat Resepsi</h4>
                                                        <h6 className='text-center fw-normal pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>{alamatResepsi}</h6>
                                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"#ffabde",border:"3px solid #ffabde"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-6 my-4">
                                        <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3P.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                                            <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>{kataKata}</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>({namaSurah})</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b1P.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginTop:"25px"}} alt="bingkai" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#ff009935",width:"100%",height:"auto"}}>
                                <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#ffabde"}}></div>
                                <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>Gallery Foto</h4>
                                <div className="container-fluid">
                                    <div className='rowes justify-content-center'>
                                        <div className="column">
                                            <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                        <div className="column">
                                            <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-10 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#ffabde"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div style={{backgroundColor:"transparant",borderRadius:"15px",width:"100%"}}>
                                                        <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>{namaBank1}</h5>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>{atasNama1}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form-control mb-3' ref={inputNoRek1} value={noRek1} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"15px",color:"#ffabde",backgroundColor:"black",border:"3px solid #ffabde"}}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="mx-auto d-block fw-bold py-2" onClick={salinRek1} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"80%",backgroundColor:"black",borderRadius:"15px",color:"#ffabde",border:"3px solid #ffabde"}}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div style={{backgroundColor:"transparant",borderRadius:"15px",width:"100%"}}>
                                                        <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>{namaBank2}</h5>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>{atasNama2}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form-control mb-3' ref={inputNoRek2} value={noRek2} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"15px",color:"#ffabde",backgroundColor:"black",border:"3px solid #ffabde"}}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="mx-auto d-block fw-bold py-2" onClick={salinRek2} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"80%",backgroundColor:"black",borderRadius:"15px",color:"#ffabde",border:"3px solid #ffabde"}}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                        
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#ffabde"}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>Beri Ucapan</h4>
                                            <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama" style={{color:"#ffabde"}}>Nama</label>
                                                <input type="text" id='nama' name='nama' className='form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required style={{borderRadius:"15px",color:"#ffabde",backgroundColor:"black",border:"3px solid #ffabde"}} maxLength="100" />
                                                <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                <label className='mx-2 fw-semibold' htmlFor="status" style={{color:"#ffabde"}}>Status</label>
                                                <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                <select className="form-select mb-2" id="status" name='status' aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required style={{borderRadius:"15px",color:"#ffabde",backgroundColor:"black",border:"3px solid #ffabde"}}>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan" style={{color:"#ffabde"}}>Ucapan</label>
                                                <input type="text" id='ucapan' name='ucapan' className='form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required style={{borderRadius:"15px",color:"#ffabde",backgroundColor:"black",border:"3px solid #ffabde"}} maxLength="300"/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"black",border:"3px solid #ffabde",borderRadius:"15px",color:"#ffabde"}}/>
                                                <h6 className='text-center mt-3' style={{color:"#ffabde"}}>{msgUcapan}</h6>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#ffabde"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#ffabde"}}>Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        {dataUcapan.map((user,index)=>(
                                                            <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"#ffabde",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"black",border:"3px solid #ffabde",color:"#ffabde",textAlign:"center",borderRadius:"15px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"black",border:"3px solid #ffabde",color:"#ffabde",textAlign:"left",borderRadius:"15px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='p-4' style={{backgroundColor:"#ff009935",width:"100%",height:"auto"}}>
                                <div className='bi bi-image text-center' style={{fontSize:"30px",color:"#ffabde"}}></div>
                                <h4 className='text-center fw-bolder' style={{color:"#ffabde"}}>Image On Freepik</h4>
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-4">
                                            <a href="https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph" aria-label="By macrovector">
                                                <input type="button" value="By macrovector" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"black",borderRadius:"15px",color:"#ffabde",border:"3px solid #ffabde"}}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"#ffabde",marginTop:"25px"}}></div>
                                    <h4 className='text-center fw-bolder' style={{color:"#ffabde"}}>Song On Youtube</h4>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                                <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"black",borderRadius:"15px",color:"#ffabde",border:"3px solid #ffabde"}}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <h6 className='text-center fw-bolder mt-2' style={{color:"#ffabde"}}>Website By Webew.id</h6>
                            </div>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "BLT"
                ?<section id='BLT'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundImage:"linear-gradient(to bottom, #0000005a, #49dbff35)",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundColor:"black",borderLeft:"3px solid #95eaff",borderRight:"3px solid #95eaff",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"15px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"15px",borderLeft:"4px solid #95eaff",borderRight:"4px solid #95eaff"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px",color:"#95eaff"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold' style={{color:"#95eaff"}}>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{color:"#95eaff"}}>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{backgroundColor:"black",border:"3px solid #95eaff",borderRadius:"15px",color:"#95eaff",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundImage:"linear-gradient(to bottom, #0000005a, #49dbff35)",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section className='p-4' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2T.png" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" className='mx-auto d-block' style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"15px",borderLeft:"4px solid #95eaff",borderRight:"4px solid #95eaff"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"15px",borderLeft:"4px solid #95eaff",borderRight:"4px solid #95eaff"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"#95eaff"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#49dbff35",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-1">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid #95eaff",borderRight:"3px solid #95eaff"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5T.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#95eaff"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>Akad Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>{hariAkad}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"#95eaff"}}>{tanggalAkad}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"#95eaff"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                        <a href={kalenderAkad} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"#95eaff",border:"3px solid #95eaff"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4" style={{marginLeft:"-15px"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliT.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"25px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliT.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid #95eaff",borderRight:"3px solid #95eaff"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5T.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#95eaff"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>Resepsi Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>{hariResepsi}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"#95eaff"}}>{tanggalResepsi}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"#95eaff"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                        <a href={kalenderResepsi} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"#95eaff",border:"3px solid #95eaff"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid #95eaff",borderRight:"3px solid #95eaff"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5T.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#95eaff"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>Alamat Akad</h4>
                                                        <h6 className='text-center fw-normal pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>{alamatAkad}</h6>
                                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"#95eaff",border:"3px solid #95eaff"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliT.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliT.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid #95eaff",borderRight:"3px solid #95eaff"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5T.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#95eaff"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>Alamat Resepsi</h4>
                                                        <h6 className='text-center fw-normal pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>{alamatResepsi}</h6>
                                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"#95eaff",border:"3px solid #95eaff"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-6 my-4">
                                        <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3T.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                                            <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>{kataKata}</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>({namaSurah})</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b1T.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginTop:"25px"}} alt="bingkai" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#49dbff35",width:"100%",height:"auto"}}>
                                <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#95eaff"}}></div>
                                <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>Gallery Foto</h4>
                                <div className="container-fluid">
                                    <div className='rowes justify-content-center'>
                                        <div className="column">
                                            <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                        <div className="column">
                                            <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-10 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#95eaff"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div style={{backgroundColor:"transparant",borderRadius:"15px",width:"100%"}}>
                                                        <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>{namaBank1}</h5>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>{atasNama1}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form-control mb-3' ref={inputNoRek1} value={noRek1} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"15px",color:"#95eaff",backgroundColor:"black",border:"3px solid #95eaff"}}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="mx-auto d-block fw-bold py-2" onClick={salinRek1} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"80%",backgroundColor:"black",borderRadius:"15px",color:"#95eaff",border:"3px solid #95eaff"}}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div style={{backgroundColor:"transparant",borderRadius:"15px",width:"100%"}}>
                                                        <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>{namaBank2}</h5>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>{atasNama2}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form-control mb-3' ref={inputNoRek2} value={noRek2} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"15px",color:"#95eaff",backgroundColor:"black",border:"3px solid #95eaff"}}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="mx-auto d-block fw-bold py-2" onClick={salinRek2} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"80%",backgroundColor:"black",borderRadius:"15px",color:"#95eaff",border:"3px solid #95eaff"}}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                        
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#95eaff"}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>Beri Ucapan</h4>
                                            <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama" style={{color:"#95eaff"}}>Nama</label>
                                                <input type="text" id='nama' name='nama' className='form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required style={{borderRadius:"15px",color:"#95eaff",backgroundColor:"black",border:"3px solid #95eaff"}} maxLength="100" />
                                                <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                <label className='mx-2 fw-semibold' htmlFor="status" style={{color:"#95eaff"}}>Status</label>
                                                <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                <select className="form-select mb-2" id="status" name='status' aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required style={{borderRadius:"15px",color:"#95eaff",backgroundColor:"black",border:"3px solid #95eaff"}}>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan" style={{color:"#95eaff"}}>Ucapan</label>
                                                <input type="text" id='ucapan' name='ucapan' className='form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required style={{borderRadius:"15px",color:"#95eaff",backgroundColor:"black",border:"3px solid #95eaff"}} maxLength="300"/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"black",border:"3px solid #95eaff",borderRadius:"15px",color:"#95eaff"}}/>
                                                <h6 className='text-center mt-3' style={{color:"#95eaff"}}>{msgUcapan}</h6>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"#95eaff"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"#95eaff"}}>Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        {dataUcapan.map((user,index)=>(
                                                            <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"#95eaff",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"black",border:"3px solid #95eaff",color:"#95eaff",textAlign:"center",borderRadius:"15px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"black",border:"3px solid #95eaff",color:"#95eaff",textAlign:"left",borderRadius:"15px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='p-4' style={{backgroundColor:"#49dbff35",width:"100%",height:"auto"}}>
                                <div className='bi bi-image text-center' style={{fontSize:"30px",color:"#95eaff"}}></div>
                                <h4 className='text-center fw-bolder' style={{color:"#95eaff"}}>Image On Freepik</h4>
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-4">
                                            <a href="https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph" aria-label="By macrovector">
                                                <input type="button" value="By macrovector" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"black",borderRadius:"15px",color:"#95eaff",border:"3px solid #95eaff"}}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"#95eaff",marginTop:"25px"}}></div>
                                    <h4 className='text-center fw-bolder' style={{color:"#95eaff"}}>Song On Youtube</h4>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                                <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"black",borderRadius:"15px",color:"#95eaff",border:"3px solid #95eaff"}}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <h6 className='text-center fw-bolder mt-2' style={{color:"#95eaff"}}>Website By Webew.id</h6>
                            </div>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "BLW"
                ?<section id='BLW'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundImage:"linear-gradient(to bottom, #0000005a, #ffffff35)",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundColor:"black",borderLeft:"3px solid white",borderRight:"3px solid white",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"15px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"15px",borderLeft:"4px solid white",borderRight:"4px solid white"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px",color:"white"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold' style={{color:"white"}}>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{color:"white"}}>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{backgroundColor:"black",border:"3px solid white",borderRadius:"15px",color:"white",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundImage:"linear-gradient(to bottom, #0000005a, #ffffff35)",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section className='p-4' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2W.png" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" className='mx-auto d-block' style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"15px",borderLeft:"4px solid white",borderRight:"4px solid white"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>&</h1></td>
                                    <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"15px",borderLeft:"4px solid white",borderRight:"4px solid white"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>{namaLengkapPria}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"white"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>{namaLengkapWanita}</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#ffffff35",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-1">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid white",borderRight:"3px solid white"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5W.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"white"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>Akad Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>{hariAkad}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"white"}}>{tanggalAkad}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"white"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                        <a href={kalenderAkad} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"white",border:"3px solid white"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4" style={{marginLeft:"-15px"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliW.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"25px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliW.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid white",borderRight:"3px solid white"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5W.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"white"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>Resepsi Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>{hariResepsi}</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"white"}}>{tanggalResepsi}</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:"white"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                        <a href={kalenderResepsi} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"white",border:"3px solid white"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid white",borderRight:"3px solid white"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5W.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"white"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>Alamat Akad</h4>
                                                        <h6 className='text-center fw-normal pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>{alamatAkad}</h6>
                                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"white",border:"3px solid white"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliW.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliW.png"className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:"3px solid white",borderRight:"3px solid white"}}>
                                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5W.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"white"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>Alamat Resepsi</h4>
                                                        <h6 className='text-center fw-normal pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>{alamatResepsi}</h6>
                                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:"white",border:"3px solid white"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-6 my-4">
                                        <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3W.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                                            <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>{kataKata}</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>({namaSurah})</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b1W.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginTop:"25px"}} alt="bingkai" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#ffffff35",width:"100%",height:"auto"}}>
                                <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"white"}}></div>
                                <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>Gallery Foto</h4>
                                <div className="container-fluid">
                                    <div className='rowes justify-content-center'>
                                        <div className="column">
                                            <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                        <div className="column">
                                            <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-10 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"white"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div style={{backgroundColor:"transparant",borderRadius:"15px",width:"100%"}}>
                                                        <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>{namaBank1}</h5>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>{atasNama1}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form-control mb-3' ref={inputNoRek1} value={noRek1} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"15px",color:"white",backgroundColor:"black",border:"3px solid white"}}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="mx-auto d-block fw-bold py-2" onClick={salinRek1} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"80%",backgroundColor:"black",borderRadius:"15px",color:"white",border:"3px solid white"}}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div style={{backgroundColor:"transparant",borderRadius:"15px",width:"100%"}}>
                                                        <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>{namaBank2}</h5>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>{atasNama2}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form-control mb-3' ref={inputNoRek2} value={noRek2} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"15px",color:"white",backgroundColor:"black",border:"3px solid white"}}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="mx-auto d-block fw-bold py-2" onClick={salinRek2} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"80%",backgroundColor:"black",borderRadius:"15px",color:"white",border:"3px solid white"}}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                        
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"white"}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>Beri Ucapan</h4>
                                            <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama" style={{color:"white"}}>Nama</label>
                                                <input type="text" id='nama' name='nama' className='form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required style={{borderRadius:"15px",color:"white",backgroundColor:"black",border:"3px solid white"}} maxLength="100" />
                                                <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                <label className='mx-2 fw-semibold' htmlFor="status" style={{color:"white"}}>Status</label>
                                                <input type='text' id='panggilan' className='form-disable' value={panggilan} required />
                                                <select className="form-select mb-2" id="status" name='status' aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required style={{borderRadius:"15px",color:"white",backgroundColor:"black",border:"3px solid white"}}>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan" style={{color:"white"}}>Ucapan</label>
                                                <input type="text" id='ucapan' name='ucapan' className='form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required style={{borderRadius:"15px",color:"white",backgroundColor:"black",border:"3px solid white"}} maxLength="300"/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"black",border:"3px solid white",borderRadius:"15px",color:"white"}}/>
                                                <h6 className='text-center mt-3' style={{color:"white"}}>{msgUcapan}</h6>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"white"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:"white"}}>Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        {dataUcapan.map((user,index)=>(
                                                            <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"white",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"black",border:"3px solid white",color:"white",textAlign:"center",borderRadius:"15px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"black",border:"3px solid white",color:"white",textAlign:"left",borderRadius:"15px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='p-4' style={{backgroundColor:"#ffffff35",width:"100%",height:"auto"}}>
                                <div className='bi bi-image text-center' style={{fontSize:"30px",color:"white"}}></div>
                                <h4 className='text-center fw-bolder' style={{color:"white"}}>Image On Freepik</h4>
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-4">
                                            <a href="https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph" aria-label="By macrovector">
                                                <input type="button" value="By macrovector" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"black",borderRadius:"15px",color:"white",border:"3px solid white"}}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"white",marginTop:"25px"}}></div>
                                    <h4 className='text-center fw-bolder' style={{color:"white"}}>Song On Youtube</h4>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                                <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"black",borderRadius:"15px",color:"white",border:"3px solid white"}}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <h6 className='text-center fw-bolder mt-2' style={{color:"white"}}>Website By Webew.id</h6>
                            </div>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE PERNIKAHAN COLOR */}
            {jenisUndanganDigitalPernikahan === "CUT"
                ?<section id='CUT'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#f1c0ff70",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"linear-gradient(to bottom, #f1c9fe, #ffffff)",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"0 5px 25px -10px rgb(108, 108, 108)"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none",borderRadius:"20px",color:"rgb(108, 108, 108)",backgroundColor:"#f1c9fe",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#f1c0ff70",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"linear-gradient(180deg, #f1c9fe, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed",borderTopLeftRadius:"50px"}}>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <h1 className='text-center fw-bold mt-5' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                                        <h6 className='text-center fw-semibold my-4'  data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                                        <table>
                                            <tr>
                                                <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"20px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}} alt="calon pengantin pria" /></td>
                                                <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >&</h1></td>
                                                <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"20px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}} alt="calon pengantin Wanita" /></td>
                                            </tr>
                                        </table>
                                        <div className="container-fluid">
                                            <div className="row justify-content-center mt-4">
                                                <div className="col-12 col-md-5">
                                                    <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{namaLengkapPria}</h6>
                                                    <h6 className='text-center fw-semibold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                                </div>
                                                <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                                <div className="col-12 col-md-5">
                                                    <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{namaLengkapWanita}</h6>
                                                    <h6 className='text-center fw-semibold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"linear-gradient(-180deg, #f1c9fe, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{hariAkad}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{tanggalAkad}</h5>
                                                <h5 className='text-center pb-2 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#f1c9fe",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{hariResepsi}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{tanggalResepsi}</h5>
                                                <h5 className='text-center pb-2 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                <a href={kalenderResepsi} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#f1c9fe",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"linear-gradient(180deg, #f1c9fe, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Alamat Akad</h4>
                                                <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{alamatAkad}</h6>
                                                <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                                    <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#f1c9fe",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Alamat Resepsi</h4>
                                                <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{alamatResepsi}</h6>
                                                <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                                    <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#f1c9fe",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"linear-gradient(-180deg, #f1c9fe, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-lg-9 mb-5">
                                        <div className='p-4'>
                                            <h6 className='text-center fw-semibold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{kataKata}</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >({namaSurah})</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"linear-gradient(180deg, #f1c9fe, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                        <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Gallery Foto</h4>
                                        <div className='rowes justify-content-center'>
                                            <div className="column">
                                                <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            </div>
                                            <div className="column">
                                                <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"linear-gradient(-180deg, #f1c9fe, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-10 my-4">
                                        <div className='p-4'>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek1} value={noRek1}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"#f1c9fe",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)"}} onClick={salinRek1}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek2} value={noRek2}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"#f1c9fe",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)"}} onClick={salinRek2}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-4'>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Beri Ucapan</h4>
                                            <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama" >Nama</label>
                                                <input type="text" id='nama' className='form form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required/>
                                                <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                <label className='mx-2 fw-semibold' htmlFor="status" >Status</label>
                                                <select className="form-select form mb-2" id="status" aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan" >Ucapan</label>
                                                <input type="text" id='ucapan' className='form form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"#f1c9fe",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-4'>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        {dataUcapan.map((user,index)=>(
                                                            <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:"#f1c9fe",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:"#f1c9fe",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section className='p-4' style={{backgroundImage:"linear-gradient(-180deg, #f1c9fe, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder' >Song On Youtube</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"#f1c9fe",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2 mb-3' >Website By Webew.id</h6>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "ADO"
                ?<section id='ADO'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#c0ffd870",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"linear-gradient(to bottom, #c9fee2, #ffffff)",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"0 5px 25px -10px rgb(108, 108, 108)"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none",borderRadius:"20px",color:"rgb(108, 108, 108)",backgroundColor:"#c9fee2",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#c0ffd870",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"linear-gradient(180deg, #c9fee2, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed",borderTopLeftRadius:"50px"}}>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <h1 className='text-center fw-bold mt-5' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                                        <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                                        <table>
                                            <tr>
                                                <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid'  data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine"style={{width:"250px",borderRadius:"20px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}} alt="calon pengantin pria" /></td>
                                                <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >&</h1></td>
                                                <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"20px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}} alt="calon pengantin Wanita" /></td>
                                            </tr>
                                        </table>
                                        <div className="container-fluid">
                                            <div className="row justify-content-center mt-4">
                                                <div className="col-12 col-md-5">
                                                    <h6 className='text-center fw-bold'  data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapPria}</h6>
                                                    <h6 className='text-center fw-semibold'  data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                                </div>
                                                <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                                <div className="col-12 col-md-5">
                                                    <h6 className='text-center fw-bold'  data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">{namaLengkapWanita}</h6>
                                                    <h6 className='text-center fw-semibold'  data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"linear-gradient(-180deg, #c9fee2, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{hariAkad}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{tanggalAkad}</h5>
                                                <h5 className='text-center pb-2 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#c9fee2",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{hariResepsi}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{tanggalResepsi}</h5>
                                                <h5 className='text-center pb-2 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                <a href={kalenderResepsi} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#c9fee2",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"linear-gradient(180deg, #c9fee2, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Alamat Akad</h4>
                                                <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{alamatAkad}</h6>
                                                <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                                    <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#c9fee2",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Alamat Resepsi</h4>
                                                <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{alamatResepsi}</h6>
                                                <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                                    <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#c9fee2",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"linear-gradient(-180deg, #c9fee2, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-lg-9 mb-5">
                                        <div className='p-4'>
                                            <h6 className='text-center fw-semibold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{kataKata}</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >({namaSurah})</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"linear-gradient(180deg, #c9fee2, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                        <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Gallery Foto</h4>
                                        <div className='rowes justify-content-center'>
                                            <div className="column">
                                                <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            </div>
                                            <div className="column">
                                                <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"linear-gradient(-180deg, #c9fee2, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-10 my-4">
                                        <div className='p-4'>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek1} value={noRek1}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"#c9fee2",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)"}} onClick={salinRek1}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek2} value={noRek2}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"#c9fee2",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)"}} onClick={salinRek2}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-4'>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Beri Ucapan</h4>
                                            <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama" >Nama</label>
                                                <input type="text" id='nama' className='form form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required/>
                                                <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                <label className='mx-2 fw-semibold' htmlFor="status" >Status</label>
                                                <select className="form-select form mb-2" id="status" aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan" >Ucapan</label>
                                                <input type="text" id='ucapan' className='form form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"#c9fee2",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-4'>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        {dataUcapan.map((user,index)=>(
                                                            <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:"#c9fee2",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:"#c9fee2",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section className='p-4' style={{backgroundImage:"linear-gradient(-180deg, #c9fee2, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder' >Song On Youtube</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"#c9fee2",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2 mb-3' >Website By Webew.id</h6>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "BEA"
                ?<section id='BEA'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#ffd3c070",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"linear-gradient(to bottom, #fed5c9, #ffffff)",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"0 5px 25px -10px rgb(108, 108, 108)"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none",borderRadius:"20px",color:"rgb(108, 108, 108)",backgroundColor:"#fed5c9",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#ffd3c070",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"linear-gradient(180deg, #fed5c9, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed",borderTopLeftRadius:"50px"}}>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <h1 className='text-center fw-bold mt-5' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                                        <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                                        <table>
                                            <tr>
                                                <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"20px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}} alt="calon pengantin pria" /></td>
                                                <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >&</h1></td>
                                                <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"20px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}} alt="calon pengantin Wanita" /></td>
                                            </tr>
                                        </table>
                                        <div className="container-fluid">
                                            <div className="row justify-content-center mt-4">
                                                <div className="col-12 col-md-5">
                                                    <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{namaLengkapPria}</h6>
                                                    <h6 className='text-center fw-semibold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                                </div>
                                                <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                                <div className="col-12 col-md-5">
                                                    <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{namaLengkapWanita}</h6>
                                                    <h6 className='text-center fw-semibold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"linear-gradient(-180deg, #fed5c9, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{hariAkad}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{tanggalAkad}</h5>
                                                <h5 className='text-center pb-2 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold"  data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine"style={{backgroundColor:"#fed5c9",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{hariResepsi}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{tanggalResepsi}</h5>
                                                <h5 className='text-center pb-2 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                <a href={kalenderResepsi} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#fed5c9",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"linear-gradient(180deg, #fed5c9, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Alamat Akad</h4>
                                                <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{alamatAkad}</h6>
                                                <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                                    <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold"  data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine"style={{backgroundColor:"#fed5c9",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Alamat Resepsi</h4>
                                                <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{alamatResepsi}</h6>
                                                <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                                    <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#fed5c9",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"linear-gradient(-180deg, #fed5c9, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-lg-9 mb-5">
                                        <div className='p-4'>
                                            <h6 className='text-center fw-semibold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{kataKata}</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >({namaSurah})</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"linear-gradient(180deg, #fed5c9, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                        <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Gallery Foto</h4>
                                        <div className='rowes justify-content-center'>
                                            <div className="column">
                                                <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            </div>
                                            <div className="column">
                                                <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"linear-gradient(-180deg, #fed5c9, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                <div className="col-12 col-md-10 my-4">
                                        <div className='p-4'>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek1} value={noRek1}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"#fed5c9",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)"}} onClick={salinRek1}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek2} value={noRek2}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"#fed5c9",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)"}} onClick={salinRek2}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-4'>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Beri Ucapan</h4>
                                            <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama" >Nama</label>
                                                <input type="text" id='nama' className='form form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required/>
                                                <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                <label className='mx-2 fw-semibold' htmlFor="status" >Status</label>
                                                <select className="form-select form mb-2" id="status" aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan" >Ucapan</label>
                                                <input type="text" id='ucapan' className='form form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"#fed5c9",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-4'>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        {dataUcapan.map((user,index)=>(
                                                            <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:"#fed5c9",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:"#fed5c9",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section className='p-4' style={{backgroundImage:"linear-gradient(-180deg, #fed5c9, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder' >Song On Youtube</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"#fed5c9",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2 mb-3' >Website By Webew.id</h6>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {jenisUndanganDigitalPernikahan === "COO"
                ?<section id='COO'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#c0e9ff70",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"linear-gradient(to bottom, #c0e9ff, #ffffff)",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFotoLatar} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"0 5px 25px -10px rgb(108, 108, 108)"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none",borderRadius:"20px",color:"rgb(108, 108, 108)",backgroundColor:"#c0e9ff",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('"+urlFotoLatar+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#c0e9ff70",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilPria}</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>{namaPanggilWanita}</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"linear-gradient(180deg, #c0e9ff, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed",borderTopLeftRadius:"50px"}}>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <h1 className='text-center fw-bold mt-5' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                                        <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                                        <table>
                                            <tr>
                                                <td style={{width:"45%"}}><img src={urlFotoPria} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"20px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}} alt="calon pengantin pria" /></td>
                                                <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >&</h1></td>
                                                <td style={{width:"45%"}}><img src={urlFotoWanita} className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"20px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}} alt="calon pengantin Wanita" /></td>
                                            </tr>
                                        </table>
                                        <div className="container-fluid">
                                            <div className="row justify-content-center mt-4">
                                                <div className="col-12 col-md-5">
                                                    <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{namaLengkapPria}</h6>
                                                    <h6 className='text-center fw-semibold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Putra dari Bapak {namaBapakPria} & Ibu {namaIbuPria}</h6>
                                                </div>
                                                <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                                <div className="col-12 col-md-5">
                                                    <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{namaLengkapWanita}</h6>
                                                    <h6 className='text-center fw-semibold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Putri dari Bapak {namaBapakWanita} & Ibu {namaIbuWanita}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"linear-gradient(-180deg, #c0e9ff, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{hariAkad}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{tanggalAkad}</h5>
                                                <h5 className='text-center pb-2 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{jamAkadMulai} sd {jamAkadAkhir}</h5>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#c0e9ff",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{hariResepsi}</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{tanggalResepsi}</h5>
                                                <h5 className='text-center pb-2 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>{jamResepsiMulai} sd {jamResepsiAkhir}</h5>
                                                <a href={kalenderResepsi} aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#c0e9ff",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"linear-gradient(180deg, #c0e9ff, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Alamat Akad</h4>
                                                <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{alamatAkad}</h6>
                                                <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                                    <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#c0e9ff",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Alamat Resepsi</h4>
                                                <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{alamatResepsi}</h6>
                                                <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                                    <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"#c0e9ff",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"linear-gradient(-180deg, #c0e9ff, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-lg-9 mb-5">
                                        <div className='p-4'>
                                            <h6 className='text-center fw-semibold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >{kataKata}</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >({namaSurah})</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"linear-gradient(180deg, #c0e9ff, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                        <h4 className='text-center fw-bolder mb-4'  data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                                        <div className='rowes justify-content-center'>
                                            <div className="column">
                                                <a href={urlFotoGaleri1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri1} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri2} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri3} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            </div>
                                            <div className="column">
                                                <a href={urlFotoGaleri4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri4} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri5} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href={urlFotoGaleri6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={urlFotoGaleri6} className="img-fluid" width="280px" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"linear-gradient(-180deg, #c0e9ff, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                <div className="col-12 col-md-10 my-4">
                                        <div className='p-4'>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank1}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama1}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek1} value={noRek1}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"#c0e9ff",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)"}} onClick={salinRek1}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank {namaBank2}</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. {atasNama2}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" ref={inputNoRek2} value={noRek2}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"#c0e9ff",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)"}} onClick={salinRek2}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-4'>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" >Beri Ucapan</h4>
                                            <form onSubmit={createUcapanWePernikahan} data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama" >Nama</label>
                                                <input type="text" id='nama' className='form form-control mb-2' value={nama} onChange={(e)=>setNama(e.target.value)} required/>
                                                <input type="text" id='link' className='form-disable' value={salinLinkk} readOnly required/>
                                                <label className='mx-2 fw-semibold' htmlFor="status" >Status</label>
                                                <select className="form-select form mb-2" id="status" aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} required>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan" >Ucapan</label>
                                                <input type="text" id='ucapan' className='form form-control mb-4' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"#c0e9ff",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                <h6 className='text-center mt-3' style={{color:"rgb(108, 108, 108)"}}>{msgUcapan}</h6>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-4'>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        {dataUcapan.map((user,index)=>(
                                                            <tr key={dataUcapan.id} className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:"#c0e9ff",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.nama}</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:"#c0e9ff",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>{user.ucapan}</p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section className='p-4' style={{backgroundImage:"linear-gradient(-180deg, #c0e9ff, #ffffff)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder' >Song On Youtube</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"#c0e9ff",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2 mb-3' >Website By Webew.id</h6>
                        </section>
                    </div>
                </section>
                :<section style={{display:"none"}}></section>
            }
            <h1 className='text-center'>{msg}</h1>
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE PERNIKAHAN HASIL
// EKSPOR
export default WePernikahanHasil