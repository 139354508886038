// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE ULTAH
const MenuWeUltah = () => { // KURUNG BUKA WE ULTAH
    // MEMBUAT VARIABEL WE ULTAH
    const [nama, setNama] = useState('')
    const [foto, setFoto] = useState('')
    const [tempatLahir, setTempatLahir] = useState('')
    const [tanggalLahir, setTanggalLahir] = useState('')
    const [ucapan, setUcapan] = useState('')
    const [warna, setWarna] = useState('')
    const [displayLoader, setDisplayLoader] = useState('')
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-ultah", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES MENGAMBIL FILE FOTO DARI FORM
    function handleUploadChange(e){
        let uploaded = e.target.files[0]
        setFoto(uploaded)
    }
    // PROSES MENYIMPAN WE ULTAH KE DB
    const createWeUltah = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("nama", nama)
        formData.append("foto", foto)
        formData.append("tempatLahir", tempatLahir)
        formData.append("tanggalLahir", tanggalLahir)
        formData.append("ucapan", ucapan)
        formData.append("warna", warna)
        formData.append("panggilan", panggilan)
        try{
            setDisplayLoader("")
            await axios.post("https://server13.webew.id/createWeUltahBE", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            navigate(`/menu-we-ultah-salin-kode/${panggilan}`, { replace: true })
        } catch(error){
            if(error.response){
                setDisplayLoader("none")
                console.log(error.response.data.msg)
            }
        }
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div  className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row my-3">
                                        <div className="col-12 p-2">
                                            <h4 className='text-center fw-bold'>We Ultah</h4>
                                            <p className='text-center fw-semibold'>Buat Ucapan Ulang Tahun Secara Online Dengan We Ultah</p>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mb-5 pb-1">
                                        <div className="col-12 col-lg-6 mb-4 order-1 order-md-0">
                                            <div className="box p-4" style={{width: "100%",minHeight:"370px"}}>
                                                <form onSubmit={createWeUltah}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <label style={{fontSize:"10pt"}}  htmlFor="nama">Nama Lengkap</label>
                                                                    <input type="text" id="nama" className="form-control form" title='Masukan nama yang ultah' value={nama} onChange={(e)=>setNama(e.target.value)} required/>
                                                                </div>
                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <label style={{fontSize:"10pt"}}  htmlFor="foto">Foto (JPEG/PNG)</label>
                                                                    <input type="file" id="foto" className="form-control form" accept="image/*" title='Masukan foto max 2MB' onChange={handleUploadChange} required/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <label style={{fontSize:"10pt"}}  htmlFor="tempatLahir">Tempat Lahir</label>
                                                                    <input type="text" id="tempatLahir" className="form-control form" title='Masukan tempat lahir' value={tempatLahir} onChange={(e)=>setTempatLahir(e.target.value)} required/>
                                                                </div>
                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <label style={{fontSize:"10pt"}}  htmlFor="tanggalLahir">Tanggal Lahir</label>
                                                                    <input type="date" id="tanggalLahir" className="form-control form" title='Masukan tanggal lahir' value={tanggalLahir} onChange={(e)=>setTanggalLahir(e.target.value)} required/>
                                                                    <input type="text" id="panggilan" className="form-disable" value={panggilan} readOnly/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <label style={{fontSize:"10pt"}}  htmlFor="ucapan">Ucapan Kata</label>
                                                                    <input type="text" id="ucapan" className="form-control form" title='Berikan ucapan kata untuk dia' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required/>
                                                                </div>
                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <label style={{fontSize:"10pt"}}  htmlFor="warna">Warna Latar</label>
                                                                    <input type="color" id="warna" className="form-control-color form-control form" title="Pilih warna latar" style={{width:"100%"}} value={warna} onChange={(e)=>setWarna(e.target.value)} required/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <input type="submit" className="btn btn-webew-login mt-3" value="Proses"/>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-4 order-0 order-md-1">
                                            <div className="col-12">
                                                <div className="box p-4" style={{width: "100%",minHeight:"auto"}}>
                                                    <h6 className='text-center fw-normal'>Sudah pernah buat sebelumnya ? Jika sudah maka tekan tombol dibawah ini, jika belum silahkan isi form dulu yaa</h6>
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-sm-7">
                                                            <a href={`/menu-we-ultah-salin-kode/${panggilan}`} className="btn btn-webew-product mt-3 mb-2">Lanjut</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="box mt-4">
                                                    <img src="https://webew.id/img/banner.png" alt="banner webew.id" className='mx-auto d-block' style={{width:"100%",maxHeight:"35.4vh",borderRadius:"20px"}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU WE ULTAH
// EKSPOR
export default MenuWeUltah