// IMPORT REACT
import React,{ useRef, useState, useEffect } from "react"
// IMPORT USE PARAMS
import { useParams } from "react-router-dom"
// IMPORT AOS
import AOS from "aos";
// WE PERNIKAHAN CONTOH
const WePernikahanContoh = () => { // KURUNG BUKA WE PERNIKAHAN CONTOH
    // MEMBUAT VARIABEL WE PERNIKAHAN CONTOH
    const [backgroundColor, setBackgroundColor] = useState('')
    const [backgroundImage, setBackgroundImage] = useState('')
    const [button, setButton] = useState('')
    const [color, setColor] = useState('')
    const [bingkai1, setBingkai1] = useState('')
    const [bingkai2, setBingkai2] = useState('')
    const [bingkai3, setBingkai3] = useState('')
    const [bingkai4, setBingkai4] = useState('')
    const [bingkaiTali, setBingkaiTali] = useState('')
    const [image1, setImage1] = useState('')
    const [image2, setImage2] = useState('')
    const [image3, setImage3] = useState('')
    const [image4, setImage4] = useState('')
    const [image5, setImage5] = useState('')
    const [rotate, setRotate] = useState('')
    const [pemilik1, setPemilik1] = useState('')
    const [link1, setLink1] = useState('')
    const [pemilik2, setPemilik2] = useState('')
    const [link2, setLink2] = useState('')
    const [bukaUndangan, setBukaUndangan] = useState('')
    const [tutupButton, setTutupButton] = useState('')
    const [isPlaying, setIsPlaying] = useState(false);
    const { jenisWePernikahanParams } = useParams();
    const audioRef = useRef(null);
    // USEEFFECT
    useEffect(() => {
        AOS.init()
        AOS.refresh()
        setBukaUndangan("none")
        if(jenisWePernikahanParams === "adorable"){
            setBackgroundColor("#c0ffd870")
            setBackgroundImage("linear-gradient(to bottom, #c9fee2, #ffffff)")
            setButton("#c9fee2")
        }else if(jenisWePernikahanParams === "cute"){
            setBackgroundColor("#f1c0ff70")
            setBackgroundImage("linear-gradient(to bottom, #f1c9fe, #ffffff)")
            setButton("#f1c9fe")
        }else if(jenisWePernikahanParams === "beauty"){
            setBackgroundColor("#ffd3c070")
            setBackgroundImage("linear-gradient(to bottom, #fed5c9, #ffffff)")
            setButton("#fed5c9")
        }else if(jenisWePernikahanParams === "cool"){
            setBackgroundColor("#c0e9ff70")
            setBackgroundImage("linear-gradient(to bottom, #c0e9ff, #ffffff)")
            setButton("#c0e9ff")
        }else if(jenisWePernikahanParams === "blegold"){
            setColor("#e6c300")
            setBingkai1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2G.png")
            setBingkai2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5G.png")
            setBingkai3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3G.png")
            setBingkai4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b1G.png")
            setBingkaiTali("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliG.png")
            setBackgroundColor("#e6c40035")
            setBackgroundImage("linear-gradient(to bottom, #0000005a, #e6c40035)")
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("By macrovector")
        }else if(jenisWePernikahanParams === "blepink"){
            setColor("#ffabde")
            setBingkai1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2P.png")
            setBingkai2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5P.png")
            setBingkai3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3P.png")
            setBingkai4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b1P.png")
            setBingkaiTali("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliP.png")
            setBackgroundColor("#ff009935")
            setBackgroundImage("linear-gradient(to bottom,#0000005a, #ff009935)")
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("By macrovector")
        }else if(jenisWePernikahanParams === "bletosca"){
            setColor("#95eaff")
            setBingkai1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2T.png")
            setBingkai2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5T.png")
            setBingkai3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3T.png")
            setBingkai4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b1T.png")
            setBingkaiTali("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliT.png")
            setBackgroundColor("#49dbff35")
            setBackgroundImage("linear-gradient(to bottom, #0000005a, #49dbff35)")
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("By macrovector")
        }else if(jenisWePernikahanParams === "blewhite"){
            setColor("#ffffff")
            setBingkai1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2W.png")
            setBingkai2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5W.png")
            setBingkai3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3W.png")
            setBingkai4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b1W.png")
            setBingkaiTali("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/taliW.png")
            setBackgroundColor("#ffffff35")
            setBackgroundImage("linear-gradient(to bottom, #0000005a, #ffffff35)")
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("By macrovector")
        }else if(jenisWePernikahanParams === "fotografi"){
            setImage1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/fotografi/f2.png")
            setImage2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/fotografi/f4.png")
            setImage3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/fotografi/f3.png")
            setImage4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/fotografi/f5.png")
            setImage5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/fotografi/f5.png")
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("By macrovector")
            setLink2("https://www.freepik.com/free-vector/professional-photographer-equipment-isometric-icons-collection-with-studio-portrait-photo-shoots-camera-light-drone-isolated-vector-illustration_6932664.htm#query=kamera&position=11&from_view=search&track=sph")
            setPemilik2("By macrovector")
        }else if(jenisWePernikahanParams === "bike"){
            setImage1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bike/b5.png")
            setImage2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bike/b1.png")
            setImage3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bike/b2.png")
            setImage4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bike/b3.png")
            setImage5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bike/b4.png")
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("By macrovector")
            setLink2("https://www.freepik.com/free-vector/bicycle-vintage-elements-set_5971242.htm#query=bike&position=23&from_view=search&track=sph")
            setPemilik2("By macrovector")
        }else if(jenisWePernikahanParams === "football"){
            setImage1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/football/f5.png")
            setImage2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/football/f1.png")
            setImage3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/football/f2.png")
            setImage4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/football/f3.png")
            setImage5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/football/f4.png")
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("By macrovector")
            setLink2("https://www.freepik.com/free-vector/flat-football-players-collection_15275628.htm#query=football&position=7&from_view=search&track=sph")
            setPemilik2("By pikisuperstar")
        }else if(jenisWePernikahanParams === "badminton"){
            setImage1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/badminton/b5.png")
            setImage2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/badminton/b1.png")
            setImage3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/badminton/b2.png")
            setImage4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/badminton/b3.png")
            setImage5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/badminton/b4.png")
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("By macrovector")
            setLink2("https://www.freepik.com/free-vector/flat-badminton-player-silhouette-collection_4787467.htm#query=badminton%20vector&position=30&from_view=search&track=ais")
            setPemilik2("By pikisuperstar")
        }else if(jenisWePernikahanParams === "bnw1"){
            setImage1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb1.png")
            setImage2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb2.png")
            setImage3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png")
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("By macrovector")
            setLink2("https://www.freepik.com/free-vector/elegant-wedding-decorative-floral-background_9853423.htm#query=black%20and%20white%20flower%20background&position=10&from_view=search&track=ais")
            setPemilik2("By Harryarts")
        }else if(jenisWePernikahanParams === "bnw2"){
            setImage1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb4.png")
            setImage2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb5.png")
            setImage3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png")
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("By macrovector")
            setLink2("https://www.freepik.com/free-vector/beautiful-hand-drawn-wedding-ornament-floral-background_9853397.htm#page=3&query=black%20and%20white%20flower%20background&position=3&from_view=search&track=ais")
            setPemilik2("By Harryarts")
        }else if(jenisWePernikahanParams === "bnw3"){
            setImage1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb7.png")
            setImage2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb8.png")
            setImage3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png")
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("By macrovector")
            setLink2("https://www.freepik.com/free-vector/hand-drawn-spring-background_12806202.htm#query=black%20and%20white%20flower%20background&position=38&from_view=search&track=ais")
            setPemilik2("By Freepik")
        }else if(jenisWePernikahanParams === "bnw4"){
            setImage1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb10.png")
            setImage2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb11.png")
            setImage3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png")
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("By macrovector")
            setLink2("https://www.freepik.com/free-vector/floral-background-with-sketchy-style_2416402.htm#page=5&query=black%20and%20white%20flower%20background&position=13&from_view=search&track=ais")
            setPemilik2("By Freepik")
        }else if(jenisWePernikahanParams === "agapanthus"){
            setImage1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png")
            setImage2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/14309031_5470466.png")
            setRotate("rotate(-180deg)")
            setBackgroundColor("#4f8dff15")
            setLink1("https://www.freepik.com/free-vector/summer-pattern-background-zoom_8845952.htm#page=5&query=purple%20leaf%20background&position=34&from_view=search&track=ais")
            setPemilik1("By pikisuperstar")
            setLink2("https://www.freepik.com/free-vector/gradient-tropical-leaves-background_14309031.htm#page=5&query=purple%20leaf%20background&position=46&from_view=search&track=ais")
            setPemilik2("By Freepik")
        }else if(jenisWePernikahanParams === "aglaonema"){
            setImage1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png")
            setImage2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13991173_5386317.png")
            setRotate("rotate(-180deg)")
            setBackgroundColor("#5eff4f15")
            setLink1("https://www.freepik.com/free-vector/hand-painted-watercolor-tropical-leaves-background_13900114.htm#query=tropical%20background&position=3&from_view=search&track=ais")
            setPemilik1("By pikisuperstar")
            setLink2("https://www.freepik.com/free-vector/realistic-tropical-leaves-background_13991173.htm#page=2&query=tropical&position=3&from_view=search&track=sph")
            setPemilik2("By pikisuperstar")
        }else if(jenisWePernikahanParams === "alamanda"){
            setImage1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png")
            setImage2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5113181_2638116.png")
            setRotate("rotate(-180deg)")
            setBackgroundColor("#ffdc4f15")
            setLink1("https://www.freepik.com/free-vector/realistic-autumn-background_9260611.htm#page=6&query=yellow%20leaf&position=16&from_view=search&track=ais")
            setPemilik1("By pikisuperstar")
            setLink2("https://www.freepik.com/free-vector/flat-autumn-forest-leaves-background_5113181.htm#page=3&query=yellow%20leaf%20background&position=20&from_view=search&track=ais")
            setPemilik2("By freepik")
        }else if(jenisWePernikahanParams === "amaryllis"){
            setImage1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png")
            setImage2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5020958_2649322.png")
            setRotate("rotate(-180deg)")
            setBackgroundColor("#ff4f4f15")
            setLink1("https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5045257.htm#page=3&query=read%20leaf%20background&position=14&from_view=search&track=ais")
            setPemilik1("By freepik")
            setLink2("https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5020958.htm#page=7&query=red%20leaf%20background&position=34&from_view=search&track=ais")
            setPemilik2("By freepik")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // BUTTON BUKA UNDANGAN
    const btnBukaUndangan = () => {
        setBukaUndangan("")
        setTutupButton("none")
        audioRef.current.play()
        setIsPlaying(true)
    }
    // BUTTON PLAY MUSIC
    const handlePlay = () => {
        audioRef.current.play()
        setIsPlaying(true)
    }
    // BUTTON PAUSE MUSIC
    const handlePause = () => {
        audioRef.current.pause()
        setIsPlaying(false)
    }
    // BUTTON PLAY PAUSE MUSIC
    const handlePlayPause = () => {
        if (isPlaying) {
            handlePause()
        } else {
            handlePlay()
        }
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className="background-home">
            {/* JENIS WE PERNIKAHAN ADORABLE, CUTE, BEAUTY, COOL */}
            {jenisWePernikahanParams === "adorable" || jenisWePernikahanParams === "cute" || jenisWePernikahanParams === "beauty" || jenisWePernikahanParams === "cool"
                ?<section>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:`${backgroundColor}`,zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:`${backgroundImage}`,height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"0 5px 25px -10px rgb(108, 108, 108)"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>Yusuf</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none",borderRadius:"20px",color:"rgb(108, 108, 108)",backgroundColor:`${button}`,display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="https://webew.id/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:`${backgroundColor}`,width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>Romeo</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>Juliet</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:`${backgroundImage}`,width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed",borderTopLeftRadius:"50px"}}>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <h1 className='text-center fw-bold mt-5' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                                        <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                                        <table>
                                            <tr>
                                                <td style={{width:"45%"}}><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" className='img-fluid' style={{width:"250px",borderRadius:"20px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}} alt="calon pengantin pria" /></td>
                                                <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                                <td style={{width:"45%"}}><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" className='img-fluid' style={{width:"250px",borderRadius:"20px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}} alt="calon pengantin Wanita" /></td>
                                            </tr>
                                        </table>
                                        <div className="container-fluid">
                                            <div className="row justify-content-center mt-4">
                                                <div className="col-12 col-md-5">
                                                    <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Romeo Romeano</h6>
                                                    <h6 className='text-center fw-semibold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak Abdul Aziz & Ibu Nuraini</h6>
                                                </div>
                                                <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                                <div className="col-12 col-md-5">
                                                    <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Juliet Julieto</h6>
                                                    <h6 className='text-center fw-semibold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak Aziz Abdul & Ibu Nuraina</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:`${backgroundImage}`,width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Sabtu</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>13 Mei 2023</h5>
                                                <h5 className='text-center pb-2 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>08.00 sd 10.00 WIB</h5>
                                                <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:`${button}`,borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Sabtu</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>13 Mei 2023</h5>
                                                <h5 className='text-center pb-2 mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-5px"}}>11.00 sd 15.00 WIB</h5>
                                                <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:`${button}`,borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:`${backgroundImage}`,width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Akad</h4>
                                                <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia</h6>
                                                <a href="https://www.google.com" aria-label="Google Maps">
                                                    <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:`${button}`,borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 mb-5">
                                            <div className='p-4'>
                                                <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Resepsi</h4>
                                                <h6 className='text-center fw-semibold my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.110 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia</h6>
                                                <a href="https://www.google.com" aria-label="Google Maps">
                                                    <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:`${button}`,borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:`${backgroundImage}`,width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-lg-9 mb-5">
                                        <div className='p-4'>
                                            <h6 className='text-center fw-semibold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">(QS. Ar-Rum ayat 21)</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:`${backgroundImage}`,width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                        <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                                        <div className='rowes justify-content-center'>
                                            <div className="column">
                                                <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            </div>
                                            <div className="column">
                                                <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:`${backgroundImage}`,width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                <div className="col-12 col-md-10 my-4">
                                    <div className='p-4'>
                                        <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                        <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-5 mb-4">
                                                <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%"}}>
                                                    <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank Mandiri</p>
                                                    <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. Romeo</p>
                                                    <div className="row">
                                                        <div className="col-12 col-md-7">
                                                            <input type="text" id='noRek1' className='form form-control mb-2' value="1234567890" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine"/>
                                                        </div>
                                                        <div className="col-12 col-md-5">
                                                            <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:`${button}`,borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)"}}>Salin</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-5 mb-4">
                                                <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%"}}>
                                                    <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank BCA</p>
                                                    <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. Juliet</p>
                                                    <div className="row">
                                                        <div className="col-12 col-md-7">
                                                            <input type="text" id='noRek2' className='form form-control mb-2' value="1234567890" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine"/>
                                                        </div>
                                                        <div className="col-12 col-md-5">
                                                            <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:`${button}`,borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)"}}>Salin</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <div className="col-12 col-md-5 my-4">
                                    <div className='p-4'>
                                        <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                        <form data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                            <label className='mx-2 fw-semibold' htmlFor="nama" >Nama</label>
                                            <input type="text" id='nama' className='form form-control mb-2' required/>
                                            <label className='mx-2 fw-semibold' htmlFor="status" >Status</label>
                                            <select className="form-select form mb-2" id="status" aria-label="status" required>
                                                <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                <option value="Saudara">Saudara</option>
                                                <option value="Atasan Kerja">Atasan Kerja</option>
                                                <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                <option value="Dosen/Guru">Dosen/Guru</option>
                                                <option value="Teman Kerja">Teman Kerja</option>
                                                <option value="Teman Sekolah">Teman Sekolah</option>
                                                <option value="Teman">Teman</option>
                                            </select>
                                            <label className='mx-2 fw-semibold' htmlFor="ucapan" >Ucapan</label>
                                            <input type="text" id='ucapan' className='form form-control mb-4' required/>
                                            <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:`${button}`,borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5 my-4">
                                    <div className='p-4'>
                                        <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                        <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                        <div style={{maxHeight:"200px",overflow:"auto"}}>
                                            <table>
                                                <tbody>
                                                    <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                        <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                        <td style={{width:"1%"}}><p> </p></td>
                                                        <td style={{width:"32%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:`${button}`,textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Heru</p></td>
                                                        <td style={{width:"1%"}}><p> </p></td>
                                                        <td style={{width:"63%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:`${button}`,textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Selamat ya bang dan neng</p></td>
                                                    </tr>
                                                    <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                        <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                        <td style={{width:"1%"}}><p> </p></td>
                                                        <td style={{width:"32%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:`${button}`,textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Safi</p></td>
                                                        <td style={{width:"1%"}}><p> </p></td>
                                                        <td style={{width:"63%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:`${button}`,textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Asik udah nikah, samawa ya</p></td>
                                                    </tr>
                                                    <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                        <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                        <td style={{width:"1%"}}><p> </p></td>
                                                        <td style={{width:"32%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:`${button}`,textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Nais</p></td>
                                                        <td style={{width:"1%"}}><p> </p></td>
                                                        <td style={{width:"63%"}}><p style={{color:"rgb(108, 108, 108)",backgroundColor:`${button}`,textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Happy wedding kakak</p></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section className='p-4' style={{backgroundImage:`${backgroundImage}`,width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm" aria-label="By Freepik">
                                            <input type="button" value="By Freepik" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:`${button}`,borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                        </a>
                                    </div>
                                </div>
                                <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:`${button}`,borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108, 108, 108)",border:"none"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2 mb-3' >Website By Webew.id</h6>
                        </section>
                    </div>
                </section>
                :""
            }
            {/* JENIS WE PERNIKAHAN BLEGOLD, BLEPINK, BLE TOSCA, BLEWHITE */}
            {jenisWePernikahanParams === "blegold" || jenisWePernikahanParams === "blepink" || jenisWePernikahanParams === "bletosca" || jenisWePernikahanParams === "blewhite"
                ?<section>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundImage:`${backgroundImage}`,zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundColor:"black",borderLeft:`3px solid ${color}`,borderRight:`3px solid ${color}`,height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"15px",padding:"30px"}}>
                                        <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"15px",borderLeft:`4px solid ${color}`,borderRight:`4px solid ${color}`}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px",color:`${color}`}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold' style={{color:`${color}`}}>Yusuf</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{color:`${color}`}}>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{backgroundColor:"black",border:`3px solid ${color}`,borderRadius:"15px",color:`${color}`,display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="https://webew.id/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundImage:`${backgroundImage}`,width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>Romeo</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>Juliet</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section className='p-4' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-6">
                                    <img src={bingkai1} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                                </div>
                            </div>
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"15px",borderLeft:`4px solid ${color}`,borderRight:`4px solid ${color}`}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>&</h1></td>
                                    <td style={{width:"45%"}}><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"15px",borderLeft:`4px solid ${color}`,borderRight:`4px solid ${color}`}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Romeo Romeano</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Putra dari Bapak Abdul Aziz & Ibu Nuraini</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:`${color}`}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Juliet Julieto</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Putri dari Bapak Aziz Abdul & Ibu Aininuroh</h6>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:`${backgroundColor}`,width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-1">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:`3px solid ${color}`,borderRight:`3px solid ${color}`}}>
                                                    <img src={bingkai2} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:`${color}`}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Akad Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Sabtu</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:`${color}`}}>13 Mei 2023</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:`${color}`}}>08.00 sd 10.00</h5>
                                                        <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:`${color}`,border:`3px solid ${color}`}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4" style={{marginLeft:"-15px"}}>
                                                    <img src={bingkaiTali} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"25px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src={bingkaiTali} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:`3px solid ${color}`,borderRight:`3px solid ${color}`}}>
                                                    <img src={bingkai2} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:`${color}`}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Resepsi Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Sabtu</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:`${color}`}}>13 Mei 2023</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px",color:`${color}`}}>12.00 sd 15.00</h5>
                                                        <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:`${color}`,border:`3px solid ${color}`}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:`3px solid ${color}`,borderRight:`3px solid ${color}`}}>
                                                    <img src={bingkai2} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:`${color}`}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Alamat Akad</h4>
                                                        <h6 className='text-center fw-normal pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia</h6>
                                                        <a href="https://www.google.com" aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:`${color}`,border:`3px solid ${color}`}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4 ">
                                                    <img src={bingkaiTali} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src={bingkaiTali} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"15px",borderLeft:`3px solid ${color}`,borderRight:`3px solid ${color}`}}>
                                                    <img src={bingkai2} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"5px"}} alt="bingkai" />
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:`${color}`}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Alamat Resepsi</h4>
                                                        <h6 className='text-center fw-normal pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.110 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia</h6>
                                                        <a href="https://www.google.com" aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"black",borderRadius:"15px",color:`${color}`,border:`3px solid ${color}`}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-6 my-4">
                                        <div className='p-3' style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <img src={bingkai3} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                                            <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>(QS. Ar-Rum ayat 21)</h5>
                                            <img src={bingkai4} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginTop:"25px"}} alt="bingkai" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:`${backgroundColor}`,width:"100%",height:"auto"}}>
                                <div className="container p-4">
                                    <div className='row justify-content-center'>
                                        <div className='col-12 col-lg-10 mb-5'>
                                            <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:`${color}`}}></div>
                                            <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Gallery Foto</h4>
                                            <div className="container-fluid">
                                                <div className='rowes justify-content-center'>
                                                    <div className="column">
                                                        <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                        <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                        <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    </div>
                                                    <div className="column">
                                                        <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                        <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                        <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-10 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:`${color}`}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div style={{backgroundColor:"transparant",borderRadius:"15px",width:"100%"}}>
                                                        <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Bank Mandiri</h5>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>An. Romeo</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form-control mb-3' value="1234567890" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"15px",color:`${color}`,backgroundColor:"black",border:`3px solid ${color}`}}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="mx-auto d-block fw-bold py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"80%",backgroundColor:"black",borderRadius:"15px",color:`${color}`,border:`3px solid ${color}`}}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div style={{backgroundColor:"transparant",borderRadius:"15px",width:"100%"}}>
                                                        <h5 className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Bank BCA</h5>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>An. Juliet</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form-control mb-3'value="1234567890" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"15px",color:`${color}`,backgroundColor:"black",border:`3px solid ${color}`}}/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="mx-auto d-block fw-bold py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"80%",backgroundColor:"black",borderRadius:"15px",color:`${color}`,border:`3px solid ${color}`}}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                        
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:`${color}`}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Beri Ucapan</h4>
                                            <form data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama" style={{color:`${color}`}}>Nama</label>
                                                <input type="text" id='nama' name='nama' className='form-control mb-2' required style={{borderRadius:"15px",color:`${color}`,backgroundColor:"black",border:`3px solid ${color}`}} maxLength="100" />
                                                <label className='mx-2 fw-semibold' htmlFor="status" style={{color:`${color}`}}>Status</label>
                                                <select className="form-select mb-2" id="status" name='status' aria-label="status" required style={{borderRadius:"15px",color:`${color}`,backgroundColor:"black",border:`3px solid ${color}`}}>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan" style={{color:`${color}`}}>Ucapan</label>
                                                <input type="text" id='ucapan' name='ucapan' className='form-control mb-4' required style={{borderRadius:"15px",color:`${color}`,backgroundColor:"black",border:`3px solid ${color}`}} maxLength="300"/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"black",border:`3px solid ${color}`,borderRadius:"15px",color:`${color}`}}/>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='p-3' style={{width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:`${color}`}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{color:`${color}`}}>Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                            <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:`${color}`,fontSize:"20px"}}></i></p></td>
                                                            <td style={{width:"1%"}}><p> </p></td>
                                                            <td style={{width:"32%"}}><p style={{backgroundColor:"black",border:`3px solid ${color}`,color:`${color}`,textAlign:"center",borderRadius:"15px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Akang</p></td>
                                                            <td style={{width:"1%"}}><p> </p></td>
                                                            <td style={{width:"63%"}}><p style={{backgroundColor:"black",border:`3px solid ${color}`,color:`${color}`,textAlign:"left",borderRadius:"15px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Halo, selamat wedding ya, semoga samawa</p></td>
                                                        </tr>
                                                        <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                            <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:`${color}`,fontSize:"20px"}}></i></p></td>
                                                            <td style={{width:"1%"}}><p> </p></td>
                                                            <td style={{width:"32%"}}><p style={{backgroundColor:"black",border:`3px solid ${color}`,color:`${color}`,textAlign:"center",borderRadius:"15px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Agro</p></td>
                                                            <td style={{width:"1%"}}><p> </p></td>
                                                            <td style={{width:"63%"}}><p style={{backgroundColor:"black",border:`3px solid ${color}`,color:`${color}`,textAlign:"left",borderRadius:"15px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Asik nikah</p></td>
                                                        </tr>
                                                        <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                            <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:`${color}`,fontSize:"20px"}}></i></p></td>
                                                            <td style={{width:"1%"}}><p> </p></td>
                                                            <td style={{width:"32%"}}><p style={{backgroundColor:"black",border:`3px solid ${color}`,color:`${color}`,textAlign:"center",borderRadius:"15px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Kolah</p></td>
                                                            <td style={{width:"1%"}}><p> </p></td>
                                                            <td style={{width:"63%"}}><p style={{backgroundColor:"black",border:`3px solid ${color}`,color:`${color}`,textAlign:"left",borderRadius:"15px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Selamat menempuh hidup baru</p></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundColor:"black",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='p-4' style={{backgroundColor:`${backgroundColor}`,width:"100%",height:"auto"}}>
                                <div className='bi bi-image text-center' style={{fontSize:"30px",color:`${color}`}}></div>
                                <h4 className='text-center fw-bolder' style={{color:`${color}`}}>Image On Freepik</h4>
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-4">
                                            <a href={link1} aria-label="By macrovector">
                                                <input type="button" value={pemilik1} className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"black",borderRadius:"15px",color:`${color}`,border:`3px solid ${color}`}}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:`${color}`,marginTop:"25px"}}></div>
                                    <h4 className='text-center fw-bolder' style={{color:`${color}`}}>Song On Youtube</h4>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                                <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"black",borderRadius:"15px",color:`${color}`,border:`3px solid ${color}`}}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <h6 className='text-center fw-bolder mt-2' style={{color:`${color}`}}>Website By Webew.id</h6>
                            </div>
                        </section>
                    </div>
                </section>
                :""
            }
            {/* JENIS WE PERNIKAHAN FOTOGRAFI, BIKE, FOOTBALL, BADMINTON */}
            {jenisWePernikahanParams === "fotografi" || jenisWePernikahanParams === "bike" || jenisWePernikahanParams === "football" || jenisWePernikahanParams === "badminton"
                ?<section>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#ffffff31",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",border:"6px solid white",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>Yusuf</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{backgroundColor:"white",boxShadow:"0 10px 20px -10px #8e8e8e",border:"none",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#ffffff31",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>Romeo</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>Juliet</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section className='p-4' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-6">
                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                                </div>
                            </div>
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Romeo Romeano</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak Abdul Aziz & Ibu Nuraini</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><img src={image1} className='mx-auto d-block pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"50px"}} alt="fotografi" /></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Juliet Julieto</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak Aziz Abdul & Ibu Aininuroh</h6>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='py-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-1">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Sabtu</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>13 Mei 2023</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>08.00 sd 10.00</h5>
                                                        <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <img src={image2} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"25px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src={image3} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Sabtu</h5>
                                                        <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>13 Mei 2023</h5>
                                                        <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>12.00 sd 15.00</h5>
                                                        <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Akad</h4>
                                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia</h6>
                                                        <a href="https://www.google.com" aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-4 ">
                                                    <img src={image4} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px"}} alt="fotografi" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-4">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <img src={image5} className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{maxHeight:"220px",marginTop:"30px",transform:"scalex(-1)"}} alt="fotografi" />
                                                </div>
                                                <div className="col-8">
                                                    <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                        <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                        <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Resepsi</h4>
                                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.110 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia</h6>
                                                        <a href="https://www.google.com" aria-label="Google Maps">
                                                            <input type="button" value="Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-6 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b3.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginBottom:"25px"}} alt="bingkai" />
                                            <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”</h6>
                                            <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">(QS. Ar-Rum ayat 21)</h5>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b1.png" className='mx-auto d-block' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",marginTop:"25px"}} alt="bingkai" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className="container p-4">
                                    <div className='row justify-content-center'>
                                        <div className='col-12 col-lg-10 mb-5'>
                                            <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                                            <div className='rowes justify-content-center'>
                                                <div className="column">
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                </div>
                                                <div className="column">
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">
                                    <div className="col-12 col-md-10 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank Mandiri</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. Romeo</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" value="1234567890"/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 mb-4">
                                                    <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank BCA</p>
                                                        <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. Juliet</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7">
                                                                <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine"value="1234567890"/>
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                <button type="button" className="fw-bold mt-2 py-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}>Salin</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                        
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                            <form data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                <label className='mx-2 fw-semibold' htmlFor="nama">Nama</label>
                                                <input type="text" id='nama' name='nama' className='form form-control mb-2' required style={{color:"rgb(108, 108, 108)"}} maxLength="100" />
                                                <label className='mx-2 fw-semibold' htmlFor="status">Status</label>
                                                <select className="form-select form mb-2" id="status" name='status' aria-label="status" required style={{color:"rgb(108, 108, 108)"}}>
                                                    <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                    <option value="Saudara">Saudara</option>
                                                    <option value="Atasan Kerja">Atasan Kerja</option>
                                                    <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                    <option value="Dosen/Guru">Dosen/Guru</option>
                                                    <option value="Teman Kerja">Teman Kerja</option>
                                                    <option value="Teman Sekolah">Teman Sekolah</option>
                                                    <option value="Teman">Teman</option>
                                                </select>
                                                <label className='mx-2 fw-semibold' htmlFor="ucapan">Ucapan</label>
                                                <input type="text" id='ucapan' name='ucapan' className='form form-control mb-4' required style={{color:"rgb(108, 108, 108)"}} maxLength="300"/>
                                                <input type="submit" className='mx-auto d-block fw-bold py-2' style={{width:"80%",backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                            <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                            <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                <table>
                                                    <tbody>
                                                        <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                            <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                            <td style={{width:"1%"}}><p> </p></td>
                                                            <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Akang</p></td>
                                                            <td style={{width:"1%"}}><p> </p></td>
                                                            <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Halo, selamat wedding ya, semoga samawa</p></td>
                                                        </tr>
                                                        <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                            <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                            <td style={{width:"1%"}}><p> </p></td>
                                                            <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Agro</p></td>
                                                            <td style={{width:"1%"}}><p> </p></td>
                                                            <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Asik nikah</p></td>
                                                        </tr>
                                                        <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                            <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                            <td style={{width:"1%"}}><p> </p></td>
                                                            <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Kolah</p></td>
                                                            <td style={{width:"1%"}}><p> </p></td>
                                                            <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Selamat menempuh hidup baru</p></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div className='p-4' style={{backgroundColor:"#cdcdcd31",width:"100%",height:"auto"}}>
                                <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-4">
                                            <a href={link1} aria-label="By macrovector">
                                                <input type="button" value={pemilik1} className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <a href={link2} aria-label="By macrovector">
                                                <input type="button" value={pemilik2} className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <a href="https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm" aria-label="By Freepik">
                                                <input type="button" value="By Freepik" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                    <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                                <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 py-2 fw-bold w-100" style={{backgroundColor:"white",boxShadow:"0 10px 15px -15px #8e8e8e",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"none"}}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            </div>
                        </section>
                    </div>
                </section>
                :""
            }
            {/* JENIS WE PERNIKAHAN BNW1, BNW2, BNW3, BNW4 */}
            {jenisWePernikahanParams === "bnw1" || jenisWePernikahanParams === "bnw2" || jenisWePernikahanParams === "bnw3" || jenisWePernikahanParams === "bnw4"
                ?<section>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#63636350",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>Yusuf</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button className='fw-semibold py-1 px-4' onClick={btnBukaUndangan} style={{border:"4px solid rgb(108, 108, 108)",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:"#63636350",width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>Romeo</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>Juliet</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={image1} className='w-100'alt="bunga" />
                            <h1 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"10px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Romeo Romeano</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak Abdul Aziz & Ibu Nuraini</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Juliet Julieto</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak Aziz Abdul & Ibu Aininuroh</h6>
                                    </div>
                                </div>
                            </div>
                            <img src={image2} className='w-100' style={{marginTop:"10px"}} alt="bunga" />
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto",padding:"5vh 0 5vh 0"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-5 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Sabtu</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>13 Mei 2023</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>08.00 sd 10.00 WIB</h5>
                                                <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src={image3} className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-12 col-md-5 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Sabtu</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>13 Mei 2023</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>11.00 sd 15.00 WIB</h5>
                                                <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src={image3} className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={image1} className='w-100' alt="bunga" />
                            <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Nikah</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia</h6>
                                        <a href="https://www.google.com" aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.110 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia</h6>
                                        <a href="https://www.google.com" aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <img src={image2} className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-6 my-3">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”</h6>
                                                <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">(QS. Ar-Rum ayat 21)</h5>
                                                <img src={image3} className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={image1} className='w-100'  alt="bunga" />
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                        <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                                        <div className="container-fluid">
                                            <div className='rowes justify-content-center'>
                                                <div className="column">
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                </div>
                                                <div className="column">
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src={image2} className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:"#63636350",width:"100%",height:"auto",padding:"5vh 0 5vh 0"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-10 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank Mandiri</p>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. Romeo</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek1' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" value="1234567890"/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="fw-bold mt-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div className='p-4' style={{backgroundColor:"transparant",borderRadius:"20px",width:"100%",boxShadow:"0 0 25px -10px #8e8e8e"}}>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank BCA</p>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. Juliet</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek2' className='form form-control mb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine"value="1234567890"/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="fw-bold mt-2" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"100%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <img src={image3} className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                                <form data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                        <label className='mx-2 fw-semibold' htmlFor="nama">Nama</label>
                                                        <input type="text" id='nama' name='nama' className='form form-control mb-2' required style={{color:"rgb(108, 108, 108)"}} maxLength="100" />
                                                        <label className='mx-2 fw-semibold' htmlFor="status">Status</label>
                                                        <select className="form-select form mb-2" id="status" name='status' aria-label="status" required style={{color:"rgb(108, 108, 108)"}}>
                                                            <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                            <option value="Saudara">Saudara</option>
                                                            <option value="Atasan Kerja">Atasan Kerja</option>
                                                            <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                            <option value="Dosen/Guru">Dosen/Guru</option>
                                                            <option value="Teman Kerja">Teman Kerja</option>
                                                            <option value="Teman Sekolah">Teman Sekolah</option>
                                                            <option value="Teman">Teman</option>
                                                        </select>
                                                        <label className='mx-2 fw-semibold' htmlFor="ucapan">Ucapan</label>
                                                        <input type="text" id='ucapan' name='ucapan' className='form form-control mb-4' required style={{color:"rgb(108, 108, 108)"}} maxLength="300"/>
                                                        <input type="submit" className='mx-auto d-block fw-bold py-1' style={{width:"80%",backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                    </form>
                                                <img src={image3} className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                                <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                    <table>
                                                        <tbody>
                                                            <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Akang</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Halo, selamat wedding ya, semoga samawa</p></td>
                                                            </tr>
                                                            <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Agro</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Asik nikah</p></td>
                                                            </tr>
                                                            <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Kolah</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Selamat menempuh hidup baru</p></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <img src={image3} className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={image1} className='w-100'  alt="bunga" />
                            <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                            <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href={link1} aria-label="By Harryarts">
                                            <input type="button" value={pemilik1} className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href={link2} aria-label="By orchidart">
                                            <input type="button" value={pemilik2} className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm" aria-label="By Freepik">
                                            <input type="button" value="By Freepik" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                                <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            <img src={image2} className='w-100' alt="bunga" style={{marginTop:"10px"}}/>
                        </section>
                    </div>
                </section>
                :""
            }
            {/* JENIS WE PERNIKAHAN AGLAONEMA, AGAPANTHUS, ALAMANDA, AMARYLLIS */}
            {jenisWePernikahanParams === "aglaonema" || jenisWePernikahanParams === "agapanthus" || jenisWePernikahanParams === "alamanda" || jenisWePernikahanParams === "amaryllis"
                ?<section>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="pengantin" />
                                        <table>
                                            <tr>
                                                <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='fw-bold'>Yusuf</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                            </tr>
                                            <tr>
                                                <td><button onClick={btnBukaUndangan} className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AUDIO */}
                    <div className="fixed-bottom" style={{display:bukaUndangan}}>
                        <audio ref={audioRef} src="/song/songWedding.mp3" autoPlay loop preload='none'/>
                        <button onClick={handlePlayPause} style={{margin:"0 0 5px 5px",width:"60px",height:"60px",border:"none",borderRadius:"50%",backgroundColor:"black",color:"white"}}>
                            <span>
                            {isPlaying ? <i class="bi bi-pause-circle" style={{fontSize:"33px"}}></i> :<i class="bi bi-play-circle" style={{fontSize:"33px"}}></i>}
                            </span>
                        </button>
                    </div>
                    {/* COVER */}
                    <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:`${backgroundColor}`,width:"100%",height:"100vh"}}>
                            <table>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",marginTop:"35vh",fontStyle:"oblique",fontVariant:"small-caps"}}>Romeo</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold' style={{color:"white",fontFamily:"Times New Roman"}}>&</h1></td>
                                </tr>
                                <tr>
                                    <td><h1 className='fw-bold display-3' style={{color:"white",fontStyle:"oblique",fontVariant:"small-caps"}}>Juliet</h1></td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <div style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={image1} className='w-100' style={{transform:`${rotate}`}} alt="bunga" />
                            <h1 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-15px"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                            <h6 className='text-center fw-normal my-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tr>
                                    <td style={{width:"45%"}}><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"30px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><h1 data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">&</h1></td>
                                    <td style={{width:"45%"}}><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" className='img-fluid' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{width:"250px",borderRadius:"30px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-4">
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Romeo Romeano</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putra dari Bapak Abdul Aziz & Ibu Nuraini</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center bi bi-suit-heart-fill" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"35px",color:"rgb(108, 108, 108)"}}></div></div>
                                    <div className="col-12 col-md-5">
                                        <h6 className='text-center fw-bold' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Juliet Julieto</h6>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Putri dari Bapak Aziz Abdul & Ibu Aininuroh</h6>
                                    </div>
                                </div>
                            </div>
                            <img src={image1} className='w-100' style={{marginTop:"-10px"}} alt="bunga" />
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:`${backgroundColor}`,width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Sabtu</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>13 Mei 2023</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>08.00 sd 10.00 WIB</h5>
                                                <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender"  className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src={image2} className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-calendar2-event text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h4>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Sabtu</h5>
                                                <h5 className='text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>13 Mei 2023</h5>
                                                <h5 className='text-center pb-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{marginTop:"-10px"}}>11.00 sd 15.00 WIB</h5>
                                                <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                    <input type="button" value="Simpan ke kalender"  className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                </a>
                                                <img src={image2} className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={image1} className='w-100' style={{transform:`${rotate}`}} alt="bunga" />
                            <div className='bi bi-map text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-25px"}}></div>
                            <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Alamat Nikah</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Akad Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia</h6>
                                        <a href="https://www.google.com" aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps"  className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-5 mt-4">
                                        <h5 className='text-center fw-bolder bi bi-geo-alt' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Resepsi Nikah</h5>
                                        <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.110 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia</h6>
                                        <a href="https://www.google.com" aria-label="Google Maps">
                                            <input type="button" value="Lihat Google Maps"  className="form form-control fw-bold mb-4 mt-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <img src={image1} className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:`${backgroundColor}`,width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <h6 className='text-center fw-normal' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”</h6>
                                                <h5 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">(QS. Ar-Rum ayat 21)</h5>
                                                <img src={image2} className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={image1} className='w-100' style={{transform:`${rotate}`}} alt="bunga" />
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <div className='bi bi-images text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-25px"}}></div>
                                        <h4 className='text-center fw-bolder mb-4' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Gallery Foto</h4>
                                        <div className="container-fluid">
                                            <div className='rowes justify-content-center'>
                                                <div className="column">
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                </div>
                                                <div className="column">
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                    <a href="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src="https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg" className="img-fluid" width="100%" alt="Foto" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{borderRadius:"10px"}}/></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src={image1} className='w-100' alt="bunga" />
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:`${backgroundColor}`,width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-10 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-credit-card text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Kado Pernikahan</h4>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank Mandiri</p>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. Romeo</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek1' className="form form-control fw-bold mb-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}} value="1234567890"/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="form form-control fw-bold mb-4" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Bank BCA</p>
                                                            <p className='fw-bold text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">An. Juliet</p>
                                                            <div className="row">
                                                                <div className="col-12 col-md-7">
                                                                    <input type="text" id='noRek2' className="form form-control fw-bold mb-3" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}value="1234567890"/>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <button type="button" className="form form-control fw-bold mb-4" data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}>Salin</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <img src={image2} className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Beri Ucapan</h4>
                                                <form data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                        <label className='mx-2 fw-semibold' htmlFor="nama">Nama</label>
                                                        <input type="text" id='nama' name='nama' className='form form-control mb-2' required style={{color:"rgb(108, 108, 108)"}} maxLength="100" />
                                                        <label className='mx-2 fw-semibold' htmlFor="status">Status</label>
                                                        <select className="form-select form mb-2" id="status" name='status' aria-label="status" required style={{color:"rgb(108, 108, 108)"}}>
                                                            <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                            <option value="Saudara">Saudara</option>
                                                            <option value="Atasan Kerja">Atasan Kerja</option>
                                                            <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                            <option value="Dosen/Guru">Dosen/Guru</option>
                                                            <option value="Teman Kerja">Teman Kerja</option>
                                                            <option value="Teman Sekolah">Teman Sekolah</option>
                                                            <option value="Teman">Teman</option>
                                                        </select>
                                                        <label className='mx-2 fw-semibold' htmlFor="ucapan">Ucapan</label>
                                                        <input type="text" id='ucapan' name='ucapan' className='form form-control mb-4' required style={{color:"rgb(108, 108, 108)"}} maxLength="300"/>
                                                        <input type="submit"  className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                                    </form>
                                                <img src={image2} className='w-100' alt="bunga" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box p-3' style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                <div className='bi bi-chat-square-text text-center' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine" style={{fontSize:"30px",color:"rgb(108, 108, 108)"}}></div>
                                                <h4 className='text-center fw-bold pt-1 pb-3' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">Ucapan Live</h4>
                                                <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                    <table>
                                                        <tbody>
                                                            <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Akang</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Halo, selamat wedding ya, semoga samawa</p></td>
                                                            </tr>
                                                            <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Agro</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Asik nikah</p></td>
                                                            </tr>
                                                            <tr className='mt-2' data-aos="zoom-in-up" data-aos-delay="250" data-aos-easing="ease-in-sine">
                                                                <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:"rgb(108, 108, 108)",fontSize:"20px"}}></i></p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"32%"}}><p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>Kolah</p></td>
                                                                <td style={{width:"1%"}}><p> </p></td>
                                                                <td style={{width:"63%"}}><p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"13px",fontWeight:"600"}}>Selamat menempuh hidup baru</p></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <img src={image2} className='w-100' alt="bunga" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={image1} className='w-100' style={{transform:`${rotate}`}} alt="bunga" />
                            <div className='bi bi-image text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"-25px"}}></div>
                            <h4 className='text-center fw-bolder'>Image On Freepik</h4>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href={link1} aria-label="By pikisuperstar">
                                            <input type="button" value={pemilik1} className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href={link2} aria-label="By Freepik">
                                            <input type="button" value={pemilik2} className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm" aria-label="By Freepik">
                                            <input type="button" value="By Freepik"   className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                                <div className='bi bi-file-music text-center' style={{fontSize:"30px",color:"rgb(108, 108, 108)",marginTop:"25px"}}></div>
                                <h4 className='text-center fw-bolder'>Song On Youtube</h4>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <a href="https://www.youtube.com/watch?v=GFBMg92iTpE" aria-label="By amatoge & the musicboys">
                                            <input type="button" value="By amatoge & the musicboys" className="form form-control fw-bold mb-4 mt-3" style={{paddingTop:"13px",paddingBottom:"13px",border:"none",boxShadow:"-10px 10px 25px -18px #8e8e8e",color:"rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                            <img src={image1} className='w-100' alt="bunga" style={{marginTop:"-10px"}}/>
                        </section>
                    </div>
                </section>
                :""
            }
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE PERNIKAHAN CONTOH
// EKSPOR
export default WePernikahanContoh