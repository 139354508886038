// IMPORT REACT
import React,{ useRef, useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE PARAMS, USE NAVIGATE, LINK
import { useParams, useNavigate } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../../Layout/MenuDonasi.jsx'
// MENU WE ULTAH SALIN KODE
const MenuWeUltahSalinKode = () => { // KURUNG BUKA MENU WE ULTAH SALIN KODE
    // MEMBUAT VARIABEL WE ULTAH SALIN KODE
    const { panggilanParams } = useParams();
    const [displayLoader, setDisplayLoader] = useState('')
    const [nama, setNama] = useState('')
    const [salinKode, setSalinKode] = useState('')
    const [apiWhatsapp, setApiWhatsapp] = useState('')
    const [expire, setExpire] = useState('')
    const [display, setDisplay] = useState('')
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL INPUTREF BY USEREF
    const inputRef = useRef(null);
    // IMPORT NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        selectWeUltah()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-ultah", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES MENAMPILKAN DATA ULTAH BERDASARKAN PANGGILAN BY PARAMS
    const selectWeUltah = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeUltahBE/${panggilanParams}`)
            setNama(response.data.nama)
            setApiWhatsapp("https://api.whatsapp.com/send?text= Salin kode berikut : \n" + response.data.kode + "\n dan klik link ini : \n https://webew.id/ucapan-ultah. \n \n Atau Klik link berikut : \n https://webew.id/ucapan-ultah-hasil/" + response.data.kode )
            setSalinKode("Salin kode berikut : " + response.data.kode + " dan klik link ini : https://webew.id/ucapan-ultah. Atau Klik link berikut : https://webew.id/ucapan-ultah-hasil/" + response.data.kode )
        }catch(error){
            console.log(error)
        }
    }
    // PROSES MENYALIN TEKS / LINK
    function copyToClipboard(e) {
        inputRef.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // PROSES TUTUP MODAL
    const tutup = () => {
        setDisplay("none")
    }
    // RETURN    
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* MODAL MESSAGE*/}
                <div style={{position:"fixed",zIndex:"9999",backgroundColor:"#282a3a8b",height:"100vh",width:"100%",display:display}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6">
                                <div style={{backgroundColor:"white", width:"100%", padding:"20px",borderRadius:"20px",marginTop:"28vh",boxShadow:"-10px 10px 25px -15px #7f7f7f"}}>
                                    {nama === ""
                                        ?<h6 style={{textAlign:"center",color:"red",fontWeight:"700"}}>Maaf ya kamu belum isi form data, silahkan isi form data dulu ya</h6> :<h6 className='card-text-rupiah' style={{textAlign:"center",fontWeight:"700"}}>Hore ucapan ulang tahun sudah jadi<br /><p style={{margin:"20px 0 0 0",fontSize:"12pt",fontWeight:"500"}}>Silahkan nanti bagikan ke orangnya ya, semoga berhasil</p></h6>
                                    }
                                    {nama === ""
                                        ?<a href={`/menu-we-ultah/${panggilan}`} className="btn btn-webew-product" aria-label="Kembali webew.id" style={{marginTop:"18px"}}>Oke Siap</a> :<button type="button" className="btn btn-webew-product" style={{marginTop:"18px"}} onClick={tutup}>Oke Siap</button>
                                    }       
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* TUTUP MODAL MESSAGE */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div  className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row mb-5 py-1">
                                        <div className="col-12 col-lg-6 mb-4">
                                            <div className="box p-4">
                                                <a href={`/menu-we-ultah/${panggilan}`} className="btn btn-webew-product" aria-label="Kembali webew.id" style={{width:"auto"}}><i class="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                                                <h3 className="text-center fw-bold" style={{marginTop:"10px"}}>Salin Kode Ultah</h3>
                                                <p className="text-center fw-normal">Kalian bisa salin kode dan bagikan ke dia yang sedang ulang tahun atau hanya bagikan dengan Whatsapp</p>
                                                <div className="row">
                                                    <button className="btn btn-webew-product" data-bs-toggle="modal" data-bs-target="#staticBackdropDonasi"><i class="bi bi-coin"></i> Donasi</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="box p-4" style={{width: "100%"}}>
                                                <h5 className="text-center fw-bold">Atas nama {nama}</h5>
                                                <div className="row">
                                                    <div className="col-12 col-sm-7">
                                                        <input type="text" id="kode" className="form-control form mt-3" ref={inputRef} value={salinKode} readOnly/>
                                                    </div>
                                                    <div className="col-12 col-sm-5">
                                                        <button type="button" className="btn btn-webew-product" style={{marginTop:"16.5px"}} onClick={copyToClipboard}><i class="bi bi-clipboard-check"></i> Salin Kode</button>
                                                    </div>
                                                    <h6 className='text-center fw-bold my-3'>Atau</h6>
                                                    <div className="col-12">
                                                        <a href={apiWhatsapp} className="btn btn-webew-product" style={{height:"auto"}}><i class="bi bi-share"></i> Bagikan lewat WhatsApp</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU WE ULTAH SALIN KODE
// EKSPOR
export default MenuWeUltahSalinKode