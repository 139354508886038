import React from "react"

const Footer = () => {
    
    return (
        <footer>
            <div className='container mb-5 pb-5'>
                <p className='copyright text-center fw-semibold' style={{color:'#d1d1d1',fontSize:'9pt'}}>&copy; 2024 Promap By Webew.id</p>
            </div>
        </footer>
    )
}

export default Footer