import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
/////WEBEW//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  import Error from './components/WebProduct/Error.jsx'
  import Home from './components/WebProduct/Home.jsx'
  import Navbar from './components/Layout/Navbar.jsx'
  import Footer from './components/Layout/Footer.jsx'
  /* LOGIN ADMIN */
  import LoginAdmin from './components/Login/LoginAdmin/Login.jsx'
  import RegisterAdmin from './components/Login/LoginAdmin/Register.jsx'
  import LupaPasswordAdmin from './components/Login/LoginAdmin/LupaPassword.jsx'
  import DashboardAdmin from './components/Admin/MenuAdminWeDashboard.jsx'
  import LoadingAdmin from './components/Admin/MenuAdminWeLoading.jsx'
  /* LOGIN USERS */
  import LoginUsers from './components/Login/LoginUsers/Login.jsx'
  import RegisterUsers from './components/Login/LoginUsers/Register.jsx'
  import LupaPasswordUsers from './components/Login/LoginUsers/LupaPassword.jsx'
  import DashboardUsers from './components/Users/MenuWeDashboard.jsx'
  import LoadingUsers from './components/Users/MenuWeLoading.jsx'
  /* WECV ADMIN */
  import MenuAdminWeCv from './components/Admin/MenuAdminWeCv.jsx'
  /* WECV USERS */
  import MenuWeCv from './components/Users/MenuWeCv.jsx'
  import MenuWeCvForm from './components/Users/MenuBikinCv/MenuWeCvForm.jsx'
  import MenuWeCvDownload from './components/Users/MenuBikinCv/MenuWeCvDownload.jsx'
  import WeCV from './components/WebProduct/WeCV/WeCV.jsx'
  /* WEWEBSITE USERS */
  import MenuWeWebsite from './components/Users/MenuWeWebsite.jsx'
  import MenuWeWebsiteDownload from './components/Users/MenuWeWebsite/MenuWeWebsiteDownload.jsx'
  import WeWebsite from './components/WebProduct/WeWebsite/WeWebsite.jsx'
  import WeWebsiteContoh from './components/WebProduct/WeWebsite/WeWebsiteContoh.jsx'
  /* WEULTAH ADMIN */
  import MenuAdminWeUltah from './components/Admin/MenuAdminWeUltah.jsx'
  /* WEULTAH USERS */
  import MenuWeUltah from './components/Users/MenuWeUltah.jsx'
  import MenuWeUltahSalinKode from './components/Users/MenuWeUltah/MenuWeUltahSalinKode.jsx'
  import WeUltah from './components/WebProduct/WeUltah/WeUltah.jsx'
  import WeUltahHasil from './components/WebProduct/WeUltah/WeUltahHasil.jsx'
  /* WEPERNIKAHAN ADMIN */
  import MenuAdminWePernikahan from './components/Admin/MenuAdminWePernikahan.jsx'
  /* WEPERNIKAHAN USERS */
  import MenuWePernikahan from './components/Users/MenuWePernikahan.jsx'
  import MenuWePernikahanForm from './components/Users/MenuWePernikahan/MenuWePernikahanForm.jsx'
  import MenuWePernikahanHasil from './components/Users/MenuWePernikahan/MenuWePernikahanHasil.jsx'
  import WePernikahan from './components/WebProduct/WePernikahan/WePernikahan.jsx'
  import WePernikahanContoh from './components/WebProduct/WePernikahan/WePernikahanContoh.jsx'
  import WePernikahanHasil from './components/WebProduct/WePernikahan/WePernikahanHasil.jsx'
  /* WEAQIQAH ADMIN */
  import MenuAdminWeAqiqah from './components/Admin/MenuAdminWeAqiqah.jsx'
  /* WEAQIQAH USERS */
  import MenuWeAqiqah from './components/Users/MenuWeAqiqah.jsx'
  import MenuWeAqiqahForm from './components/Users/MenuWeAqiqah/MenuWeAqiqahForm.jsx'
  import MenuWeAqiqahHasil from './components/Users/MenuWeAqiqah/MenuWeAqiqahHasil.jsx'
  import WeAqiqah from './components/WebProduct/WeAqiqah/WeAqiqah.jsx'
  import WeAqiqahContoh from './components/WebProduct/WeAqiqah/WeAqiqahContoh.jsx'
  import WeAqiqahHasil from './components/WebProduct/WeAqiqah/WeAqiqahHasil.jsx'
  /* WEMOTIVASI ADMIN */
  import MenuAdminWeMotivasi from './components/Admin/MenuAdminWeMotivasi.jsx'
  /* WEMOTIVASI USERS */
  import MenuWeMotivasi from './components/Users/MenuWeMotivasi.jsx'
  import MenuWeMotivasiUbah from './components/Users/MenuWeMotivasi/MenuWeMotivasiUbah.jsx'
  import WeMotivasi from './components/WebProduct/WeMotivasi/WeMotivasi.jsx'
  /* WEUSERS ADMIN */
  import MenuAdminWeUsers from './components/Admin/MenuAdminWeUsers.jsx'
  /* WESaran ADMIN */
  import MenuAdminWeSaran from './components/Admin/MenuAdminWeSaran.jsx'
  /* WEKHODAM USERS */
  import MenuWeKhodam from './components/Users/MenuWeKhodam.jsx'
  import WeKhodam from './components/WebProduct/WeKhodam/WeKhodam.jsx'
  /* WENABUNG ADMIN */
  import MenuAdminWeNabung from './components/Admin/MenuAdminWeNabung.jsx'
  /* WENABUNG USERS */
  import MenuWeNabung from './components/Users/MenuWeNabung.jsx'
  import WeNabung from './components/WebProduct/WeNabung/WeNabung.jsx'
  /* WEPDF USERS */
  import WeHasilPdf from './components/WebProduct/WePdf/WeHasilPdf.jsx'
  /* WEBLOG USERS */
  import ResumeArsitekturIot from './components/WebProduct/MenuBlog/ResumeArsitekturIot.jsx'
  import Pertemuan4 from './components/WebProduct/MenuBlog/Pertemuan4.jsx'
/////MAP////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  import MapNavbar from "./components/Layout/LayoutMap/Navbar.jsx"
  /* LOGIN */
  import MapLogin from "./components/Login/LoginMap/Login.jsx"
  import MapRegister from "./components/Login/LoginMap/Register.jsx"
  import MapLupaPassword from "./components/Login/LoginMap/LupaPassword.jsx"
  import MapDashboard from "./components/Users/UsersMap/MenuDashboard.jsx"
  /* BIKIN PROPOSAL */
  import MapMenuBikinProposalTA from "./components/Users/UsersMap/MenuBikinProposal/MenuBikinProposalTA.jsx"
  import MapMenuBikinProposalTR from "./components/Users/UsersMap/MenuBikinProposal/MenuBikinProposalTR.jsx"
  import MapMenuBikinProposalTP from "./components/Users/UsersMap/MenuBikinProposal/MenuBikinProposalTP.jsx"
  import MapMenuBikinProposalTC from "./components/Users/UsersMap/MenuBikinProposal/MenuBikinProposalTC.jsx"
  import MapMenuBikinProposalPP from "./components/Users/UsersMap/MenuBikinProposal/MenuBikinProposalPP.jsx"
  import MapMenuHistoryProposal from "./components/Users/UsersMap/MenuBikinProposal/MenuHistoryProposal.jsx"
  import MapFooter from "./components/Layout/LayoutMap/Footer.jsx"
/////APP////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function App() {
  return (
    <Router>
      <Routes>
        {/* WEBEW */}
          <Route path='*'element={<><Navbar /><Error/></>}/>
          {/* HOME */}
          <Route path='/'element={<><Navbar /><Home /><Footer/></>}/>
          {/* LOGIN ADMIN */}
          <Route path='/lowemin'element={<><Navbar /><LoginAdmin /><Footer/></>}/>
          <Route path='/rewemin' element={<><Navbar /><RegisterAdmin /><Footer/></>}/>
          <Route path='/lupwemin' element={<><Navbar /><LupaPasswordAdmin /><Footer/></>}/>
          <Route path='/dashmin/:panggilanParams' element={<DashboardAdmin />}/>
          <Route path='/loamin' element={<LoadingAdmin />}/>
          {/* LOGIN USERS */}
          <Route path='/login/:jenisAplikasiParams'element={<><Navbar /><LoginUsers /><Footer/></>}/>
          <Route path='/register/:jenisAplikasiParams' element={<><Navbar /><RegisterUsers /><Footer/></>}/>
          <Route path='/lupa-password/:jenisAplikasiParams' element={<><Navbar /><LupaPasswordUsers /><Footer/></>}/>
          <Route path='/dashboard/:panggilanParams' element={<DashboardUsers />}/>
          <Route path='/loading/:jenisAplikasiParams' element={<LoadingUsers />}/>
          {/* WECV ADMIN */}
          <Route path='/menuAdminWeCv/:panggilanParams' element={<MenuAdminWeCv />}/>
          {/* WECV USERS */}
          <Route path='/menu-we-cv/:panggilanParams' element={<MenuWeCv />}/>
          <Route path='/menu-we-cv-form/:panggilanParams/:jenisWeCvParams' element={<MenuWeCvForm />}/>
          <Route path='/menu-we-cv-download/:panggilanParams/:jenisWeCvParams' element={<MenuWeCvDownload />}/>
          <Route path='/we-cv' element={<><Navbar /><WeCV /><Footer/></>}/>
          {/* WEWEBSITE USERS */}
          <Route path='/menu-we-website/:panggilanParams' element={<MenuWeWebsite />}/>
          <Route path='/menu-we-website-download/:panggilanParams/:jenisWeWebsiteParams' element={<MenuWeWebsiteDownload />}/>
          <Route path='/we-website' element={<><Navbar /><WeWebsite /><Footer/></>}/>
          <Route path='/we-website/:jenisWeWebsiteParams' element={<><Navbar /><WeWebsiteContoh /><Footer/></>}/>
          {/* WEULTAH ADMIN */}
          <Route path='/menuAdminWeUltah/:panggilanParams' element={<MenuAdminWeUltah />}/>
          {/* WEULTAH USERS */}
          <Route path='/menu-we-ultah/:panggilanParams' element={<MenuWeUltah />}/>
          <Route path='/menu-we-ultah-salin-kode/:panggilanParams' element={<MenuWeUltahSalinKode />}/>
          <Route path='/we-ultah' element={<><Navbar /><WeUltah /><Footer/></>}/>
          <Route path='/we-ultah/:kode' element={<><Navbar /><WeUltahHasil /></>}/>
          {/* WEPERNIKAHAN ADMIN */}
          <Route path='/menuAdminWePernikahan/:panggilanParams' element={<MenuAdminWePernikahan />}/>
          {/* WEPERNIKAHAN USERS */}
          <Route path='/menu-we-pernikahan/:panggilanParams' element={<MenuWePernikahan />}/>
          <Route path='/menu-we-pernikahan-form/:panggilanParams/:jenisWePernikahanParams' element={<MenuWePernikahanForm />}/>
          <Route path='/menu-we-pernikahan-hasil/:panggilanParams/:jenisWePernikahanParams' element={<MenuWePernikahanHasil />}/>
          <Route path='/we-pernikahan' element={<><Navbar /><WePernikahan /><Footer/></>}/>
          <Route path='/we-pernikahan/:jenisWePernikahanParams' element={<><WePernikahanContoh/></>}/>
          <Route path='/we-pernikahan/:salinLink/:namaTamu' element={<><WePernikahanHasil/></>}/>
          {/* WEAQIQAH ADMIN */}
          <Route path='/menuAdminWeAqiqah/:panggilanParams' element={<MenuAdminWeAqiqah />}/>
          {/* WEAQIQAH USERS */}
          <Route path='/menu-we-aqiqah/:panggilanParams' element={<MenuWeAqiqah />}/>
          <Route path='/menu-we-aqiqah-form/:panggilanParams/:jenisWeAqiqahParams' element={<MenuWeAqiqahForm />}/>
          <Route path='/menu-we-aqiqah-hasil/:panggilanParams/:jenisWeAqiqahParams' element={<MenuWeAqiqahHasil />}/>
          <Route path='/we-aqiqah' element={<><Navbar /><WeAqiqah /><Footer/></>}/>
          <Route path='/we-aqiqah/:jenisWeAqiqahParams' element={<WeAqiqahContoh />}/>
          <Route path='/we-aqiqah/:salinLink/:namaTamu' element={<><WeAqiqahHasil/></>}/>
          {/* WEMOTIVASI ADMIN */}
          <Route path='/menuAdminWeMotivasi/:panggilanParams' element={<MenuAdminWeMotivasi />}/>
          {/* WEMOTIVASI USERS */}
          <Route path='/menu-we-motivasi/:panggilanParams' element={<MenuWeMotivasi />}/>
          <Route path='/menu-we-motivasi-ubah/:panggilanParams' element={<MenuWeMotivasiUbah />}/>
          <Route path='/we-motivasi' element={<><Navbar /><WeMotivasi /></>}/>
          {/* WEUSERS ADMIN */}
          <Route path='/menuAdminWeUsers/:panggilanParams' element={<MenuAdminWeUsers />}/>
          {/* WESARAN ADMIN */}
          <Route path='/menuAdminWeSaran/:panggilanParams' element={<MenuAdminWeSaran />}/>
          {/* WEKHODAM */}
          <Route path='/menu-we-khodam/:panggilanParams' element={<MenuWeKhodam />}/>
          <Route path='/we-khodam' element={<><Navbar /><WeKhodam /></>}/>
          {/* WENABUNG ADMIN */}
          <Route path='/menuAdminWeNabung/:panggilanParams' element={<MenuAdminWeNabung />}/>
          {/* WENABUNG USER */}
          <Route path='/menu-we-nabung/:panggilanParams/:jenisTabunganParams' element={<MenuWeNabung />}/>
          <Route path='/we-nabung' element={<><Navbar /><WeNabung /></>}/>
          {/* WEPDF */}
          <Route path='/we-hasil-pdf' element={<WeHasilPdf />}/>
          {/* BLOG */}
          <Route path='/blog-arsitektur-iot' element={<><Navbar /><ResumeArsitekturIot /></>}/>
          <Route path='/blog-pertemuan-4' element={<><Navbar /><Pertemuan4 /></>}/>
        {/* WEBEW */}
        {/* MAP */}
          {/* LOGIN */}
          <Route path="/menu-login-map"element={<><MapNavbar /><MapLogin /><MapFooter/></>}/>
          <Route path="/menu-register-map" element={<><MapNavbar /><MapRegister /><MapFooter/></>}/>
          <Route path="/menu-lupa-password-map" element={<><MapNavbar /><MapLupaPassword /><MapFooter/></>}/>
          <Route path="/menu-dashboard-promap" element={<MapDashboard />}/>
          {/* BIKIN PROPOSAL USER */}
          <Route path="/menu-bikin-proposal-TA/:panggilanParams" element={<MapMenuBikinProposalTA />}/>
          <Route path="/menu-bikin-proposal-TR/:panggilanParams" element={<MapMenuBikinProposalTR />}/>
          <Route path="/menu-bikin-proposal-TP/:panggilanParams" element={<MapMenuBikinProposalTP />}/>
          <Route path="/menu-bikin-proposal-TC/:panggilanParams" element={<MapMenuBikinProposalTC />}/>
          <Route path="/menu-bikin-proposal-PP/:panggilanParams" element={<MapMenuBikinProposalPP />}/>
          <Route path="/menu-history-proposal" element={<MapMenuHistoryProposal />}/>
        {/* MAP */}
      </Routes>
    </Router>
  )
}
// ekspor app
export default App
