// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate, Link } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBarAdmin.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbarAdmin.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBotAdmin.jsx'
// MENU WE AQIQAH
const MenuWePernikahanAdmin = () => { // BUKA KURUNG MENU WE AQIQAH
    // MEMBUAT VARIABEL WE AQIQAH
    const [displayLoader, setDisplayLoader] = useState("")
    const [panggilan, setPanggilan] = useState('')
    const [data, setData] = useState([])
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        selectAllWePernikahan()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokMinBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/lowemin", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokMinBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
     // PROSES MENAMPILKAN WE ADMIN BERDASARKAN PANGGILAN BY PARAMS
     const selectAllWePernikahan = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectAllWePernikahanBE`)
            setData(response.data)
        }catch(error){
            console.log(error)
        }
    }
    // PROSES MENGHAPUS DATA PERNIKAHAN
    const deleteData = async (id) => {
        try {
            await axios.delete(`https://server13.webew.id/deleteWePernikahanBE/${id}`)
            selectAllWePernikahan()
        } catch (error) {
            console.log(error)
        }
    }
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <h4 className="text-center fw-bold">We Pernikahan Admin</h4>
                                    <div className='my-5 pb-3'>
                                        <div className='box' style={{width: '100%',minHeight:'70vh',maxHeight:'70vh',overflow:"auto"}}>
                                            <table style={{width:"10000px",overflow:"auto"}}>
                                                <thead>
                                                    <tr style={{borderBottom:'2px solid #f5f5f5'}}>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Aksi</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>No.</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Foto Latar</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Foto Pria</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Foto Wanita</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Foto Galeri 1</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Foto Galeri 2</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Foto Galeri 3</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Foto Galeri 4</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Foto Galeri 5</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Foto Galeri 6</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Nama Panggil Pria</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Nama Panggil Wanita</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Nama Lengkap Pria</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Nama Lengkap Wanita</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Nama Bapak Pria</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Nama Bapak Wanita</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Nama Ibu Pria</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Nama Ibu Wanita</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Hari Akad</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Tanggal Akad</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Jam Akad Mulai</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Jam Akad Akhir</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Hari Resepsi</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Tanggal Resepsi</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Jam Resepsi Mulai</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Jam Resepsi Akhir</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Alamat Akad</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Link Maps Alamat Akad</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Alamat Resepsi</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Link Maps Alamat Resepsi</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Kata Kata</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Nama Surah</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Nama Bank 1</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>No Rek 1</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Atas Nama 1</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Nama Bank 2</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>No Rek 2</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Atas Nama 2</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Jenis Undangan Digital Pernikahan</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Salin Link</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Created At</td>
                                                        <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Updated At</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((user,index)=>(
                                                        <tr key={data.id} style={{borderBottom:'2px solid #f5f5f5'}}>
                                                        <td className='p-2 fw-semibold text-center' style={{fontSize:'10pt',color:"white"}}><button className="btn btn-logout mx-auto d-block p-2" onClick={()=> deleteData(user.id)} style={{border:"none",backgroundColor:"red"}}><i className="bi bi-trash3" style={{color:"white"}}></i></button></td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{index + 1}.</td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}><img src={user.urlFotoLatar} className='mx-auto d-block rounded' alt='foto' style={{minWidth:'60px',maxWidth:'60px'}}/></td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}><img src={user.urlFotoPria} className='mx-auto d-block rounded' alt='foto' style={{minWidth:'60px',maxWidth:'60px'}}/></td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}><img src={user.urlFotoWanita} className='mx-auto d-block rounded' alt='foto' style={{minWidth:'60px',maxWidth:'60px'}}/></td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}><img src={user.urlFotoGaleri1} className='mx-auto d-block rounded' alt='foto' style={{minWidth:'60px',maxWidth:'60px'}}/></td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}><img src={user.urlFotoGaleri2} className='mx-auto d-block rounded' alt='foto' style={{minWidth:'60px',maxWidth:'60px'}}/></td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}><img src={user.urlFotoGaleri3} className='mx-auto d-block rounded' alt='foto' style={{minWidth:'60px',maxWidth:'60px'}}/></td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}><img src={user.urlFotoGaleri4} className='mx-auto d-block rounded' alt='foto' style={{minWidth:'60px',maxWidth:'60px'}}/></td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}><img src={user.urlFotoGaleri5} className='mx-auto d-block rounded' alt='foto' style={{minWidth:'60px',maxWidth:'60px'}}/></td>
                                                            <td className='px-3 py-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}><img src={user.urlFotoGaleri6} className='mx-auto d-block rounded' alt='foto' style={{minWidth:'60px',maxWidth:'60px'}}/></td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.namaPanggilPria}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.namaPanggilWanita}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.namaLengkapPria}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.namaLengkapWanita}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.namaBapakPria}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.namaBapakWanita}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.namaIbuPria}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.namaIbuWanita}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.hariAkad}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.tanggalAkad}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.jamAkadMulai}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.jamAkadAkhir}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.hariResepsi}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.tanggalResepsi}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.jamResepsiMulai}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.jamResepsiAkhir}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.alamatAkad}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.linkMapsAlamatAkad}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.alamatResepsi}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.linkMapsAlamatResepsi}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.kataKata}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.namaSurah}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.namaBank1}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.noRek1}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.atasNama1}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.namaBank2}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.noRek2}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.atasNama2}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.jenisUndanganDigitalPernikahan}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.salinLink}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.createdAt}</td>
                                                            <td className='px-3 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.updatedAt}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KUTUNG MENU WE AQIQAH
// EKSPOR
export default MenuWePernikahanAdmin