// IMPORT REACT
import React,{ useRef, useState, useEffect } from 'react'
// MENU DONASI
const MenuDonasi = () => { // KURUNG BUKA MENU DONASI
    // MEMBUAT VARIABEL DONASI
    const [salinBca, setSalinBca] = useState('')
    const [salinBni, setSalinBni] = useState('')
    const [salinBri, setSalinBri] = useState('')
    const [salinLinkAja, setSalinLinkAja] = useState('')
    const [salinMandiri, setSalinMandiri] = useState('')
    const [salinOvo, setSalinOvo] = useState('')
    // MMEBUAT VARIABEL BUTTON SALIN DONASI
    const inputRefBca = useRef(null);
    const inputRefBni = useRef(null);
    const inputRefBri = useRef(null);
    const inputRefLinkAja = useRef(null);
    const inputRefMandiri = useRef(null);
    const inputRefOvo = useRef(null);
    // USE EFFECT
    useEffect(()=>{
        setSalinBca("122081211156521")
        setSalinBni("081211156521")
        setSalinBri("081211156521")
        setSalinLinkAja("87788362272")
        setSalinMandiri("893081211156521")
        setSalinOvo("087788362272")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // MODAL DONASI BCA
    function salinRekBca(e) {
        inputRefBca.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // MODAL DONASI BNI
    function salinRekBni(e) {
        inputRefBni.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // MODAL DONASI BRI
    function salinRekBri(e) {
        inputRefBri.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // MODAL DONASI LINK AJA
    function salinRekLinkAja(e) {
        inputRefLinkAja.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // MODAL DONASI MANDIRI
    function salinRekMandiri(e) {
        inputRefMandiri.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // MODAL DONASI OVO
    function salinRekOvo(e) {
        inputRefOvo.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* MODAL DONASI */}
                {/* MODAL DONASI BACKDROP */}
                <div className="modal fade" id="staticBackdropDonasi" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn btn-logout mx-auto px-4 py-1"  data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi'>Tutup</button>
                            </div>
                            <div className="modal-body">
                                <p className="text-center fw-bold">Yuk kirim hadiah Ke Webew, Agar Webew Bisa Terus Berkembang</p>
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <button type="button" className="btn btn-login w-100" data-bs-toggle="modal" style={{paddingLeft:"0px",paddingRight:"0px"}} data-bs-target="#staticBackdropBCA">BCA</button>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <button type="button" className="btn btn-login w-100" data-bs-toggle="modal" style={{paddingLeft:"0px",paddingRight:"0px"}} data-bs-target="#staticBackdropBNI">BNI</button>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <button type="button" className="btn btn-login w-100" data-bs-toggle="modal" style={{paddingLeft:"0px",paddingRight:"0px"}} data-bs-target="#staticBackdropBRI">BRI</button>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <button type="button" className="btn btn-login w-100" data-bs-toggle="modal" style={{paddingLeft:"0px",paddingRight:"0px"}} data-bs-target="#staticBackdropDana">Dana</button>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <button type="button" className="btn btn-login w-100" data-bs-toggle="modal" style={{paddingLeft:"0px",paddingRight:"0px"}} data-bs-target="#staticBackdropLinkAja">LinkAja</button>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <button type="button" className="btn btn-login w-100" data-bs-toggle="modal" style={{paddingLeft:"0px",paddingRight:"0px"}} data-bs-target="#staticBackdropMandiri">Mandiri</button>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <button type="button" className="btn btn-login w-100" data-bs-toggle="modal" style={{paddingLeft:"0px",paddingRight:"0px"}} data-bs-target="#staticBackdropOvo">Ovo</button>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <button type="button" className="btn btn-login w-100" data-bs-toggle="modal" style={{paddingLeft:"0px",paddingRight:"0px"}} data-bs-target="#staticBackdropPaypal">Paypal</button>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <button type="button" className="btn btn-login w-100" data-bs-toggle="modal" style={{paddingLeft:"0px",paddingRight:"0px"}} data-bs-target="#staticBackdropShopeePay">ShopeePay</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI BCA */}
                <div className="modal fade" id="staticBackdropBCA" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn btn-logout mx-auto py-1 px-3"  data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi'>Tutup</button>
                            </div>
                            <div className="modal-body">
                                <h5 className="text-center fw-bold">Kirim hadiah Ke Webew Dengan BCA</h5>
                                <h6 className="text-center">Silahkan Salin No Virtual Account</h6>
                                <div className="row justify-content-center mb-3">
                                    <div className="col-12 col-md-6">
                                        <input type="text" name="link1" id="link1" className="form-control form mt-3" ref={inputRefBca} value={salinBca} readOnly/>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <button type="button" className="btn btn-webew-product" style={{marginTop:"16.5px"}} onClick={salinRekBca}>Salin</button>
                                    </div>
                                </div>
                                <h6 className="mt-2">Petunjuk dengan MBanking :</h6>
                                <h6>1. Buka aplikasi mobile BCA</h6>
                                <h6>2. Pilih M-Transfer</h6>
                                <h6>3. Pilih BCA Virtual Account</h6>
                                <h6>4. Masukan nomer virtual account, lalu klik send</h6>
                                <h6>5. Masukan nominal uang yang akan diberikan ke webew (min. 10.000)</h6>
                                <h6>6. Lalu proses deh</h6>
                                <h6>Terimakasih ya</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI BRI */}
                <div className="modal fade" id="staticBackdropBRI" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn btn-logout mx-auto py-1 px-3"  data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi'>Tutup</button>
                            </div>
                            <div className="modal-body">
                                <h5 className="text-center fw-bold">Kirim hadiah Ke Webew Dengan BRI</h5>
                                <h6 className="text-center">Silahkan Salin No Handphone Webew</h6>
                                <div className="row justify-content-center mb-3">
                                    <div className="col-12 col-md-6">
                                        <input type="text" name="link2" id="link2" className="form-control form mt-3" ref={inputRefBri} value={salinBri} readOnly/>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <button type="button" className="btn btn-webew-product" style={{marginTop:"16.5px"}} onClick={salinRekBri}>Salin</button>
                                    </div>
                                </div>
                                <h6 className="mt-2">Petunjuk dengan MBanking :</h6>
                                <h6>1. Buka aplikasi mobile BRI</h6>
                                <h6>2. Masuk ke menu Dompet Digital</h6>
                                <h6>3. Pilih Top-Up baru, lalu ShopeePay</h6>
                                <h6>4. Masukan nomer handphone webew</h6>
                                <h6>5. Masukan nominal uang yang akan diberikan ke webew (min. 10.000)</h6>
                                <h6>6. Lalu proses deh</h6>
                                <h6>Terimakasih ya</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI BNI */}
                <div className="modal fade" id="staticBackdropBNI" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn btn-logout mx-auto py-1 px-3"  data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi'>Tutup</button>
                            </div>
                            <div className="modal-body">
                                <h5 className="text-center fw-bold">Kirim hadiah Ke Webew Dengan BNI</h5>
                                <h6 className="text-center">Silahkan Salin No Handphone Webew</h6>
                                <div className="row justify-content-center mb-3">
                                    <div className="col-12 col-md-6">
                                        <input type="text" name="link3" id="link3" className="form-control form mt-3" ref={inputRefBni} value={salinBni}readOnly/>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <button type="button" className="btn btn-webew-product" style={{marginTop:"16.5px"}} onClick={salinRekBni}>Salin</button>
                                    </div>
                                </div>
                                <h6 className="mt-2">Petunjuk dengan MBanking :</h6>
                                <h6>1. Buka aplikasi mobile BNI</h6>
                                <h6>2. Pilih E-Wallet, lalu ShopeePay</h6>
                                <h6>3. Pilih menu input baru</h6>
                                <h6>4. Masukan nomer handphone webew</h6>
                                <h6>5. Masukan nominal uang yang akan diberikan ke webew (min. 10.000)</h6>
                                <h6>6. Lalu proses deh</h6>
                                <h6>Terimakasih ya</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI DANA */}
                <div className="modal fade" id="staticBackdropDana" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn btn-logout mx-auto py-1 px-3"  data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi'>Tutup</button>
                            </div>
                            <div className="modal-body">
                                <h5 className="text-center fw-bold">Kirim hadiah Ke Webew Dengan Dana</h5>
                                <img src="https://webew.id/img/donasi/qrDana.jpeg" className="d-block mx-auto" style={{minHeight:"200px",maxHeight:"300px"}} alt="QR Code Dana Webew" />
                                <a href="https://link.dana.id/qr/k0ici5or" className="text-center fw-semibold d-block mx-auto" style={{color:"blue"}}>https://link.dana.id/qr/k0ici5or</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI LINK AJA */}
                <div className="modal fade" id="staticBackdropLinkAja" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn btn-logout mx-auto py-1 px-3"  data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi'>Tutup</button>
                            </div>
                            <div className="modal-body">
                                <h5 className="text-center fw-bold">Kirim hadiah Ke Webew Dengan LinkAja</h5>
                                <h6 className="text-center">Silahkan Salin No Handphone Webew</h6>
                                <div className="row justify-content-center mb-4">
                                    <div className="col-12 col-md-6">
                                        <input type="text" name="link4" id="link4" className="form-control form mt-3" ref={inputRefLinkAja} value={salinLinkAja} readOnly/>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <button type="button" className="btn btn-webew-product" style={{marginTop:"16.5px"}} onClick={salinRekLinkAja}>Salin</button>
                                    </div>
                                </div>
                                <h6 className="mt-2">Petunjuk :</h6>
                                <h6>1. Buka aplikasi mobile LinkAja</h6>
                                <h6>2. Klik tombol kirim uang</h6>
                                <h6>3. Pilih metode kirim uang "nomor telepon"</h6>
                                <h6>4. Masukan nomer handphone webew diatas</h6>
                                <h6>Terimakasih ya</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI MANDIRI */}
                <div className="modal fade" id="staticBackdropMandiri" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn btn-logout mx-auto py-1 px-3"  data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi'>Tutup</button>
                            </div>
                            <div className="modal-body">
                                <h5 className="text-center fw-bold">Kirim hadiah Ke Webew Dengan Mandiri</h5>
                                <h6 className="text-center">Silahkan Salin No Virtual Account</h6>
                                <div className="row justify-content-center mb-3">
                                    <div className="col-12 col-md-6">
                                        <input type="text" name="link5" id="link5" className="form-control form mt-3" ref={inputRefMandiri} value={salinMandiri} readOnly/>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <button type="button" className="btn btn-webew-product" style={{marginTop:"16.5px"}} onClick={salinRekMandiri}>Salin</button>
                                    </div>
                                </div>
                                <h6 className="mt-2">Petunjuk dengan MBanking :</h6>
                                <h6>1. Buka aplikasi mobile Mandiri</h6>
                                <h6>2. Pilih Top-Up, lalu pilih E-wallet</h6>
                                <h6>3. Pilih penyedia layanan ShopeePay</h6>
                                <h6>4. Masukan nomer Virtual Account webew</h6>
                                <h6>5. Masukan nominal uang yang akan diberikan ke webew (min. 10.000)</h6>
                                <h6>6. Lalu proses deh</h6>
                                <h6>Terimakasih ya</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI OVO */}
                <div className="modal fade" id="staticBackdropOvo" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn btn-logout mx-auto py-1 px-3"  data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi'>Tutup</button>
                            </div>
                            <div className="modal-body">
                                <h5 className="text-center fw-bold">Kirim hadiah Ke Webew Dengan Ovo</h5>
                                <h6 className="text-center">Silahkan Salin No Handphone Webew</h6>
                                <div className="row justify-content-center mb-4">
                                    <div className="col-12 col-md-6">
                                        <input type="text" name="link6" id="link6" className="form-control form mt-3" ref={inputRefOvo} value={salinOvo} readOnly/>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <button type="button" className="btn btn-webew-product" style={{marginTop:"16.5px"}} onClick={salinRekOvo}>Salin</button>
                                    </div>
                                </div>
                                <h6 className="">Caranya :</h6>
                                <h6 className="">1. Buka aplikasi mobile Ovo</h6>
                                <h6 className="">2. Klik tombol Transfer</h6>
                                <h6 className="">3. Pilih metode "ke sesama Ovo"</h6>
                                <h6 className="">4. Masukan nomer handphone webew Diatas</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI PAYPAL */}
                <div className="modal fade" id="staticBackdropPaypal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn btn-logout mx-auto py-1 px-3"  data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi'>Tutup</button>
                            </div>
                            <div className="modal-body">
                                <h5 className="text-center fw-bold">Kirim hadiah Ke Webew Dengan Paypal</h5>
                                <h6 className="text-center">Klik Link Paypal Webew Dibawah Ini</h6>
                                <a href="https://www.paypal.me/mauladafa" className="text-center fw-semibold d-block mx-auto" style={{color:"blue"}}>https://www.paypal.me/mauladafa</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI SHOPEEPAY */}
                <div className="modal fade" id="staticBackdropShopeePay" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn btn-logout mx-auto py-1 px-3"  data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi'>Tutup</button>
                            </div>
                            <div className="modal-body">
                                <h5 className="text-center fw-bold">Kirim hadiah Ke Webew Dengan ShopeePay</h5>
                                <img src="https://webew.id/img/donasi/qrShopeePay.jpeg" className="d-block mx-auto" style={{minHeight:"200px",maxHeight:"300px"}} alt="QR Code Dana Webew" />
                                <a href="https://wsa.wallet.airpay.co.id/qr/00fa712ffe34185e22d2" className="text-center fw-semibold d-block mx-auto" style={{color:"blue"}}>https://wsa.wallet.airpay.co.id/qr/00fa712ffe34185e22d2</a>
                            </div>
                        </div>
                    </div>
                </div>
            {/* TUTUP MODAL DONASI */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU DONASI
// EKSPOR
export default MenuDonasi