import React, { useEffect, useState } from 'react';

const Modal = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        // Menampilkan modal saat halaman pertama kali dimuat
        setShowModal(true);
    }, []);

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            {/* Overlay background gelap saat modal ditampilkan */}
            {showModal && <div className="modal-backdrop fade show"></div>}
            {/* Modal */}
            {showModal && (
                <div
                    className="modal fade show d-block modal-md"id="staticBackdropModalIklan"tabIndex="-1"aria-labelledby="staticBackdropLabel"aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn btn-logout mx-auto py-1 px-3" onClick={closeModal} aria-label="Tutup">Tutup</button>
                            </div>
                            <div className="modal-body">
                                <p className="text-center fw-bold">Maaf Ada Iklan Dulu Yaa</p>
                                <div className="box">
                                    <img src="https://webew.id/img/banner.png" alt="banner webew.id" className="mx-auto d-block" style={{ width: "100%", borderRadius: "20px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Modal;
