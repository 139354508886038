// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from 'axios'
// IMPORT USE NAVIGATE, LINK
import { useNavigate } from 'react-router-dom'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// LOGIN
const Login = () => { // KURUNG BUKA LOGIN
    // MEMBUAT VARIABEL LOGIN
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    // MEMBUAT VARIABEL MESSAGE DARI BACKEND
    const [msg, setMsg] = useState('')
    // MEMBUAT VARIABEL LOADER HALAMAN
    const [displayLoader, setDisplayLoader] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USE EFFECT
    useEffect(()=>{
        setDisplayLoader('none')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // PROSES LOGIN
    const login = async(e)=>{
        e.preventDefault()
        try {
            setDisplayLoader('')
            await axios.post('https://server13.webew.id/loweminBE',{
                email: email,
                password: password
            })
            navigate('/loamin', { replace: true })
            // setMsg('Maaf dalam Pemeliharaan')
        } catch (error) {
            if(error.response){
                setDisplayLoader('none')
                setMsg(error.response.data.msg)
            }
        }
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* LOADER */}
                <div className='loader-page-bg' style={{display:displayLoader,marginTop:"-110px"}}>
                    <div className='loader-page'></div>
                </div>
            {/* TUTUP LOADER */}
            {/* BOX LOGIN */}
                <div className='container mb-3' style={{marginTop:'110px'}}>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-xl-10'>
                            <div className='box p-4'>
                                <div className='row justify-content-center'>
                                    <div className='col-11 col-md-6 col-lg-5 order-1 order-md-0'>
                                        <h4 className='text-center' style={{fontWeight:'700',marginTop:'10px'}}>Login Admin</h4>
                                        <form onSubmit={login}>
                                            <h6 style={{textAlign:'center',marginTop:'15px',marginBottom:'-10px',color:'red'}}>{msg}</h6>
                                            <label htmlFor='email' className='mx-2'></label>
                                            <input type='email' id='email' className='form-control form mb-2' placeholder='Masukan email' value={email} onChange={(e) => setEmail(e.target.value)} required autoFocus/>
                                            <label htmlFor='password' className='mx-2'></label>
                                            <input type='password' id='password' className='form-control form mb-4' placeholder='Masukan password' value={password} onChange={(e) => setPassword(e.target.value)} required/>
                                            <input type='submit' className='btn btn-webew-login mb-4 mt-3' value='Login'/>
                                            <a href={'/rewemin'} className='fw-semibold' style={{color:'rgb(0, 86, 247)', margin:'auto', display:'block', textAlign:'center', fontSize:'10pt'}}>Register</a>
                                            <a href={'/lupwemin'} className='fw-semibold' style={{color:'rgb(0, 86, 247)', margin:'auto', display:'block', textAlign:'center', fontSize:'10pt'}}>Lupa Password?</a>
                                        </form>
                                    </div>
                                    <div className='col-11 col-md-6 col-lg-5 order-0 order-md-1 mt-2 mb-3'>
                                        <img src='https://webew.id/img/4957136_4957136.jpg' alt='login' style={{width:'100%',marginTop:'0px'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* TUTUP BOX LOGIN */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
            {/* LINK CREDIT FOTO */}
                <a href={'https://www.freepik.com/free-vector/mobile-login-concept-illustration_4957136.htm#query=login%20vector&position=3&from_view=search&track=ais&uuid=3af634d7-f497-4f14-9cc2-9bfe74419701'}><p className='text-center fw-semibold' style={{fontSize:'9pt',color:'#d1d1d1',marginBottom:'0px'}}>Image by storyset</p></a>
            {/* TUTUP LINK CREDIT FOTO */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP LOGIN
// EKSPOR
export default Login