// IMPORT REACT
import React,{ useState } from 'react'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// IMPORT MODAL IKLAN
import Modal from '../../Layout/Modal.jsx'
// WE KHODAM
const WeKhodam = () => { // KURUNG BUKA WE KHODAM
    // MEMBUAT VARIABEL WE KHODAM
    const [nama, setNama] = useState('');
    const frontendWords = ['Singa', 'Macan', 'Harimau', 'Rubah', 'Serigala', 'Sapi', 'Kambing', 'Domba', 'Ular', 'Kalajengking', 'Ulet', 'Kelinci', 'Koala', 'Semut', 'Gajah', 'Badak', 'Gozila', 'Ayam', 'Bebek', 'Soang', 'Kumbang', 'Hiu', 'Paus', 'Kuda', 'Kudanil', 'Monyet', 'Kerbau', 'Banteng', 'Kingkong', 'Belalang', 'Hamster', 'Cicak', 'Kecoa', 'Tuyul', 'Pocong', 'Kelelawar', 'Superman', 'Batman', 'Spiderman', 'Hulk', 'Iguana', 'Kadal', 'Ultraman', 'Kelabang', 'Lalat', 'Belut'];
    const backendWords = ['Mulet', 'Putih', 'Item', 'Merah', 'Kuning', 'Cepak', 'Botak', 'Polkadot', 'Pitak', 'Pemalas', 'Pemarah', 'Pendiem', 'Terbang', 'Melayang', 'Kaki Tiga', 'Kaki Ayam', 'Kaki Kambing', 'Kaki Macan', 'Kaki Lima', 'Ekor Enam', 'Ekor Sembilan', 'Ekor Tiga', 'Mata Ayam', 'Mata Soang', 'Mata Momyet', 'Mata Kucing', 'Gigi Macan', 'Gigi Kelinci', 'Gigi Gingsul', 'Bersayap', 'Berjigong', 'Ekor Sapi', 'Ekor Kucing', 'Ekor Rubah', 'Kaki Kadal', 'Kaki Seribu', 'Kuping Gajah', 'Loncat', 'Kepala Belut', 'Kepala Macan', 'Kepala Kambing', 'Kepala Sapi', 'Kepala Ular', 'Berapi'];
    const [currentWord, setCurrentWord] = useState('');
    const [warning, setWarning] = useState('');
    // PROSES MENGACAK KATA
    const displayRandomWord = () => {
        if(nama === ""){
            setWarning("Isi Nama Dulu Ya Kawan !")
        }else{
            setNama("")
            const randomFrontendIndex = Math.floor(Math.random() * frontendWords.length)
            const randomBackendIndex = Math.floor(Math.random() * backendWords.length)
            const randomWord = `${frontendWords[randomFrontendIndex]} ${backendWords[randomBackendIndex]}`
            setCurrentWord(randomWord)
        }
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* MODAL IKLAN */}
            <Modal/>
            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>We Khodam</h2>
                                <p className='text-webew-home fw-normal'>Jika kalian penasaran didalam badan kalian ada khodam apa, cek disini. Hasil khodam sesuai mood sistem, dan untuk hiburan saja ya</p>
                                <a href={'/'} className='btn btn-webew-product' aria-label='Kembali webew.id'><i class="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/25559969_7094814.webp' className='img-webew-home mx-auto d-block mt-4' style={{width: '100%',minHeight:'200px'}} alt='by Vectorjuice on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* BOX CEK KHODAM */}
                <section id='cekKhodam' style={{paddingTop:'100px',paddingBottom:"70px"}}>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className="col-12 col-md-6">
                                <div className="box-kodam py-4 px-5">
                                    <h3 className='text-center fw-bold pb-4'>Cek Khodam Kalian</h3>
                                    <h6 className='text-center fw-semiBold' style={{color:"red",marginTop:"-12px",marginBottom:"-10px"}}>{warning}</h6>
                                    <input type="text" id="nama" className="form-control form my-3" value={nama} onChange={(e)=>setNama(e.target.value)} maxLength="40" placeholder='Masukan Nama' required/>
                                    <button className='btn btn-webew-product my-3' onClick={displayRandomWord}>Klik Untuk Cek Khodam</button>
                                    <h5 className='text-center fw-semibold mt-2 mb-3'>Khodam yang kalian miliki adalah :</h5>
                                    <h5 className='text-center fw-bold mt-3'>{currentWord}</h5>
                                </div>
                            </div>
                        </div>
                    </div>   
                </section>
            {/* TUTUP BOX CEK KHODAM */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>
                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/hand-drawn-flat-design-api-illustration_25559969.htm#page=4&query=programming&position=7&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Freepik di Freepik'>
                                    <div className='card'  style={{width: '100%',minHeight:'180px'}}>
                                        <img src='https://webew.id/img/credit/25559969_7094814.webp' className='mx-auto d-block pt-4' style={{width: '100%',minHeight:'90px'}} alt='by Vectorjuice on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Freepik on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE KHODAM
// EKSPOR
export default WeKhodam