import React from 'react';
import WePenampilPdf from './WePenampilPdf.jsx';
function WeHasilPdf() {
    return (
        <div style={{ textAlign: 'center' }}>
            <WePenampilPdf />
        </div>
    );
}

export default WeHasilPdf;
