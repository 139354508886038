// IMPORT REACT
import React,{ useState } from 'react'
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE KHODAM
const MenuWeKhodam = () => { // KURUNG BUKA MENU WE KHODAM
    // MEMBUAT VARIABEL WE KHODAM
    const [nama, setNama] = useState('');
    const frontendWords = ['Singa', 'Macan', 'Harimau', 'Rubah', 'Serigala', 'Sapi', 'Kambing', 'Domba', 'Ular', 'Kalajengking', 'Ulet', 'Kelinci', 'Koala', 'Semut', 'Gajah', 'Badak', 'Gozila', 'Ayam', 'Bebek', 'Soang', 'Kumbang', 'Hiu', 'Paus', 'Kuda', 'Kudanil', 'Monyet', 'Kerbau', 'Banteng', 'Kingkong', 'Belalang', 'Hamster', 'Cicak', 'Kecoa', 'Tuyul', 'Pocong', 'Kelelawar', 'Superman', 'Batman', 'Spiderman', 'Hulk', 'Iguana', 'Kadal', 'Ultraman', 'Kelabang', 'Lalat', 'Belut'];
    const backendWords = ['Mulet', 'Putih', 'Item', 'Merah', 'Kuning', 'Cepak', 'Botak', 'Polkadot', 'Pitak', 'Pemalas', 'Pemarah', 'Pendiem', 'Terbang', 'Melayang', 'Kaki Tiga', 'Kaki Ayam', 'Kaki Kambing', 'Kaki Macan', 'Kaki Lima', 'Ekor Enam', 'Ekor Sembilan', 'Ekor Tiga', 'Mata Ayam', 'Mata Soang', 'Mata Monyet', 'Mata Kucing', 'Gigi Macan', 'Gigi Kelinci', 'Gigi Gingsul', 'Bersayap', 'Berjigong', 'Ekor Sapi', 'Ekor Kucing', 'Ekor Rubah', 'Kaki Kadal', 'Kaki Seribu', 'Kuping Gajah', 'Loncat', 'Kepala Belut', 'Kepala Macan', 'Kepala Kambing', 'Kepala Sapi', 'Kepala Ular', 'Berapi'];
    const [currentWord, setCurrentWord] = useState('');
    const [warning, setWarning] = useState('');
    // PROSES MEMNGACAK KATA
    const displayRandomWord = () => {
        if(nama === ""){
            setWarning("Isi Nama Dulu Ya Kawan !")
        }else{
            setNama("")
            const randomFrontendIndex = Math.floor(Math.random() * frontendWords.length);
            const randomBackendIndex = Math.floor(Math.random() * backendWords.length);
            const randomWord = `${frontendWords[randomFrontendIndex]} ${backendWords[randomBackendIndex]}`;
            setCurrentWord(randomWord);
        }
    }
    // RETURN 
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div  className="px-4"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row mt-1 mb-3">
                                        <div className="col-12 p-2">
                                            <h4 className='text-center fw-bold'>We Khodam</h4>
                                            <p className='text-center fw-semibold'>Hiburan Cek Khodam Dengan We Khodam</p>
                                        </div>
                                    </div>
                                    <div className='row justify-content-center mb-5 pb-1'>
                                        <div className="col-12 col-md-8 col-lg-6 mb-4">
                                            <div className="box-kodam py-4 px-4 mb-4">
                                                <h4 className='text-center fw-bold pb-4'>Masukan Nama</h4>
                                                <h6 className='text-center fw-semiBold' style={{color:"red",marginTop:"-12px",marginBottom:"-10px"}}>{warning}</h6>
                                                <input type="text" id="nama" className="form-control form my-3" value={nama} onChange={(e)=>setNama(e.target.value)} maxLength="40" placeholder='Masukan Nama' required/>
                                                <button className='btn btn-webew-product my-3' onClick={displayRandomWord}>Klik Untuk Cek Khodam</button>
                                                <h5 className='text-center fw-semibold mt-2 mb-3'>Khodam yang kamu miliki adalah:</h5>
                                                <h5 className='text-center fw-bold mt-3'>{currentWord}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU WE KHODAM
// EKSPOR
export default MenuWeKhodam