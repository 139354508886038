// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from 'axios'
// IMPORT USE NAVIGATE, LINK
import { useParams, useNavigate } from 'react-router-dom'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// LOGIN
const Login = () => { // KURUNG BUKA LOGIN
    const {jenisAplikasiParams} = useParams()
    // MEMBUAT VARIABEL LOGIN
    const [jenisAplikasi, setJenisAplikasi] = useState(jenisAplikasiParams)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    // MEMBUAT VARIABEL MESSAGE DARI BACKEND
    const [msg, setMsg] = useState('')
    // MEMBUAT VARIABEL LOADER HALAMAN
    const [displayLoader, setDisplayLoader] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USE EFFECT
    useEffect(()=>{
        setDisplayLoader('none')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // PROSES LOGIN
    const login = async(e)=>{
        e.preventDefault()
        try {
            setDisplayLoader('')
            await axios.post('https://server13.webew.id/loginBE',{
                email: email,
                password: password
            })
            navigate(`/loading/${jenisAplikasi}`, { replace: true })
            // setMsg('Maaf dalam Pemeliharaan')
        } catch (error) {
            if(error.response){
                setDisplayLoader('none')
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES HANDLE PASSWORD VISIBILITY
    const handlePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* LOADER */}
                <div className='loader-page-bg' style={{display:displayLoader,marginTop:"-110px"}}>
                    <div className='loader-page'></div>
                </div>
            {/* TUTUP LOADER */}
            {/* BOX LOGIN */}
                <div className='container mb-3' style={{marginTop:'110px'}}>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-xl-10'>
                            <div className='box p-4'>
                                <div className='row justify-content-center'>
                                    <div className='col-11 col-md-6 col-lg-5 order-1 order-md-0'>
                                        <h4 className='text-center fw-bold' style={{marginTop:'10px'}}>Silahkan Login</h4>
                                        <form onSubmit={login}>
                                            <h6 style={{textAlign:'center',marginTop:'15px',marginBottom:'20px',color:'red'}}>{msg}</h6>
                                            <select className="form-select form mb-4" id="jenisAplikasi" aria-label="jenisAplikasi" value={jenisAplikasi} onChange={(e)=>setJenisAplikasi(e.target.value)} required>
                                                <option defaultValue={jenisAplikasi}>Pilih Jenis Aplikasi</option>
                                                <option value="dashboard">Dashboard</option>
                                                <option value="menu-we-aqiqah">We Aqiqah</option>
                                                <option value="menu-we-cv">We CV</option>
                                                <option value="menu-we-khodam">We Khodam</option>
                                                <option value="menu-we-motivasi">We Motivasi</option>
                                                <option value="menu-we-nabung">We Nabung</option>
                                                <option value="menu-we-pernikahan">We Pernikahan</option>
                                                <option value="menu-we-ultah">We Ultah</option>
                                                <option value="menu-we-website">We Website</option>
                                            </select>
                                            <input type='email' id='email' className='form-control form mb-4' placeholder='Masukan email' value={email} onChange={(e) => setEmail(e.target.value)} required autoFocus/>
                                            <div className="password-container" style={{ position: 'relative' }}>
                                                <input type={showPassword ? 'text' : 'password'} id='password' className='form-control form mb-3' placeholder='Masukan password' value={password} onChange={(e) => setPassword(e.target.value)} required style={{ paddingRight: '40px' }}/>
                                                <span onClick={handlePasswordVisibility} style={{position: 'absolute',right: '10px',top: '50%',transform: 'translateY(-50%)',cursor: 'pointer',color: 'gray'}}>{showPassword ? '👀' : '🙈'}</span>
                                            </div>
                                            <input type='submit' className='btn btn-webew-login mb-4 mt-3' value='Login'/>
                                            <a href={`/register/${jenisAplikasi}`} className='fw-semibold' style={{color:'rgb(0, 86, 247)', margin:'auto', display:'block', textAlign:'center', fontSize:'10pt'}}>Belum punya akun ? daftar disini</a>
                                            <a href={`/lupa-password/${jenisAplikasi}`} className='fw-semibold' style={{color:'rgb(0, 86, 247)', margin:'auto', display:'block', textAlign:'center', fontSize:'10pt'}}>Lupa Password ? klik disini</a>
                                        </form>
                                    </div>
                                    <div className='col-11 col-md-6 col-lg-5 order-0 order-md-1 mt-2 mb-3'>
                                        <img src='https://webew.id/img/4957136_4957136.jpg' alt='login' style={{width:'100%',marginTop:'0px'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* TUTUP BOX LOGIN */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
            {/* LINK CREDIT FOTO */}
                <a href={'https://www.freepik.com/free-vector/mobile-login-concept-illustration_4957136.htm#query=login%20vector&position=3&from_view=search&track=ais&uuid=3af634d7-f497-4f14-9cc2-9bfe74419701'}><p className='text-center fw-semibold' style={{fontSize:'9pt',color:'#d1d1d1',marginBottom:'0px'}}>Image by storyset</p></a>
            {/* TUTUP LINK CREDIT FOTO */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP LOGIN
// EKSPOR
export default Login