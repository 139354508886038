import React,{ useState, useEffect } from "react"
import axios from "axios"
import jwt_decode from "jwt-decode"
import { useNavigate, Link } from "react-router-dom"

const MenuDashboard = () => {
    // variabel sistem utama
    const [panggilan, setPanggilan] = useState("")
    const [, setName] = useState("")
    const [, setToken] = useState("")
    const [expire, setExpire] = useState("")
    const navigate = useNavigate()
    // Sistem utama
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const refreshToken = async() => {
        try {
            const response = await axios.get("https://server13.webew.id/MapTokenBE")
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setName(decoded.name)
            setExpire(decoded.exp)
        } catch (error) {
            if(error.response){
                navigate("/menu-login-map", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get("https://server13.webew.id/MapTokenBE")
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setName(decoded.name)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    const Logout = async() =>{
        try {
            await axios.delete("https://server13.webew.id/MapLogoutBE")
            navigate("/menu-login-map", { replace: true })
        } catch (error) {
            console.log(error)
        }
    }
    // Halaman
    return (
        <div id="wrapper">
            {/* SIDEBAR*/}
            <nav className="navbar-expand-sm bg-navbar">
                <div className="dropdown ml-2"></div>
                <div className="collapse navbar-collapse" id="sidebar">
                    <ul className="navbar-nav sidebar" id="accordionSidebar">
                        <div className="navbar-brand d-flex align-items-center justify-content-center">
                            <img src="https://webew.id/img/map/newLogoMap.png" alt="logo map" className="d-block mx-auto" style={{width:"100px",marginTop:"5px"}}/>
                        </div>
                        <div className="sidebar-heading" style={{marginTop:"10px"}}>Menu Utama</div>
                        <li className="nav-item nav-itemm">
                            <Link className="nav-link nav-linkk" to={'/menu-dashboard-promap'}><i className="bi bi-columns-gap"></i><span>Dashboard</span></Link>
                        </li>
                        <button className="sidebar-heading pb-2 col-12" style={{marginTop:"10px",border:"none",backgroundColor:"white",color:"rgb(108, 108, 108)"}} type="button" data-bs-toggle="collapse" data-bs-target="#proposal" aria-expanded="false" aria-controls="proposal">
                            Buat Proposal <i class="bi bi-caret-down-fill" style={{color:"rgb(108, 108, 108)"}}></i>
                        </button>
                        <div class="collapse" id="proposal">
                            <div class="card card-body p-0 pb-3 pt-1">
                                <li className="nav-item nav-itemm">
                                    <Link className="nav-link nav-linkk" to={`/menu-bikin-proposal-TA/${panggilan}`}><i className="bi bi-file-earmark-text-fill"></i><span>Tax Audit</span></Link>
                                </li>
                                <li className="nav-item nav-itemm">
                                    <Link className="nav-link nav-linkk" to={`/menu-bikin-proposal-TC/${panggilan}`}><i className="bi bi-file-earmark-text-fill"></i><span>Tax Compliance</span></Link>
                                </li>
                                <li className="nav-item nav-itemm">
                                    <Link className="nav-link nav-linkk" to={`/menu-bikin-proposal-TR/${panggilan}`}><i className="bi bi-file-earmark-text-fill"></i><span>Tax Review</span></Link>
                                </li>
                                <li className="nav-item nav-itemm">
                                    <Link className="nav-link nav-linkk" to={`/menu-bikin-proposal-TP/${panggilan}`}><i className="bi bi-file-earmark-text-fill"></i><span>TP Doc</span></Link>
                                </li>
                                <li className="nav-item nav-itemm">
                                    <Link className="nav-link nav-linkk" to={`/menu-bikin-proposal-PP/${panggilan}`}><i className="bi bi-file-earmark-text-fill"></i><span>Pendamping Pajak</span></Link>
                                </li>
                            </div>
                        </div>
                        <button className="sidebar-heading pb-2 col-12" style={{marginTop:"10px",border:"none",backgroundColor:"white",color:"rgb(108, 108, 108)"}} type="button" data-bs-toggle="collapse" data-bs-target="#perjanjian" aria-expanded="false" aria-controls="perjanjian">
                            Buat Kontrak <i class="bi bi-caret-down-fill" style={{color:"rgb(108, 108, 108)"}}></i>
                        </button>
                        <div class="collapse" id="perjanjian">
                            <div class="card card-body p-0 pb-3 pt-1">
                                
                            </div>
                        </div>
                        <button className="sidebar-heading pb-2 col-12" style={{marginTop:"10px",border:"none",backgroundColor:"white",color:"rgb(108, 108, 108)"}} type="button" data-bs-toggle="collapse" data-bs-target="#bapp" aria-expanded="false" aria-controls="bapp">
                        {/* Berita Acara Penyelesaian Pekerjaan */}
                            Buat BAPP <i class="bi bi-caret-down-fill" style={{color:"rgb(108, 108, 108)"}}></i>
                        </button>
                        <div class="collapse" id="bapp">
                            <div class="card card-body p-0 pb-3 pt-1">
                                
                            </div>
                        </div>
                        <button className="sidebar-heading pb-2 col-12" style={{marginTop:"10px",border:"none",backgroundColor:"white",color:"rgb(108, 108, 108)"}} type="button" data-bs-toggle="collapse" data-bs-target="#invoice" aria-expanded="false" aria-controls="invoice">
                            Buat Invoice <i class="bi bi-caret-down-fill" style={{color:"rgb(108, 108, 108)"}}></i>
                        </button>
                        <div class="collapse" id="invoice">
                            <div class="card card-body p-0 pb-3 pt-1">
                                
                            </div>
                        </div>
                        <button className="sidebar-heading pb-2 col-12" style={{marginTop:"10px",border:"none",backgroundColor:"white",color:"rgb(108, 108, 108)"}} type="button" data-bs-toggle="collapse" data-bs-target="#pelunasan" aria-expanded="false" aria-controls="pelunasan">
                            Buat Pelunasan <i class="bi bi-caret-down-fill" style={{color:"rgb(108, 108, 108)"}}></i>
                        </button>
                        <div class="collapse" id="pelunasan">
                            <div class="card card-body p-0 pb-3 pt-1">
                                
                            </div>
                        </div>
                    </ul>
                </div>
            </nav>
            {/* KONTEN WRAP */}
            <div id="content-wrapper">
                {/* NAVBAR */}
                <nav className="navbar navbar-expand-sm sticky-top nav-bar py-3">
                    <div className="container">
                        <div className="dropdown ml-2">
                            <button className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation" style={{border:"none"}}>
                                <i className="bi bi-grid-fill" style={{fontSize:"22px"}}></i>
                            </button>
                        </div>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                        <div className="d-flex" role="search">
                            <button onClick={Logout} className="btn btn-logout mx-3"><i className="bi bi-power" style={{color:"white",fontSize:"22px"}}></i></button>
                        </div>
                    </div>
                </nav>
                {/* KONTEN */}
                <div className="container-fluid" style={{overflow:"auto",display:"block",width:"100%",height:"88vh"}}>
                    <h4 className="text-center mt-4" style={{fontWeight:"700"}}>Selamat Datang di ProMAP</h4>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-3">
                            <div className="box p-2" style={{width: "100%",minHeight:"270px"}}>
                                <h6 className="fw-bold mt-2"><i className="bi biShop bi-file-earmark-text-fill" style={{color:"rgb(108, 108, 108)"}}></i> Bikin Proposal</h6>
                                <div className="row ">
                                    <div className="col-12 my-2">
                                        <Link className="mx-2 fw-semibold" to={`/menu-bikin-proposal-TA/${panggilan}`} style={{color:"rgb(11, 97, 255)"}}><i className="bi bi-file-earmark-text-fill"></i> Tax Audit <i class="bi bi-box-arrow-up-right" style={{fontSize:"13px",marginLeft:"5px"}}></i></Link>
                                    </div>
                                    <div className="col-12 my-2">
                                        <Link className="mx-2 fw-semibold" to={`/menu-bikin-proposal-TC/${panggilan}`} style={{color:"rgb(11, 97, 255)"}}><i className="bi bi-file-earmark-text-fill"></i> Tax Compliance <i class="bi bi-box-arrow-up-right" style={{fontSize:"13px",marginLeft:"5px"}}></i></Link>
                                    </div>
                                    <div className="col-12 my-2">
                                        <Link className="mx-2 fw-semibold" to={`/menu-bikin-proposal-TR/${panggilan}`} style={{color:"rgb(11, 97, 255)"}}><i className="bi bi-file-earmark-text-fill"></i> Tax Review <i class="bi bi-box-arrow-up-right" style={{fontSize:"13px",marginLeft:"5px"}}></i></Link>
                                    </div>
                                    <div className="col-12 my-2">
                                        <Link className="mx-2 fw-semibold" to={`/menu-bikin-proposal-TP/${panggilan}`} style={{color:"rgb(11, 97, 255)"}}><i className="bi bi-file-earmark-text-fill"></i> TP Doc <i class="bi bi-box-arrow-up-right" style={{fontSize:"13px",marginLeft:"5px"}}></i></Link>
                                    </div>
                                    <div className="col-12 my-2">
                                        <Link className="mx-2 fw-semibold" to={`/menu-bikin-proposal-PP/${panggilan}`} style={{color:"rgb(11, 97, 255)"}}><i className="bi bi-file-earmark-text-fill"></i> Pendamping Pajak <i class="bi bi-box-arrow-up-right" style={{fontSize:"13px",marginLeft:"5px"}}></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-8 col-xl-9 mt-3">
                            <div className="box p-4" style={{width: "100%",minHeight:"270px"}}>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-6 col-lg-3 mt-4">
                            <div className="box p-3" style={{width: "100%",minHeight:"120px"}}>
                                <Link to={`/menu-history-proposal`} className="btn btn-webew-product mt-4 mx-auto d-block" style={{height:"auto"}}><i class="bi bi-clock-history"></i> History Proposal</Link>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 mt-4">
                            <div className="box p-3" style={{width: "100%",minHeight:"120px"}}>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 mt-4">
                            <div className="box p-3" style={{width: "100%",minHeight:"120px"}}>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 mt-4">
                            <div className="box p-3" style={{width: "100%",minHeight:"120px"}}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuDashboard