// IMPORT REACT
import React,{ useState, useEffect } from "react"
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE PARAMS, USE NAVIGATE
import { useParams, useNavigate } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../../Layout/MenuDonasi.jsx'
//  MENU DASHBOARD
const MenuDashboard = () => { // KURUNG BUKA MENU DASHBOARD
    // MEMBUAT VARIABEL DASHBOARD
    const { panggilanParams } = useParams();
    const [displayLoader, setDisplayLoader] = useState('')
    const [id, setId] = useState("")
    const [kata, setKata] = useState("")
    const [jenisMotivasi, setJenisMotivasi] = useState("")
    const [panggilan, setPanggilan] = useState("")
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState("")
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState("")
    // MEMBUAT NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        selectWeMotivasiByPanggilan()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get("https://server13.webew.id/tokenBE")
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-motivasi", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get("https://server13.webew.id/tokenBE")
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES MENAMPILKAN MOTIVASI BERDASARKAN PANGGILAN BY PARAMS
    const selectWeMotivasiByPanggilan = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeMotivasiByPanggilanBE/${panggilanParams}`)
            setId(response.data.id)
            setKata(response.data.kata)
            setJenisMotivasi(response.data.jenisMotivasi)
        }catch(error){
            console.log(error)
        }
    }
    // PROSES MENGUBAH DATA MOTIVASI
    const updateWeMotivasi = async (e) => {
        e.preventDefault()
        try {
            setDisplayLoader("")
            await axios.patch('https://server13.webew.id/updateWeMotivasiBE',{
                id: id,
                kata: kata,
                jenisMotivasi: jenisMotivasi,
            })
            navigate(`/menu-we-motivasi/${panggilan}`, { replace: true })
        } catch (error) {
            if(error.response){
                setDisplayLoader("none")
                console.log(error.response.data.msg)
            }
        }
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR*/}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR*/}
            {/* KONTEN WRAP */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div  className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="box col-12 col-md-8 p-4">
                                            <h3 className="text-center fw-bold mb-5">Ubah Motivasi</h3>
                                            <form onSubmit={updateWeMotivasi}>
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 mb-3">
                                                        <label htmlFor="kata">Kata Motivasi</label>
                                                        <input type="text" id="kata" className="form-control form" value={kata} onChange={(e)=>setKata(e.target.value)} required/>
                                                    </div>
                                                    <div className="col-12 col-lg-6 mb-3">
                                                        <label htmlFor="jenisMotivasi">Jenis Motivasi</label>
                                                        <select className="form-select form" id="jenisMotivasi" aria-label="jenisMotivasi" value={jenisMotivasi} onChange={(e)=>setJenisMotivasi(e.target.value)} required>
                                                            <option value="MotivasiPendidikan">Pilih</option>
                                                            <option value="MotivasiPendidikan">Motivasi Pendidikan</option>
                                                            <option value="MotivasiPekerjaan">Motivasi Pekerjaan</option>
                                                            <option value="MotivasiKehidupan">Motivasi Kehidupan</option>
                                                            <option value="MotivasiPercintaan">Motivasi Percintaan</option>
                                                            <option value="MotivasiEmosional">Motivasi Emosional</option>
                                                        </select>
                                                        <input type="text" id="id" className="form-disable" value={id} readOnly/>
                                                    </div>
                                                </div>
                                                <input type="submit" className="btn btn-webew-login mt-4" value="Ubah Motivasi"/>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU DASHBOARD
// EKSPOR
export default MenuDashboard