import React from "react"

const Navbar = () => {
    // Halaman
    return (
        <div>
            <nav className="navbar navbar-expand-md fixed-top nav-bar">
                <div className="container">
                    <img src="https://webew.id/img/map/newLogoMap.png" alt="logo map"style={{width:"100px",marginTop:"-5px",marginBottom:"-5px"}}/>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{border:"none"}}>
                        <i className="bi bi-grid-fill" style={{fontSize:"22px"}}></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                    <div className="d-flex" role="search">
                        <a href="/" className="btn btn-login" type="button" aria-label="Login">Login</a>
                    </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar