// IMPORT REACT
import React,{ useState, useEffect } from "react"
// IMPORT USE PARAMS
import { useParams } from "react-router-dom"
// WE AQIQAH CONTOH
const WeAqiqahContoh = () => {
    // MEMBUAT VARIABEL WE AQIQAH CONTOH
    const [color, setColor] = useState('')
    const [backgroundColor, setBackgroundColor] = useState('')
    const [backgroundImage1, setBackgroundImage1] = useState('')
    const [backgroundImage2, setBackgroundImage2] = useState('')
    const [border, setBorder] = useState('')
    const [border2, setBorder2] = useState('')
    const [link, setLink] = useState('')
    const [pemilik, setPemilik] = useState('')
    const [tutupButton, setTutupButton] = useState('')
    const { jenisWeAqiqahParams } = useParams();
    // USEEFFECT
    useEffect(()=>{
        if(jenisWeAqiqahParams === "color1"){
            setBackgroundColor("#0012294d")
            setBackgroundImage1("linear-gradient(180deg,#c4ebff,white)")
            setBackgroundImage2("linear-gradient(to top,#c4ebff,white)")
            setBorder("3px solid #c4ebff")
            setBorder2("none")
            setColor("#ffffff")
            setLink("https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph")
            setPemilik("By studioredcup")
        }else if(jenisWeAqiqahParams === "color2"){
            setBackgroundColor("#0012294d")
            setBackgroundImage1("linear-gradient(180deg,#f5dcff,white)")
            setBackgroundImage2("linear-gradient(to top,#f5dcff,white)")
            setBorder("3px solid #f5dcff")
            setBorder2("none")
            setColor("#ffffff")
            setLink("https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph")
            setPemilik("By studioredcup")
        }else if(jenisWeAqiqahParams === "color3"){
            setBackgroundColor("#0012294d")
            setBackgroundImage1("linear-gradient(180deg,#ffdcdc,white)")
            setBackgroundImage2("linear-gradient(to top,#ffdcdc,white)")
            setBorder("3px solid #ffdcdc")
            setBorder2("none")
            setColor("#ffffff")
            setLink("https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph")
            setPemilik("By studioredcup")
        }else if(jenisWeAqiqahParams === "color4"){
            setBackgroundColor("#0012294d")
            setBackgroundImage1("linear-gradient(180deg,#dcfff0,white)")
            setBackgroundImage2("linear-gradient(to top,#dcfff0,white)")
            setBorder("3px solid #dcfff0")
            setBorder2("none")
            setColor("#ffffff")
            setLink("https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph")
            setPemilik("By studioredcup")
        }else if(jenisWeAqiqahParams === "flower1"){
            setBackgroundColor("#0012294d")
            setBackgroundImage1("url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/17437651_5631800.jpg')")
            setBackgroundImage2("#ffffff99")
            setBorder("3px solid #de8f8f")
            setBorder2("3px solid #de8f8f")
            setColor("#ffffff")
            setLink("https://www.freepik.com/free-vector/watercolor-copy-space-background-with-floral-hand-drawn-elements_12263072.htm#page=2&query=flower%20background&position=27&from_view=search&track=ais")
            setPemilik("By freepik")
        }else if(jenisWeAqiqahParams === "flower2"){
            setBackgroundColor("#0012294d")
            setBackgroundImage1("url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12552268_5023096.jpg')")
            setBackgroundImage2("#ffffff99")
            setBorder("3px solid #768280")
            setBorder2("3px solid #768280")
            setColor("#ffffff")
            setLink("https://www.freepik.com/free-vector/watercolor-background-with-hand-drawn-elements_12552268.htm?query=flower%20background#from_view=detail_alsolike")
            setPemilik("By pikisuperstar")
        }else if(jenisWeAqiqahParams === "flower3"){
            setBackgroundColor("#0012294d")
            setBackgroundImage1("url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/8845961_4004353.jpg')")
            setBackgroundImage2("#ffffff99")
            setBorder("3px solid #a57fc1")
            setBorder2("3px solid #a57fc1")
            setColor("#ffffff")
            setLink("https://www.freepik.com/free-vector/powder-pastel-with-hand-drawn-elements-background_8845961.htm#page=3&query=flower%20background&position=8&from_view=search&track=ais")
            setPemilik("By pikisuperstar")
        }else if(jenisWeAqiqahParams === "flower4"){
            setBackgroundColor("#0012294d")
            setBackgroundImage1("url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12557301_5014255.jpg')")
            setBackgroundImage2("#ffffff99")
            setBorder("3px solid #6599ba")
            setBorder2("3px solid #6599ba")
            setColor("#ffffff")
            setLink("https://www.freepik.com/free-vector/watercolor-background-with-hand-drawn-elements_12557301.htm?query=flower%20background#from_view=detail_alsolike")
            setPemilik("By Freepik")
        }else if(jenisWeAqiqahParams === "white1"){
            setBackgroundColor("#0012294d")
            setBackgroundImage1("url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/15628817_5655049.jpg')")
            setBackgroundImage2("#ffffff99")
            setBorder("none")
            setBorder2("none")
            setColor("#fffff")
            setLink("https://www.freepik.com/free-vector/gradient-white-monochrome-background_15628817.htm#query=white%20background&position=32&from_view=search&track=ais")
            setPemilik("By Freepik")
        }else if(jenisWeAqiqahParams === "white2"){
            setBackgroundColor("#0012294d")
            setBackgroundImage1("url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12230268_4931029.jpg')")
            setBackgroundImage2("#ffffff99")
            setBorder("none")
            setBorder2("none")
            setColor("#fffff")
            setLink("https://www.freepik.com/free-vector/smooth-white-wave-background_12230268.htm#query=white%20background&position=17&from_view=search&track=ais")
            setPemilik("By pikisuperstar")
        }else if(jenisWeAqiqahParams === "white3"){
            setBackgroundColor("#0012294d")
            setBackgroundImage1("url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12220552_4935872.jpg')")
            setBackgroundImage2("#ffffff99")
            setBorder("none")
            setBorder2("none")
            setColor("#fffff")
            setLink("https://www.freepik.com/free-vector/white-abstract-background_12220552.htm#page=3&query=white%20background&position=20&from_view=search&track=ais")
            setPemilik("By Freepik")
        }else if(jenisWeAqiqahParams === "white4"){
            setBackgroundColor("#0012294d")
            setBackgroundImage1("url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/14571701_5487739.jpg')")
            setBackgroundImage2("#ffffff99")
            setBorder("none")
            setBorder2("none")
            setColor("#fffff")
            setLink("https://www.freepik.com/free-vector/paper-style-smooth-background_14571701.htm#page=4&query=white%20background&position=10&from_view=search&track=ais")
            setPemilik("By Freepik")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // BUTTON BUKA UNDANGAN
    const btnBukaUndangan = () => {
        setTutupButton("none")
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className="background-home">
            {/* MODAL */}
                <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor: `${backgroundColor}`,zIndex:"1",display:tutupButton,overflow:"auto"}}>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-8 col-md-7">
                                <div style={{backgroundImage:`${backgroundImage1}`,height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                    <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/lovely-newborn-asian-baby-sleeping-furry-cloth.jpg" className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="Aqiqahan" />
                                    <table>
                                        <tr>
                                            <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className='fw-bold'>Yusuf</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                        </tr>
                                        <tr>
                                            <td><button className='fw-bold py-2 px-4' onClick={btnBukaUndangan} style={{backgroundColor:`${color}`,borderRadius:"20px",border:`${border}`,color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}}>Buka Undangan</button></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* TUTUP MODAL */}
            {/* BOX */}
                <section style={{backgroundImage:`${backgroundImage1}`,width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                    {/* BOX 1 */}
                    <div className="container-fluid">
                        <div className="row justify-content-center p-4">
                            <div className="col-12 col-md-10 col-lg-11" style={{margin:"10px 0 10px 0"}}>
                                <div className='box p-3' style={{backgroundImage:`${backgroundImage2}`,backgroundColor:`${backgroundImage2}`,width:"100%",height:"auto",border:`${border2}`}}>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <h3 className='text-center mt-2 mb-4'>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h3>
                                            <h4 className='text-center fw-bold mb-5'>Tasyakuran Aqiqah</h4>
                                            <img src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/lovely-newborn-asian-baby-sleeping-furry-cloth.jpg" className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 20px -20px #8e8e8e"}} alt="Aqiqahan" />
                                            <h4 className='text-center fw-bold mt-5 '>Asrul Dahlan Pangestu</h4>
                                            <h6 className='text-center mt-3 mb-2'>Berjenis kelamin laki-laki, pada 20 Februari 2023</h6>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <h6 className='text-center mt-2'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami.</h6>
                                            <h6 className='text-center mt-4'>Yang akan dilaksanakan pada :</h6>
                                            <h5 className='text-center fw-bold'>Senin, 27 Februari 2023</h5>
                                            <h5 className='text-center fw-bold'>10.00 - 14.00</h5>
                                            <h6 className='text-center mb-4'>Jl. Anggrek 22, Blok A.33 No.24, RT. 021 RW. 020, Desa Sukamaju, Tambun Selatan</h6>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-4">
                                                    <a href="https://www.google.com" aria-label="Google Maps">
                                                        <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:`${color}`,border:`${border}`,borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108,108,108)"}}/>
                                                    </a>
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                        <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:`${color}`,border:`${border}`,borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108,108,108)"}}/>
                                                    </a>
                                                </div>
                                            </div>
                                            <h6 className='text-center mt-4'>Semoga Allah SWT menjadikan anak yang sholeh, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h6>
                                            <h6 className='text-center mt-4'>Kami yang berbahagia</h6>
                                            <h5 className='text-center fw-bold'>Aji & Siti</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BOX 2 */}
                    <div className='p-4' style={{backgroundImage:`${backgroundImage2}`,backgroundColor:`${backgroundImage2}`,width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",border:`${border2}`}}>
                        <h5 className='bi bi-image text-center text-center fw-bolder'> Image On Freepik</h5>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-4">
                                    <a href="https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph" aria-label="By studioredcup">
                                        <input type="button" value="By studioredcup" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:`${color}`,border:`${border2}`,borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108,108,108)"}}/>
                                    </a>
                                </div>
                                <div className="col-12 col-md-4">
                                    <a href={link} aria-label={pemilik}>
                                        <input type="button" value={pemilik} className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:`${color}`,border:`${border2}`,borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108,108,108)"}}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                    </div>
                </section>
            {/* TUTUP BOX */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE AQIQAH CONTOH
// EKSPOR
export default WeAqiqahContoh