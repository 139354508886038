// IMPORT REACT
import React from 'react'
// MENU NAVBAR
const MenuNavbarAdmin = () => { // KURUNG BUKA MENU NAVBAR
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* NAVBAR */}
            <nav className='navbar navbar-expand-sm navbar-height'>
                <div className='container'>
                    <button className='navbar-toggler' data-bs-toggle='collapse' data-bs-target='#sidebar' aria-controls='sidebar' aria-expanded='false' aria-label='Toggle navigation' style={{border:'none'}}>
                        <i className='bi bi-grid-fill' style={{fontSize:'22px'}}></i>
                    </button>
                </div>
            </nav>
            {/* TUTUP NAVBAR */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU NAVBAR
// EKSPOR
export default MenuNavbarAdmin