import React, {useState} from 'react'
import axios from 'axios'
import NavbarBot from '../../Layout/NavbarBot.jsx'

const Pertemuan4 = () => {
    const [name,] = useState('webewers')
    const [saran, setSaran] = useState('')
    const createSaran = async (e) => {
        e.preventDefault()
        try{
            await axios.post('https://server13.webew.id/createSaranBE',{
                saran: saran,
                nama: name
            })
            window.location.reload()
        } catch(error){
            if(error.response){
                console.log(error.response.data.msg)
            }
        }
    }
    return (
        <div className='background-home' style={{marginTop:'0px'}}>
            <section id='home'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h1 className='title-webew-home'>Perangkat Keras, Referensi Model dan Proses Bisnis</h1>
                        </div>
                    </div>
                </div>    
            </section>
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            <section id='about' style={{marginTop:'-70px'}}>
                <div className='container'>
                    <p className='text-webew-home px-4' style={{textAlign:"justify"}}>
                        <h4>Author by Mauladafa Fiscal Wibowo (10210006)</h4>
                        <br/>
                        Digitalisasi telah menjadi kata kunci dalam banyak industri, membawa perubahan signifikan dalam cara kita bekerja, berkomunikasi, dan mengelola bisnis. Tiga elemen utama yang sering muncul dalam diskusi tentang digitalisasi adalah perangkat keras, referensi model, dan proses bisnis. Ketiganya saling terkait dan berkontribusi pada keberhasilan implementasi teknologi di dunia bisnis. Dalam blog ini, kita akan membahas lebih dalam mengenai masing-masing elemen tersebut dan bagaimana mereka berperan dalam transformasi digital.
                        <br/>
                        <br/>
                        <h5 className='fw-bold'>1. Perangkat Keras</h5>
                        <br/>
                        <h6 className='fw-bold'>Definisi dan Fungsi</h6>
                        Perangkat keras (hardware) mengacu pada komponen fisik dari sistem komputer. Ini termasuk perangkat seperti komputer, server, perangkat jaringan, perangkat penyimpanan, dan berbagai perangkat input/output. Perangkat keras adalah fondasi dari setiap sistem teknologi informasi, memungkinkan perangkat lunak berjalan dan memproses data.
                        <br/>
                        <br/>
                        <h6 className='fw-bold'>Jenis Perangkat Keras dalam Bisnis</h6>
                        - Komputer dan Laptop: Digunakan oleh karyawan untuk menjalankan aplikasi bisnis, mengolah data, dan berkomunikasi.
                        <br/>
                        - Server: Menyediakan layanan kepada komputer lain di jaringan, seperti hosting aplikasi, penyimpanan data, dan manajemen database.
                        <br/>
                        - Perangkat Jaringan: Router, switch, dan firewall yang memastikan konektivitas dan keamanan jaringan.
                        <br/>
                        - Perangkat Penyimpanan: HDD, SSD, dan sistem penyimpanan berbasis cloud yang digunakan untuk menyimpan data bisnis secara aman.
                        <br/>
                        <br/>
                        <h6 className='fw-bold'>Peran dalam Digitalisasi</h6>
                        Perangkat keras yang andal dan efisien adalah tulang punggung dari setiap inisiatif digital. Dengan perangkat keras yang tepat, perusahaan dapat:
                        <br/>
                        - Memproses data lebih cepat dan lebih efisien.
                        <br/>
                        - Menyediakan akses yang lebih cepat dan aman ke informasi.
                        <br/>
                        - Mengimplementasikan teknologi baru seperti AI, IoT, dan big data analytics.
                        <br/>
                        <br/>
                        <br/>
                        <h5 className='fw-bold'>2. Referensi Model</h5>
                        <br/>
                        <h6 className='fw-bold'>Definisi dan Tujuan</h6>
                        Referensi model adalah kerangka kerja yang menyediakan panduan tentang cara mengembangkan dan mengimplementasikan sistem informasi dan proses bisnis. Model ini sering kali mencakup best practices, standar, dan pola yang dapat diikuti oleh organisasi untuk memastikan bahwa solusi teknologi mereka efektif dan efisien.
                        <br/>
                        <br/>
                        <h6 className='fw-bold'>Contoh Referensi Model</h6>
                        - TOGAF (The Open Group Architecture Framework): Menyediakan metodologi dan alat untuk merancang, merencanakan, mengimplementasikan, dan mengatur arsitektur perusahaan.
                        <br/>
                        - ITIL (Information Technology Infrastructure Library): Menawarkan pendekatan best practices untuk manajemen layanan TI.
                        <br/>
                        - COBIT (Control Objectives for Information and Related Technologies): Kerangka kerja untuk manajemen dan tata kelola TI perusahaan.
                        <br/>
                        <br/>
                        <h6 className='fw-bold'>Manfaat Referensi Model</h6>
                        - Standarisasi: Menyediakan standar yang dapat diikuti untuk memastikan konsistensi dan kualitas.
                        <br/>
                        - Efisiensi: Membantu mengidentifikasi cara terbaik untuk mengimplementasikan proses dan teknologi.
                        <br/>
                        - Pengurangan Risiko: Meminimalkan risiko dengan mengikuti best practices yang telah teruji.
                        <br/>
                        <br/>
                        <h5 className='fw-bold'>3. Proses Bisnis</h5>
                        <br/>
                        <h6 className='fw-bold'>Definisi dan Pentingnya</h6>
                        Proses bisnis adalah serangkaian langkah atau aktivitas yang dilakukan untuk mencapai tujuan bisnis tertentu. Proses ini mencakup segala sesuatu dari produksi hingga manajemen pelanggan, dan dari pemasaran hingga logistik. Proses bisnis yang efisien dan efektif sangat penting untuk kesuksesan operasional dan strategis perusahaan.
                        <br/>
                        <br/>
                        <h6 className='fw-bold'>Optimalisasi Proses Bisnis</h6>
                        - Automatisasi: Menggunakan teknologi untuk mengotomatisasi tugas-tugas berulang dan mengurangi kesalahan manusia.
                        <br/>
                        - Pengukuran dan Analisis: Menggunakan alat analitik untuk mengukur kinerja proses dan mengidentifikasi area untuk perbaikan.
                        <br/>
                        - Perbaikan Berkelanjutan: Menerapkan prinsip-prinsip lean dan six sigma untuk terus meningkatkan efisiensi dan efektivitas proses.
                        <br/>
                        <br/>
                        <h6 className='fw-bold'>Dampak pada Digitalisasi</h6>
                        - Peningkatan Efisiensi: Proses bisnis yang dioptimalkan memungkinkan perusahaan untuk beroperasi lebih cepat dan dengan biaya lebih rendah.
                        <br/>
                        - Pengalaman Pelanggan yang Lebih Baik: Proses yang efisien dapat meningkatkan kepuasan pelanggan dengan menyediakan layanan yang lebih cepat dan berkualitas lebih tinggi.
                        <br/>
                        - Fleksibilitas: Proses yang dirancang dengan baik memungkinkan perusahaan untuk lebih mudah beradaptasi dengan perubahan pasar dan teknologi.
                        <br/>
                        <br/>
                        <h6 className='fw-bold'>Kesimpulan</h6>
                        <br/>
                        Perangkat keras, referensi model, dan proses bisnis adalah tiga pilar utama yang mendukung transformasi digital yang sukses. Dengan perangkat keras yang kuat, referensi model yang terstandarisasi, dan proses bisnis yang efisien, perusahaan dapat meningkatkan produktivitas, mengurangi biaya, dan meningkatkan kepuasan pelanggan. Dalam dunia yang terus berkembang ini, investasi dalam ketiga elemen ini adalah kunci untuk tetap kompetitif dan relevan.
                        <br/>
                        Implementasi yang sukses membutuhkan pendekatan holistik yang mencakup pemahaman mendalam tentang kebutuhan bisnis, adopsi teknologi yang tepat, dan komitmen untuk perbaikan berkelanjutan. Dengan demikian, perusahaan dapat memanfaatkan potensi penuh dari digitalisasi dan mencapai keberhasilan jangka panjang.
                    </p>
                </div>
            </section>
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px',marginTop:'0px'}} alt='bg lengkung'/>
            <section id='saran' className='mb-5'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-7">
                            <div className='box p-3' style={{width: '100%',minHeight:'100px'}}>
                                <form onSubmit={createSaran} className='mt-3'>
                                    <div className='container'>
                                        <div className='row'>
                                            <h6 className='card-title text-center mb-3'>Beri saran untuk kemajuan webew</h6>
                                            <div className='col-12 col-md-6'>
                                                <input type='text' className='form-control form mb-3' value={saran} onChange={(e)=> setSaran(e.target.value)} required placeholder='Beri saran'/>
                                                <input type='text' className='form-disable form mb-3' value={name} readOnly/>
                                            </div>
                                            <div className='col-12 col-md-6 mb-2'>
                                                <input type='submit' className='btn btn-webew-login mb-2' value='Kirim Saran'/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* NAVBARBOT */}
            <NavbarBot/>
        </div>
    )
}

export default Pertemuan4